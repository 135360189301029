import * as types from "../actions/actionTypes";

import {
  GetBalance as GetBalanceXdc3,
  FromWei as FromWeiXdc3,
  GetTokenBalance,
} from "../crypto-rpc/xdc3";
import {
  GetBalance as GetBalanceWeb3,
  FromWei as FromWeiWeb3,
  GetTokenBalance as GetTokenBalanceWeb3,
} from "../crypto-rpc/web3";

import {
  AUTH_STATUS_PATH,
  AUTH_TOKEN_PATH,
  ColdWalletAddress,
  ColdWalletAddressXdc,
  GetAccountLinkXDC,
  TOKEN_ADDRESS,
  TOKEN_DECIMALS,
  WithdrawalAddress,
  GetAccountLinkEth,
  ColdWalletErc20,
  GBEX_USER_ADDRESS,
  XRC20_TOKENS,
  GetTokenDecimals,
} from "../helper/constant";
import { ParseError } from "../helper/ResponseHelper";
import { GetXrpBalance } from "../helper/common";
import { FromDecimals, FromDecimalsAbs } from "../helper/decimals";

export const SideEffects = (store) => (next) => (action) => {
  if (action.type === types.LOGIN_SUCCESS) {
    localStorage.setItem(AUTH_STATUS_PATH, "true");
    localStorage.setItem(AUTH_TOKEN_PATH, action.payload);
  } else if (action.type === types.GET_PAIRS_SUCCESS) {
    const pairs = action.payload;
    const pairedCurrency = pairs.reduce((acc, data) => {
      if (!acc.includes(data.firstCurrency)) {
        acc.push(data.firstCurrency);
      }
      if (!acc.includes(data.secondCurrency)) {
        acc.push(data.secondCurrency);
      }
      return acc;
    }, []);
    store.dispatch({
      type: types.PAIRED_CURRENCIES,
      payload: pairedCurrency,
    });
  } else if (action.type === types.GET_ADMIN_ADDRESS_SUCCESS) {
    const feeWithdrawal = action.payload.feeWithdrawal;
    const adminAddress = action.payload.address;

    const promisifiedBalance = [
      ...feeWithdrawal.map((e) => {
        return { ...e, platform: "Fee-Addr" };
      }),
      ...adminAddress.map((e) => {
        return { ...e, platform: "Admin-Addr" };
      }),
    ]
      .map((curr, i) => {
        if (curr.name === "XDC") {
          return [
            new Promise((resolve, reject) => {
              GetBalanceXdc3(curr.xdcAddress)
                .then((resp) => {
                  resolve({
                    address: curr.xdcAddress,
                    currency: curr.name,
                    balance: FromDecimalsAbs(resp, GetTokenDecimals(curr.name)),
                    platform: (
                      <>
                        {curr.platform} {GetAccountLinkXDC(curr.xdcAddress)}
                      </>
                    ),
                  });
                })
                .catch((e) => {
                  reject(e);
                });
            }),
            ...XRC20_TOKENS.map(
              (x) =>
                new Promise((resolve, reject) => {
                  GetTokenBalance(
                    TOKEN_ADDRESS[x.toLowerCase()],
                    curr.xdcAddress
                  )
                    .then((resp) => {
                      resolve({
                        address: curr.xdcAddress,
                        currency: x,
                        balance: FromDecimalsAbs(resp, GetTokenDecimals(x)),
                        platform: (
                          <>
                            {curr.platform} {GetAccountLinkXDC(curr.xdcAddress)}{" "}
                          </>
                        ),
                      });
                    })
                    .catch((e) => {
                      reject(e);
                    });
                })
            ),
          ];
        } else if (curr.name === "ETH") {
          return new Promise((resolve, reject) => {
            GetBalanceWeb3(curr.address)
              .then((resp) => {
                resolve({
                  address: curr.address,
                  currency: curr.name,
                  balance: FromWeiWeb3(resp),
                  platform: (
                    <>
                      {" "}
                      {curr.platform} {GetAccountLinkEth(curr.address)}{" "}
                    </>
                  ),
                  srNo: i,
                });
              })
              .catch((e) => {
                reject(e);
              });
          });
        }
        return {};
      })
      .flat();

    let count = promisifiedBalance.length + 2;

    const adminWalletEth = ColdWalletAddress.eth.map((addr) => {
      return new Promise((resolve, reject) => {
        GetBalanceWeb3(addr)
          .then((resp) => {
            resolve({
              address: addr,
              currency: "ETH",
              balance: FromWeiWeb3(resp),
              platform: <>Cold Wallet {GetAccountLinkEth(addr)}</>,
              srNo: count++,
            });
          })
          .catch((e) => {
            reject(e);
          });
      });
    });

    const adminWalletErc20 = ColdWalletErc20.map((token) => {
      return new Promise((resolve, reject) => {
        GetTokenBalanceWeb3(TOKEN_ADDRESS[token.token], token.address)
          .then((resp) => {
            resolve({
              address: token.address,
              currency: token.token.toUpperCase(),
              balance: FromDecimalsAbs(resp, GetTokenDecimals(token.token.toUpperCase())),
              platform: <>Cold Wallet - {GetAccountLinkEth(token.address)}</>,
              srNo: count++,
            });
          })
          .catch((e) => {
            reject(e);
          });
      });
    });

    const adminWalletXrp = ColdWalletAddress.xrp.map(
      (addr) =>
        new Promise((resolve, reject) => {
          GetXrpBalance(addr)
            .then((resp) => {
              resolve({
                address: addr,
                currency: "XRP",
                balance: resp,
                platform: <>Cold Wallet {addr}</>,
                srNo: count++,
              });
            })
            .catch((e) => {
              reject(e);
            });
        })
    );

    const adminWalletXdc = ColdWalletAddressXdc.map(
      (token) =>
        new Promise((resolve, reject) => {
          if (token.token === "xdc")
            GetBalanceXdc3(token.address)
              .then((resp) => {
                resolve({
                  address: token.address,
                  currency: token.token.toUpperCase(),
                  balance: FromDecimals(resp, TOKEN_DECIMALS[token.token]),
                  platform: (
                    <>Cold Wallet - {GetAccountLinkXDC(token.address)}</>
                  ),
                  srNo: count++,
                });
              })
              .catch((e) => {
                reject(e);
              });
          else {
            GetTokenBalance(TOKEN_ADDRESS[token.token], token.address)
              .then((resp) => {
                resolve({
                  address: token.address,
                  currency: token.token.toUpperCase(),
                  balance: FromDecimals(resp, TOKEN_DECIMALS[token.token]),
                  platform: (
                    <>Cold Wallet - {GetAccountLinkXDC(token.address)}</>
                  ),
                  srNo: count++,
                });
              })
              .catch((e) => {
                reject(e);
              });
          }
        })
    );

    // const gbexUserFundWallet = () => {
    //   return new Promise((resolve, reject) => {
    //     GetTokenBalance(TOKEN_ADDRESS.gbex, GBEX_USER_ADDRESS)
    //       .then((resp) => {
    //         resolve({
    //           address: GBEX_USER_ADDRESS,
    //           currency: "GBEX",
    //           balance: FromDecimals(resp, TOKEN_DECIMALS.gbex),
    //           platform: (
    //             <>
    //               Exchange User Wallet - {GetAccountLinkXDC(GBEX_USER_ADDRESS)}
    //             </>
    //           ),
    //           srNo: count++,
    //         });
    //       })
    //       .catch((e) => {
    //         reject(e);
    //       });
    //   });
    // };

    const adminWithdrawalWallet = Object.keys(WithdrawalAddress).map(
      (token) => {
        return new Promise((resolve, reject) => {
          if (token === "xdc")
            GetBalanceXdc3(WithdrawalAddress[token].address)
              .then((resp) => {
                resolve({
                  address: WithdrawalAddress[token].address,
                  currency: token.toUpperCase(),
                  balance: FromDecimals(
                    resp,
                    WithdrawalAddress[token].decimals
                  ),
                  platform: (
                    <>
                      Withdrawal Wallet -{" "}
                      {GetAccountLinkXDC(WithdrawalAddress[token].address)}
                    </>
                  ),
                  srNo: count++,
                });
              })
              .catch((e) => {
                reject(e);
              });
          else
            GetTokenBalance(
              TOKEN_ADDRESS[token],
              WithdrawalAddress[token].address
            )
              .then((resp) => {
                resolve({
                  address: WithdrawalAddress[token].address,
                  currency: token.toUpperCase(),
                  balance: FromDecimals(
                    resp,
                    WithdrawalAddress[token].decimals
                  ),
                  platform: (
                    <>
                      Withdrawal Wallet -{" "}
                      {GetAccountLinkXDC(WithdrawalAddress[token].address)}
                    </>
                  ),
                  srNo: count++,
                });
              })
              .catch((e) => {
                reject(e);
              });
        });
      }
    );

    promisifiedBalance.push(...adminWithdrawalWallet);

    promisifiedBalance.push(...adminWalletXdc);

    promisifiedBalance.push(...adminWalletEth);

    promisifiedBalance.push(...adminWalletXrp);

    promisifiedBalance.push(...adminWalletErc20);

    // promisifiedBalance.push(gbexUserFundWallet());

    Promise.all(promisifiedBalance)
      .then((resp) => {
        store.dispatch({
          type: types.ADMIN_ADDRESS_BALANCE_SUCCESS,
          payload: resp,
        });
      })
      .catch((e) => {
        store.dispatch({
          type: types.ADMIN_ADDRESS_BALANCE_FAIL,
          notify: {
            msg: ParseError(e),
            opts: {
              type: "error",
            },
          },
        });
      });
  }

  return next(action);
};
