import React from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";

import Table from "../common/Table";
import ToggleModal from "../common/ToggleModal";
import {
  DateFilter,
  DefaultHeadFormatter,
  GeneralStateUpdaterNew,
  IconHeader,
  TableLoader,
  ViewOnlyForm,
} from "../../utils/Table";
import moment from "moment";
import { API_MERCHANT_ROOT } from "../../helper/constant";

const feeDistributionColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    // headerClasses: "u-cursor-pointer",
    // headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "amount",
    text: "amount",
    // filter: numberFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "currencyName",
    text: "currencyName",
    // filter: numberFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "category",
    text: "category",
    // filter: numberFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "typeOfRecord",
    text: "typeOfRecord",
    // filter: dateFilter({
    //   onFilter: DateFilter("checkoutTime"),
    // }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "createdAt",
    text: "created At",
    // filter: dateFilter({
    //   onFilter: DateFilter("createdAt"),
    // }),
    headerFormatter: DefaultHeadFormatter,
  }, 
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell, row, rowIndex, x) => {
      if (cell)
        return cell.map((e) => {
          if (e.modalName) return <ToggleModal {...e} />;
          return e;
        });
    },
  },
];

class FeeDistribution extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      feeDistribution: { data: [], totalRecords:0, loading: false },
      pageSize: 10,
      currentPage: 0,
      searchParams: { to: "", from: "", category: "", typeOfRecord: "" },
    };


    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    var yyyy = today.getFullYear();
    var options = { year: 'numeric', month: '2-digit', day: '2-digit',hour12: false,  hour: '2-digit', minute: '2-digit', second: '2-digit' };
    today = yyyy + '-' + mm + '-' + dd;
  
    var toDate = new Date(today + 'T23:59:00'); // Set "to" date to today at 23:59
  
    var fromDate = new Date(toDate); // Create a copy of "to" date
    fromDate.setDate(fromDate.getDate() - 7); // Subtract 7 days from the "from" date
  
    var fd = String(fromDate.getDate()).padStart(2, '0');
    var fm = String(fromDate.getMonth() + 1).padStart(2, '0'); // January is 0!
    var fy = fromDate.getFullYear();
  
    var fromdate = fy + '-' + fm + '-' + fd;
  
    const searchParams = { ...this.state.searchParams };
    searchParams["to"] = moment(toDate.toLocaleString('en-US', options)).format("YYYY-MM-DD HH:mm:ss");
    searchParams["from"] = fromdate + ' 00:00:00'; // Set "from" date to YYYY-MM-DD 00:00:00 format

    this.setState({ searchParams },()=> this.getFeeDistribution(0));
  }

  handleChange = ({ currentTarget: input }) => { 
    console.log(input);
    const searchParams = { ...this.state.searchParams };
    searchParams[input.name] = input.value;

    this.setState({ searchParams });
  };

  handlePage = (page) => {
    this.setState({ currentPage: page });
    this.getFeeDistribution(page);
  }

  handleSearch = () => {
    this.getFeeDistribution(0);
  }

  getFeeDistribution(page) {
    
    const {to, from, category, typeOfRecord} = this.state.searchParams;
    let params = {
      "page": page,
      "limit": this.state.pageSize
    };

 
    if(to != '') {
      params.to = moment(to).toISOString();
    }
    if(from != '') {
      params.from = moment(from).toISOString();
    }
    if(category != '') {
      params.category = category;
    }
    if(typeOfRecord != '') {
      params.typeOfRecord = typeOfRecord;
    }
    // console.log(params);
    this.generalStateUpdater("feeDistribution", {
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/fee-distribution/get-all-fee-distribution`,
      method: "POST",
      data: params,
      postProcessor: (e) => {
        const View = (
          {
            modalName: "View Fee Distribution",
            children: <ViewOnlyForm data={e} />,
            backdrop: "static",
            centered: true,
            disableSubmit: true,
            keyboard: false,
          }
        )

        return {
          ...e,
          actions: [
            View
          ],
        };
      },
    });
  }


  renderTableData(name) {
    const { currentPage, pageSize } =  this.state;
    let srNo = currentPage * pageSize;
    if (this.state[name].loading === true) return TableLoader("amount");
    return {
      data: this.state[name].data.map((e, i) => {
        if(e.createdAt != null) {
          e.createdAt = moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss');
        }
        if(e.amount) {
          e.amount = e.amount.toLocaleString();
        }
        return {
          srNo: i + 1 + srNo,
          ...e,
        };
      }),
    };
  }

  render() {
    return (
      <div className="kyb main-panel">
        <Card className="custom-card-1">
          <div className="title">
            Merchant: Fee Distribution
            <div className="title--bottom"></div>
          </div>
          <div className="card-body">
            <Card className="custom-card-1">
                <div className="card-body">
                  <Row>
                  <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>From:</Form.Label>
                        <Form.Control type="datetime-local" value={this.state.searchParams.from} name="from" onChange={this.handleChange} />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>To:</Form.Label>
                        <Form.Control type="datetime-local" value={this.state.searchParams.to} name="to" onChange={this.handleChange} />
                      </Form.Group>
                    </Col>
                   
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Category:</Form.Label>
                        <select className="form-control" name="category" onChange={this.handleChange}>
                          <option value="">select</option>
                          <option value="merchant-agent">merchant-agent</option>
                          <option value="merchant-official">merchant-official</option>
                          <option value="merchant-base">merchant-base</option>
                          <option value="merchant-hidden">merchant-hidden</option>
                          <option value="globiance-agent">globiance-agent</option>
                          <option value="globiance-official">globiance-official</option>
                          <option value="globiance-hidden">globiance-hidden</option>
                          <option value="globiance-refund-fee">globiance-refund-fee</option>
                        </select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Type of Record:</Form.Label>
                        <select className="form-control" name="typeOfRecord" onChange={this.handleChange}>
                          <option value="">select</option>
                          <option value="merchant">Merchant</option>
                          <option value="globiance">Globiance</option>
                        </select>
                      </Form.Group>
                    </Col>

                    <Col style={{ marginTop: "25px"}}>
                      <Button variant="primary" type="button" onClick={()=>this.handleSearch()}>
                        Search
                      </Button>
                    </Col>   
                    
                  </Row>
                </div>
            </Card>

            <Row>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={feeDistributionColumn}
                  className="table1"
                  tableName="Merchant Fee Distribution"
                  keyField="srNo"
                  customPagination="custom"
                  totalCount={this.state.feeDistribution.totalRecords}
                  pageSize={this.state.pageSize}
                  pageNumber={this.state.currentPage}
                  onPageChange={this.handlePage}                
                  {...this.renderTableData("feeDistribution")}
                />
              </Col>
            </Row>

            <br />
          </div>
        </Card>
      </div>
    );
  }
}

export default FeeDistribution;
