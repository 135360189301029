import axios from 'axios';
import { BASE_URL_SOTATEK, NETWORK, URL_LIVE_PRICE } from '../common/constants';
import { stringify } from 'querystring'
import { ParseError } from '../../../helper/ResponseHelper';
import { AddNoti } from '../../../helper/Notification';

function getBearerToken() {
  const jwt = localStorage.getItem('GlobianceAdmin-AUTH-TOKEN');
  return `Bearer ${jwt}`
}
export class stakingServices {
  getTokens = (hash, network) => {
    let queryString = '';
    switch (network) {
      case NETWORK.XinFin:
        queryString = `https://xdc.blocksscan.io/api/tokens/${hash}`;
        break;

      default:
        break;
    }
    return axios({
      method: 'GET',
      url: queryString,
    });
  };

  createPool = (data) => {
    const queryString = `${BASE_URL_SOTATEK}/pool/admin`;
    const jwt = localStorage.getItem('GlobianceAdmin-AUTH-TOKEN');

    return axios({
      url: queryString,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      data: JSON.stringify(data),
    });
  };

  addDuration = (data) => {
    const queryString = `${BASE_URL_SOTATEK}/pool/admin/add-pool`;
    const jwt = localStorage.getItem('GlobianceAdmin-AUTH-TOKEN');

    return axios({
      url: queryString,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      data: JSON.stringify(data),
    });
  };

  getCurrency = () => {
    const queryString = `${BASE_URL_SOTATEK}/currency/list`;
    const jwt = localStorage.getItem('GlobianceAdmin-AUTH-TOKEN');

    return axios({
      url: queryString,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });
  };

  getListLocked = (params) => {
    const queryString = `${BASE_URL_SOTATEK}/pool/admin/locked`;
    const jwt = localStorage.getItem('GlobianceAdmin-AUTH-TOKEN');

    const { param } = params

    return axios({
      url: queryString,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      params,
    });
  };

  getListFlexible = (params) => {
    const queryString = `${BASE_URL_SOTATEK}/pool/admin/flexible`;
    const jwt = localStorage.getItem('GlobianceAdmin-AUTH-TOKEN');

    return axios({
      url: queryString,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      params,
    });
  };

  getListUserStake = (params) => {
    const { page, limit, type, pool_id, user_id	} = params;
    const queryString = !!user_id
        ? `${BASE_URL_SOTATEK}/pool/admin/details?page=${page}&limit=${limit}&type=${type}&pool_id=${pool_id}&user_id=${user_id}`
        : `${BASE_URL_SOTATEK}/pool/admin/details?page=${page}&limit=${limit}&type=${type}&pool_id=${pool_id}`;
    const jwt = localStorage.getItem('GlobianceAdmin-AUTH-TOKEN');

    return axios({
      url: queryString,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });
  };

  getDripIntervals = (params) => {
    const {pool_id} = params;
    const queryString = `${BASE_URL_SOTATEK}/pool/admin/get-drip/${pool_id}`;
    const jwt = localStorage.getItem('GlobianceAdmin-AUTH-TOKEN');

    return axios({
      url: queryString,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      params,
    });
  }

  putUpdatePool = (id, data) => {
    const jwt = localStorage.getItem('GlobianceAdmin-AUTH-TOKEN');
    const queryString = `${BASE_URL_SOTATEK}/pool/admin/${id}`;

    return axios({
      url: queryString,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      data: JSON.stringify(data),
    });
  };

  postPublishPool = (id) => {
    const jwt = localStorage.getItem('GlobianceAdmin-AUTH-TOKEN');
    const queryString = `${BASE_URL_SOTATEK}/pool/admin/display/${id}`;

    return axios({
      url: queryString,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });
  };

  postActivePool = (id, activateDate) => {
    const jwt = localStorage.getItem('GlobianceAdmin-AUTH-TOKEN');
    const queryString = `${BASE_URL_SOTATEK}/pool/admin/active/${id}`;

    if (Number(activateDate) > 0) {
      return axios({
        url: queryString,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
        data: JSON.stringify({ date: activateDate })
      });
    }

    return axios({
      url: queryString,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });
  };


  postCapPool = (id) => {
    const jwt = localStorage.getItem('GlobianceAdmin-AUTH-TOKEN');
    const queryString = `${BASE_URL_SOTATEK}/pool/admin/cap/${id}`;

    return axios({
      url: queryString,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });
  };

  postResumePool = (id) => {
    const jwt = localStorage.getItem('GlobianceAdmin-AUTH-TOKEN');
    const queryString = `${BASE_URL_SOTATEK}/pool/admin/restart/${id}`;

    return axios({
      url: queryString,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });
  };

  deletePool = (id) => {
    const jwt = localStorage.getItem('GlobianceAdmin-AUTH-TOKEN');
    const queryString = `${BASE_URL_SOTATEK}/pool/admin/${id}`;

    return axios({
      url: queryString,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });
  };

  unStakePool = (id) => {
    const jwt = localStorage.getItem('GlobianceAdmin-AUTH-TOKEN');
    const queryString = `${BASE_URL_SOTATEK}/pool/admin/unstake/${id}`;

    return axios({
      url: queryString,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });
  };

  getLivePrice = () => {
    const queryString = `${URL_LIVE_PRICE}`;

    return axios({
      url: queryString,
      method: 'GET',
    });
  };

  getPoolDetail = (pool_id) => {
    return axios.get(`${BASE_URL_SOTATEK}/pool/admin/get/${pool_id}`,
      {
        headers: { Authorization: getBearerToken() }
      }
    );
  }

  getHistory = (params) => {
    const { page, limit, asset, admin_action, date_from, date_to, user_id, pool_id } = params;

    return axios.get(`${BASE_URL_SOTATEK}/pool/admin/history`,
      {
        params: { page, limit, asset, admin_action, date_from, date_to, user_id, pool_id },
        headers: { Authorization: getBearerToken() }
      }
    );
  };

  getBufferBalance(params) {
    const { page, limit, asset, date_from, date_to, admin_action, user_id, pool_id } = params;

    return axios.get(`${BASE_URL_SOTATEK}/pool/admin/history-buffer`,
      {
        params: { page, limit, asset, date_from, date_to, admin_action, user_id, pool_id },
        headers: { Authorization: getBearerToken() }
      }
    );
  };

  depositBufferBalance(body) {
    const { pool_id, balance } = body;

    const config = {
      method: 'POST',
      url: `${BASE_URL_SOTATEK}/pool/admin/deposit-buffer`,
      headers: { Authorization: getBearerToken() },
      data: stringify({ pool_id, balance })
    }
    return axios(config);
  }

  withdrawBufferBalance(body) {
    const { pool_id, balance } = body;

    const config = {
      method: 'POST',
      url: `${BASE_URL_SOTATEK}/pool/admin/withdraw-buffer`,
      headers: { Authorization: getBearerToken() },
      data: stringify({ pool_id, balance })

    }
    return axios(config);
  }

  getValidator(params) {
    const { page, limit, asset, date_from, date_to, admin_action, user_id, pool_id } = params;

    return axios.get(`${BASE_URL_SOTATEK}/pool/admin/history-validator`,
      {
        params: { page, limit, asset, date_from, date_to, admin_action, user_id, pool_id },
        headers: { Authorization: getBearerToken() }
      }
    );
  };

  depositValidator(body) {
    const { pool_id, balance } = body;

    const config = {
      method: 'POST',
      url: `${BASE_URL_SOTATEK}/pool/admin/deposit-validator`,
      headers: { Authorization: getBearerToken() },
      data: stringify({ pool_id, balance })
    }
    return axios(config);
  }

  withdrawValidator(body) {
    const { pool_id, balance } = body;

    const config = {
      method: 'POST',
      url: `${BASE_URL_SOTATEK}/pool/admin/withdraw-validator`,
      headers: { Authorization: getBearerToken() },
      data: stringify({ pool_id, balance })

    }
    return axios(config);
  }

  deactivatePool ({pool_id}) {
    const config = {
      method: 'POST',
      url: `${BASE_URL_SOTATEK}/pool/admin/deactive/${pool_id}`,
      headers: { Authorization: getBearerToken() },
    }

    return axios(config);
  }

  getValidatorBalance(pool_id) {
    const params = {pool_id};

    return axios.get(`${BASE_URL_SOTATEK}/pool/admin/balance-validator`,
      {
        params,
        headers: { Authorization: getBearerToken() }
      }
    );
  };

  editApr(pool_id, apy) {
    const body = {pool_id, apy};

    return axios.post(`${BASE_URL_SOTATEK}/pool/admin/edit-field`,
      body,
      {
        headers: { Authorization: getBearerToken() }
      }
    );
  }
  editEstimate(pool_id, estimate_origin_drip_interval, estimate_drip_interval_time_type) {
    const body = {pool_id, estimate_origin_drip_interval, estimate_drip_interval_time_type};

    return axios.post(`${BASE_URL_SOTATEK}/pool/admin/edit-field`,
      body,
      {
        headers: { Authorization: getBearerToken() }
      }
    );
  }
  editEstimate(pool_id, estimate_origin_drip_interval, estimate_drip_interval_time_type) {
    const body = {pool_id, estimate_origin_drip_interval, estimate_drip_interval_time_type};

    return axios.post(`${BASE_URL_SOTATEK}/pool/admin/edit-field`,
      body,
      {
        headers: { Authorization: getBearerToken() }
      }
    );
  }

  getPreStakeConditionList = () => {
    const queryString = `${BASE_URL_SOTATEK}/pool/admin/get-all-pre-stake-condition`;
    return axios({
      url: queryString,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getBearerToken() 
      },
    });
  };

  stakingPostApi = (api, body, cb) => {
    const queryString = `${BASE_URL_SOTATEK}/${api}`;
    return axios({
      url: queryString,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getBearerToken() 
      },
      data: body
    }).then((resp) => {
      if (resp.status === 200 || resp.status === 201) {
        AddNoti(resp.message || "Request completed successfully", { type: "info" });
      } else {
        AddNoti(ParseError(resp), { type: "error" });
      }
    })
    .catch((e) => {
      AddNoti(ParseError(e), { type: "error" });
    })
    .finally(() => {
      cb();
    });
  };

  getUserSummaryStakingHistory = (api) => {
    const queryString = `${BASE_URL_SOTATEK}/${api}`;
    const jwt = localStorage.getItem('GlobianceAdmin-AUTH-TOKEN');

    return axios({
      url: queryString,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });
  };

  stakingDistributeRewardsApi = (api, body, cb) => {
    const queryString = `${BASE_URL_SOTATEK}/${api}`;
    return axios({
      url: queryString,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getBearerToken() 
      },
      data: body
    }).then((resp) => {
      if (resp.status === 200 || resp.status === 201) {
        AddNoti(resp.message || "Request completed successfully", { type: "info" });
      } else {
        AddNoti(ParseError(resp), { type: "error" });
      }
    })
    .catch((e) => {
      AddNoti(ParseError(e), { type: "error" });
    })
    .finally(() => {
      if(cb) {
        cb();
      }
    });
  };
}

export const StakingServices = new stakingServices();
