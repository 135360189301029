import React, { Component } from "react";
import { connect } from "react-redux";

const RequireAuthentication = (FallbackComponent = null) => (
  ComposedComponent
) => {
  class Authentication extends Component {
    render() {
      if (this.props.auth.isLoggedIn === true)
        return <ComposedComponent {...this.props} />;
      if (!FallbackComponent) return null;
      return <FallbackComponent />;
    }
  }

  function mapStateToProps({ auth }) {
    return { auth };
  }

  return connect(mapStateToProps)(Authentication);
};

export default RequireAuthentication;
