import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col } from "react-bootstrap";
import _ from "lodash";
import { Redirect } from "react-router-dom";
import loaderGif from "../assets/gif/loader.gif";
import { DynamicForm } from "../components/common/RenderCustomView";
import { GeneralObjectViewer } from "../components/common/ObjectComponent";
import { AxiosInstance, MerchantAxiosInstance } from "../helper/AxiosInstance";
import { AddNoti } from "../helper/Notification";
import { ParseError } from "../helper/ResponseHelper";
import { ToJson } from "./Miscellaneous";
import Table from "../components/common/Table";

export const EvaluateDateExpression = (a, b, comparator) => {
  const a_date = new Date(a);
  const b_date = new Date(b);
  b_date.setHours(0, 0, 0, 0);
  switch (comparator) {
    case "=": {
      if (
        a_date.getDate() === b_date.getDate() &&
        a_date.getMonth() === b_date.getMonth() &&
        a_date.getFullYear() === b_date.getFullYear()
      ) {
        return true;
      }
      return false;
    }
    case ">=": {
      if (a_date.getTime() >= b_date.getTime()) {
        return true;
      }
      return false;
    }
    case "<=": {
      if (a_date.getTime() <= b_date.getTime()) {
        return true;
      }
      return false;
    }
    case ">": {
      if (a_date.getTime() > b_date.getTime()) {
        return true;
      }
      return false;
    }
    case "<": {
      if (a_date.getTime() < b_date.getTime()) {
        return true;
      }
      return false;
    }
    case "!=": {
      if (
        a_date.getDate() !== b_date.getDate() ||
        a_date.getMonth() !== b_date.getMonth() ||
        a_date.getFullYear() !== b_date.getFullYear()
      ) {
        return true;
      }
      return false;
    }
    default: {
    }
  }
};

export const DefaultHeadFormatter = (
  column,
  colIndex,
  { sortElement, filterElement }
) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>{filterElement}</div>
      <div>
        {column.text}
        {sortElement}
      </div>
    </div>
  );
};

export const DateFilter = (columnName) => (filterVal, data) => {
  if (filterVal.date != null && filterVal.comparator !== "") {
    return data.filter((row) => {
      if (
        !isNaN(Date.parse(row[columnName])) &&
        Date.parse(row[columnName]) > 0
      ) {
        return EvaluateDateExpression(
          row[columnName],
          filterVal.date,
          filterVal.comparator
        );
      }
      return false;
    });
  }
  return data;
};

export const IconHeader =
  (icon) =>
    (col, colIndex, { sortElement, filterElement }) =>
    (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>{filterElement}</div>
        <div className="table-common__icon-header">
          <span className="table-common__icon-text">{col.text}</span>
          {<FontAwesomeIcon icon={icon} />}
        </div>
      </div>
    );

/**
 *
 * !CIR
 *
 */

// export const RowSpan = (cols) => (row, rowIndex) => {
//   let style = {};
//   let colToSpan = {};

//   let span = 1,
//     prevCol = null;

//   for (let i = 0; i < cols.length; i++) {
//     const currCol = cols[i];
//     if (row.hasOwnProperty(currCol)) {
//       if (prevCol !== null) colToSpan[prevCol] = span;
//       prevCol = currCol;
//       if (i === cols.length - 1) {
//         colToSpan[currCol] = 1;
//       }
//     } else {
//       span++;
//       if (i === cols.length - 1) {
//         colToSpan[prevCol] = span;
//       }
//     }
//   }

//   return style;
// };

export const ColSpan =
  (cols, col, toShow = false) =>
    (cell, row, rowIndex, colIndex) => {
      if (toShow === false) return {};

      let colToSpan = {};

      let span = 1,
        prevCol = null;

      for (let i = 0; i < cols.length; i++) {
        const currCol = cols[i];
        if (row.hasOwnProperty(currCol)) {
          if (prevCol !== null) colToSpan[prevCol] = span;
          prevCol = currCol;
          if (i === cols.length - 1) {
            colToSpan[currCol] = 1;
          }
        } else {
          span++;
          if (i === cols.length - 1) {
            colToSpan[prevCol] = span;
          }
        }
      }

      span = colToSpan[col] || 0;

      if (span > 0)
        if (span > 1)
          return {
            colSpan: span,
            style: {
              textAlign: "center",
            },
          };
        else
          return {
            colSpan: span,
          };
      else {
        return {
          style: {
            display: "none",
          },
        };
      }
    };

export const TableLoader = (name) => {
  const loader = (
    <img src={loaderGif} alt="loading" width="20%" height="10rem" />
  );
  const query = {
    srNo: 1,
  };
  query[name] = loader;
  return {
    data: [{ ...query }],
    customColSpan: true,
  };
};
export const EmptyState = (name, message) => {
  const loader = (
    <p>{message}</p>
  );
  const query = {
    srNo: 1,
  };
  query[name] = loader;
  return {
    data: [{ ...query }],
    customColSpan: true,
  };
};

export function GeneralStateUpdater(stateName, opts, cb = () => { }) {
  const query = {};
  query[stateName] = { ...this.state[stateName], loading: true };
  this.setState({
    ...query,
  });
  MerchantAxiosInstance({ method: opts.method, url: opts.path, data: opts.data })
    .then((resp) => {
      if (resp.data.statusCode === 200) {
        if (opts.postProcessor)
          resp.data.data = resp.data.data.map(opts.postProcessor);
        query[stateName] = { loading: false, data: resp.data.data };
        this.setState({
          ...query,
        });
        if (opts.cb && typeof opts.cb === "function") {
          opts.cb(resp);
        }
      } else {
        AddNoti(ParseError(resp), { type: "error" });
        query[stateName] = { ...this.state[stateName], loading: false };
        this.setState({
          ...query,
        });
      }
    })
    .catch((e) => {
      AddNoti(ParseError(e), { type: "error" });
      query[stateName] = { ...this.state[stateName], loading: false };
      this.setState({
        ...query,
      });
    })
    .finally(() => cb());
}

export function GeneralStateUpdaterNew(stateName, opts, cb = () => { }) {
  const query = {};
  query[stateName] = { ...this.state[stateName], loading: true };
  this.setState({
    ...query,
  });
  MerchantAxiosInstance({ method: opts.method, url: opts.path, data: opts.data })
    .then((resp) => {
      if (resp.status == 200 || resp.status == 201) {
        if (stateName === 'checkout' || stateName === 'settlement' || stateName === 'payment' || stateName === 'feeDistribution' || stateName === 'balance'
          || stateName === 'refundRequest' || stateName === 'balanceTally') {
          if (opts.postProcessor) {
            let totalrows = resp.data.data.count;
            resp.data.data = resp.data.data.rows.map(opts.postProcessor);
            query[stateName] = { loading: false, data: resp.data.data, totalRecords: totalrows };

            this.setState({
              ...query,
            });
          }
        }
        if (stateName === 'statusChart' || stateName === 'merchantChart' || stateName === 'brokerChart' || stateName === 'transactionChart' ||
          stateName === 'merchantRequest' || stateName === "currency" || stateName === 'settlementPair' || stateName === 'refundFee' ||
          stateName === 'feeCollection' || stateName === 'feeChart' || stateName === 'merchantBalance' || stateName === 'balanceSummary'
          || stateName === 'unmatchedTally' || stateName === 'paymentDistribution' || stateName === 'paymentBlockchain') {

          if (opts.postProcessor) {
            resp.data.data = resp.data.data.map(opts.postProcessor);
          }
          query[stateName] = { loading: false, data: resp.data.data };

          this.setState({
            ...query,
          });
        }

        if (stateName === 'merchantDetail') {
          if (opts.postProcessor) {
            resp.data.data = [resp.data.data].map(opts.postProcessor);
          }
          query[stateName] = { loading: false, data: resp.data.data };

          this.setState({
            ...query,
          });
        }

        if(stateName === 'merchantCurrencies' || stateName === 'merchantWithdrawalList' || stateName === 'merchantUsers'
          || stateName === 'PageLayout'
        ) 
          { 
            if (opts.postProcessor) {
              
              const data = resp.data.data.rows.map(opts.postProcessor);
              const totalCount = resp.data.data.count;
              query[stateName] = { loading: false, data, totalCount };
              this.setState({
                ...query,
              });
            }
          }


      } else {
        AddNoti(ParseError(resp), { type: "error" });
        query[stateName] = { ...this.state[stateName], loading: false };
        this.setState({
          ...query,
        });
      }
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.data) {
          if (e.response.data.error) {
            AddNoti(e.response.data.error.field + ": " + e.response.data.error.message, { type: "error" });
          }
          else {
            AddNoti(ParseError(e), { type: "error" });
          }
        }
        else {
          AddNoti(ParseError(e), { type: "error" });
        }
      } else {
        AddNoti(ParseError(e), { type: "error" });
      }

      query[stateName] = { ...this.state[stateName], loading: false };
      this.setState({
        ...query,
      });
    })
    .finally(() => cb());
}

export function RenderCreateBtn(data, api, name = "New Entry", isUserApi = false, label = 'Create +', variant = 'warning') {
  return {
    modalName: name,
    children: <DynamicForm isUserApi={isUserApi} data={data} api={api} />,
    backdrop: "static",
    centered: true,
    disableSubmit: true,
    keyboard: false,
    btnName: label,
    btnVariant: variant,
  };
}

export function RenderUpdateBtn(data, api, name = "New Entry", isUserApi = false, btnName = 'update') {
  return {
    modalName: name,
    children: <DynamicForm isUserApi={isUserApi} data={data} api={api} />,
    backdrop: "static",
    centered: true,
    disableSubmit: true,
    keyboard: false,
    btnName: btnName,
    btnVariant: "warning",
  };
}

export const ViewOnlyForm = ({ data }) => {
  const RenderElement = ({ field, value }) => (
    <Row>
      <Col lg={4} md={4} sm={12}>
        <label className="form-control">{field}</label>
      </Col>
      <Col lg={8} md={8} sm={12}>
        <div className="form-control render-field">{value}</div>
      </Col>
    </Row>
  );

  const RenderObject = ({ field, value }) => (
    <Row>
      <Col lg={4} md={4} sm={12}>
        <label className="form-control">{field}</label>
      </Col>
      <Col lg={8} md={8} sm={12}>
        {GeneralObjectViewer(value)}
      </Col>
    </Row>
  );

  const RenderImage = ({ field, value }) => (
    <Row>
      <Col lg={4} md={4} sm={12}>
        <label className="form-control">{field}</label>
      </Col>
      <Col lg={8} md={8} sm={12}>
        <img src={`.${value}`} height={150} />
      </Col>
    </Row>
  );

  return (
    <div className="custom-input-1">
      <Container>
        {Object.keys(data).map((e) => {
          const child = ToJson(data[e]);

          if (_.isBoolean(data[e])) data[e] = `${data[e]}`

          if (child === null) {
            if (e === 'image') {
              return <RenderImage field={e} value={data[e]} />;
            }
            return <RenderElement field={e} value={data[e]} />;
          }

          return <RenderObject field={e} value={child} />;
        })}
      </Container>
    </div>
  );
};

export class ViewOnlyTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: { data: [], loading: false },
    };

    this.generalStateUpdater = GeneralStateUpdater.bind(this);
  }

  componentDidMount() {
    this.getTableDataRequest();
  }

  renderTableData(name) {
    if (this.state[name].loading === true) return TableLoader("srNo");
    return {
      data: this.state[name].data.map((e, i) => {
        return {
          srNo: i + 1,
          ...e,
        };
      }),
    };
  }

  getTableDataRequest() {
    this.generalStateUpdater("tableData", {
      method: "POST",
      path: this.props.api,
      data: {
        "jobId": this.props.id
      }
    });
  }

  render() {
    return (
      <div className="custom-input-1">
        <Container fluid>
          <Row>
            <Col lg={12} sm={12} md={12}>
              <Table
                columns={this.props.columns}
                className="table1"
                tableName={this.props.tableName}
                keyField="srNo"
                onRefresh={this.getTableDataRequest.bind(this)}
                {...this.renderTableData("tableData")}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
};

export const merchantKeys = ({ data }) => {
  return (
    <div className="custom-input-1">
      <Container>
        <Row>
          <Col lg={4} md={4} sm={12}>
            <label className="form-control">Name</label>
          </Col>
          <Col lg={8} md={8} sm={12}>
            <div className="form-control render-field">{data.email}</div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
