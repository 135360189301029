import React from "react";
import { DynamicActionBtn,DynamicForm, SelectOption } from "../common/RenderCustomView";
import { AddNoti } from "../../helper/Notification";
import { ParseError } from "../../helper/ResponseHelper";
import { MerchantAxiosInstance } from "../../helper/AxiosInstance";
import {
  RenderUpdateBtn,
  GeneralStateUpdaterNew
} from "../../utils/Table";
import ToggleModal from "../common/ToggleModal";
import { API_MERCHANT_ROOT } from "../../helper/constant";

class SettlementKeyTable extends React.Component {
	constructor(props) {
    super(props);

    this.state = {
      keyDetail: { data: this.props.merchantKeyDetail, loading: false },
      currencyList: [],
      merchantDetail: { data: {}, loading: false },
    };
    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    this.getMerchantKeys();
    this.getCurrencyList()
    .then((currencyList) => {

    })
    .catch((error) => {
      console.error(error);
    });
  }

 
 getMerchantKeys() {
   this.generalStateUpdater("merchantDetail", {
     path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service//merchant-key/get-merchant-key-by-id`,
     method: "POST",
     data: {"merchantKeyId":this.state.keyDetail.data.uniqueId},
   });
 }


  getCurrencyList = () => {
    return new Promise((resolve, reject) => {
      MerchantAxiosInstance.post("/admin-dashboard-api-service/currency/get-all-currency")
        .then((resp) => {
          if (resp.status === 200 || resp.statusCode === 201) {
            const currencyList = resp.data.data.rows;
            this.setState({ currencyList: currencyList });
            resolve(currencyList); // resolve the promise with the data
          } else {
            AddNoti(ParseError(resp), { type: "error" });
            reject(resp.data.message); // reject the promise with an error message
          }
        })
        .catch((e) => {
          AddNoti(ParseError(e), { type: "error" });
          reject(e.message); // reject the promise with an error message
        });
    });
  };

  updateConfigBtn() {
    let currencyMap = [{ label: "loading", value: "" }];
    if (this.state.currencyList.length > 0) {
      currencyMap = this.state.currencyList.map((currency) => {
        return { label: currency.symbol, value: `${currency.uniqueId}` };
      });
    }

    const updateSettlement = [
      { name: "merchantKeyId", value: this.state.merchantDetail.data && this.state.merchantDetail.data.uniqueId, disabled: true },

      { name: "currencyId", type: "react-select", options: [...SelectOption, ...currencyMap] },
    ];
    const updateSettlementApi =
    `${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/update-auto-settlement-currency`;
   // return RenderCreateBtn(data, api, "Create Refund Fee");
    const renderUpdateBtnConfigs = { ...RenderUpdateBtn(updateSettlement, updateSettlementApi, "Update Settlement", false, "Update Settlement") };
    renderUpdateBtnConfigs.children = <DynamicForm
                                        data={updateSettlement}
                                        api={updateSettlementApi}
                                      />;
    return renderUpdateBtnConfigs;
  }
  

  render() {
    const {data} = this.state.merchantDetail;
    return (
      <>
        <div
          style={{ border: "1px solid #ccc", padding: "10px", display: "flex" }}
        >
          
          <div style={{ flex: "1" }}>
            <div style={{ marginBottom: "5px" }}>
              {" "}
              <span style={{ fontWeight: "bold"}}>Auto Settle Status:</span> {data && data.autoSettle ? data.autoSettle.toString() : "false"}
            </div>
          </div>
          <div style={{ flex: "1" }}>
            <span style={{ marginRight: "10px" }}>
              <DynamicActionBtn
                api={`${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/enable-auto-settlement`}
                type="actionBtnMerchant"
                options={{
                  variant: "warning",
                  name: "Enable ",
                  data: { merchantKeyId: data && data.uniqueId },
                }}
              />
            </span>
            <DynamicActionBtn
              api={`${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/disable-auto-settlement`}
              type="actionBtnMerchant"
              options={{
                variant: "danger",
                name: "Disable",
                data: { merchantKeyId: data && data.uniqueId },
              }}
            />
            <button style={{ marginLeft: '8px'}} onClick={()=>this.getMerchantKeys()} type="button" class="btn btn-info"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sync-alt" class="svg-inline--fa fa-sync-alt fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M370.72 133.28C339.458 104.008 298.888 87.962 255.848 88c-77.458.068-144.328 53.178-162.791 126.85-1.344 5.363-6.122 9.15-11.651 9.15H24.103c-7.498 0-13.194-6.807-11.807-14.176C33.933 94.924 134.813 8 256 8c66.448 0 126.791 26.136 171.315 68.685L463.03 40.97C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.749zM32 296h134.059c21.382 0 32.09 25.851 16.971 40.971l-41.75 41.75c31.262 29.273 71.835 45.319 114.876 45.28 77.418-.07 144.315-53.144 162.787-126.849 1.344-5.363 6.122-9.15 11.651-9.15h57.304c7.498 0 13.194 6.807 11.807 14.176C478.067 417.076 377.187 504 256 504c-66.448 0-126.791-26.136-171.315-68.685L48.97 471.03C33.851 486.149 8 475.441 8 454.059V320c0-13.255 10.745-24 24-24z"></path></svg></button>
          </div>
        </div>
      {/* update settlement */}
        <div
          style={{ border: "1px solid #ccc", padding: "10px", display: "flex", marginTop:"10px" }}
        >
          <div style={{ flex: "1" }}>
            <div style={{ marginBottom: "5px" }}>
              {" "}
              <span style={{ fontWeight: "bold"}}>Settlement Currency:</span> {data && data.settlementCurrencyName ? data.settlementCurrencyName : ""}
            </div>
          </div>
          <div style={{ flex: "1" }}>
              <ToggleModal {...this.updateConfigBtn()} />
            
          </div>
        </div>
      </>
    );
  }
}

export default SettlementKeyTable;
