import React from "react";
import {  Card, Col, Row } from "react-bootstrap";
import Table from "../common/Table";
import {
  TableLoader,
} from "../../utils/Table";
import moment from "moment";

const quotesColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
  },
  {
    dataField: "merchantUserId",
    text: "merchantUserId",
    classes: "highlight",
  },
  {
    dataField: "checkoutId",
    text: "checkoutId",
  },
  {
    dataField: "currencyId",
    text: "currencyId",
  },
  {
    dataField: "currencyName",
    text: "currencyName",
  },
  {
    dataField: "promisedSettlementRateUser",
    text: "promisedSettlementRateUser",
  },
  {
    dataField: "promisedSettlementRateMerchant",
    text: "promisedSettlementRateMerchant",
  },
  {
    dataField: "promisedSettlementRateGlobiance",
    text: "promisedSettlementRateGlobiance",
  },
  {
    dataField: "priceUser",
    text: "priceUser",
  },
  {
    dataField: "priceMerchant",
    text: "priceMerchant",
  },
  {
    dataField: "priceGlobiance",
    text: "priceGlobiance",
  },
  {
    dataField: "amountUsd",
    text: "amountUsd",
  },
  {
    dataField: "amountGlobiance",
    text: "amountGlobiance",
  },
  {
    dataField: "amountMerchant",
    text: "amountMerchant",
  },
  {
    dataField: "amountUser",
    text: "amountUser",
  },
  {
    dataField: "GHFP",
    text: "GHFP",
  },
  {
    dataField: "GOFP",
    text: "GOFP",
  },  
  {
    dataField: "MOFP",
    text: "MOFP",
  },  
  {
    dataField: "MHFP",
    text: "MHFP",
  },  
  {
    dataField: "gafOneP",
    text: "gafOneP",
  },  
  {
    dataField: "gafTwoP",
    text: "gafTwoP",
  },  
  {
    dataField: "mafOneP",
    text: "mafOneP",
  },  
  {
    dataField: "mafTwoP",
    text: "mafTwoP",
  },  
  {
    dataField: "createdAt",
    text: "createdAt",
  },  
  {
    dataField: "updatedAt",
    text: "updatedAt",
  },  
];

class QuotesTable extends React.Component {
	constructor(props) {
    super(props);

    this.state = {
      quotes: { data: this.props.quotes, loading: false },
    };
  }
  

  renderTableData(name) { 
    if (this.state.quotes.loading === true) return TableLoader("merchantUserId");
    return {
      data: this.props.quotes && this.props.quotes.length > 0 && this.props.quotes.map((e, i) => {
        if(e.createdAt != null) {
          e.createdAt = moment(e.createdAt).format('YYYY-MM-DD H:mm:ss');
        }
        if(e.updateddAt != null) {
          e.updateddAt = moment(e.updateddAt).format('YYYY-MM-DD H:mm:ss');
        }
        return {
          srNo: i + 1,
          ...e,
          actions: [],
        };
      }),
    };
  }

  render() {
    return (
            <>
            <Row style={{ marginTop: "20px"}}>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={quotesColumn}
                  className="table1"
                  tableName="Quotes"
                  keyField="srNo"
                  {...this.renderTableData("quotes")}
                />
              </Col>
            </Row>
            </>
    );
  }
}

export default QuotesTable;
