export const HeaderText = {
  brandName: 'Merchant Admin',
  search: 'Search',
  login: 'Login',
  logout: 'Logout',
};

export const LoginText = {
  login: 'Login',
  email: 'Email',
  emailPH: 'enter email id',
  password: 'Password',
  passwordPH: 'enter password',
  submit: 'Submit',
};

export const BroadcasterText = {
  title: 'Broadcaster',
};

export const ManagementText = {
  title: 'Management',
  exchangeTitle: 'Liquidator Exchange Management',
  exchangeInfo: 'Future Order Management',
  getConfig: 'Config Info',
  addExchange: 'Add Exchange',
  addExchangePair: 'Add Exchange Pair',
  addExchangeSupport: 'Add Exchange Support',
  addExchangeTableTitle: 'Add Exchange Table',
  enableExchangeFO: 'Enable Exchange for future order',
  disableExchangeFO: 'Disable Exchange for future order',
  enablePairFO: 'Enable Pair for future order',
  disablePairFO: 'Disable Pair for future order',
  enableFO: 'Enable future order',
  disableFO: 'Disable future order',
  initializeFO: 'Initialize FO',
};

export const UtilitiesText = {
  resend: 'Resend Verification Link',
  email: 'User Email',
  submit: 'Submit',
  resettfa: 'Reset TFA',
  processBlock: 'Process Block',
  getBlock: 'Get Block',
  generateUserWalletAddress: 'Generate User wallet address',
  generateAllUserWallet: 'Generate All User Wallet',
  generateAllUserAllWallet: 'Generate All User All Wallet',

};

export const RegionalProfitLogsText = {
  title: 'Regional Profit Logs',
  lineGraph: 'Profit Logs Graph',
  lineGraphFreq: 'Profit Logs Graph Frequency',
  profitLogTable: 'Regional Profit Logs Table',
};

export const KycText = {
  kycCompliance: 'User Compliance: KYC',
  kycTable: 'User KYC Table',
  kycChart: 'User KYC',
};

export const KybText = {
  kybCompliance: 'User Compliance: KYB',
  kybUser: 'User KYB',
  kybUpdate: 'Update KYB',
  kybChart: 'User KYB',
};

export const TradeOrderText = {
  tradeTitle: 'Trade order',
};

export const TradeOtcTradeText = {
  tradeOtcTitle: 'Trade Order Otc',
};

export const PaymentDepositText = {
  paymentDepositTitle: 'Payments Deposits',
};

export const DepositRequestText = {
  depositRequestTitle: 'Deposits Request',
  depositRequestTable: 'Deposit Request Table',
  depositRequestChart: 'Deposit Request Chart',
};

export const RemittanceText = {
  settlementRemittanceTitle: 'Settlement: Remittance',
  tableName: 'Remittance',
  settlementRemittanceChart: 'Settlement Remittance Chart',
};

export const BankText = {
  bankTitle: 'Settlement: Banks',
  bankTableName: 'Bank',
  bankChart: 'Bank Chart',
};

export const InternalSettlementText = {
  internalSettlementTitle: 'Settlement: Internal Settlement',
  internalSettlementTableName: 'Internal Settlement',
  internalSettlementChart: 'Internal Settlement Chart',
};
export const InternalSettlementFeeText = {
  internalSettlementFeeTitle: 'Settlement: Internal Settlement Fee',
  internalSettlementFeeTableName: 'Internal Settlement Fee ',
  internalSettlementFeeChart: 'Internal Settlement Fee Chart',
};

export const PaymentWithdrawalText = {
  paymentWithdrawalTitle: 'Payments Withdrawals',
};

export const QuickBuyText = {
  qbtFreq: 'QBT-Frequency',
  qbtPro: 'QBT-Profit',
  qbtTradeFreq: 'Quick Buy Trade: Frequncy & Profit',
  trades: 'Trades',
  qbtTitle: 'Quick Buy Trades',
  qbtSpot: 'Quick Buy Spot',
  qbtLimit: 'Quick Buy Limit',
  qrPrice: 'QB Sp. Price',
  qbtSpotRegionalProfit: 'QBT:Spot - Regional Profit',
  qbtLimitRegionalProfit: 'QBT:Limit - Regional Profit',
};

export const UserTransactionText = {
  userTransactionTitle: 'User Transaction',
  userTransactionChart: 'User Transaction Chart',
  userTransactionTable: 'User Transaction Table',
};

export const WalletTransactionIdUpdateLogsText = {
  WalletTransactionIdUpdateLogsTitle: "User Wallet Tx Hash Update Logs",
  WalletTransactionIdUpdateLogsTable: "User Wallet Tx Hash Update Table",
};

export const UserRegionUpdateLogsText = {
  UserRegionUpdateLogsTitle: "User Region Update Logs",
  UserRegionUpdateLogsTable: "User Region Update Table",
};

export const TradeOrderHistoryText = {
  tradeOrderTitle: 'Trade Orders Details',
  tradeOrderChart: 'Trade Orders Details Chart',
  tradeOrderTable: 'Trade Orders Details Table',
};

export const DealHistoryText = {
  tradeOrderTitle: 'Deal History Details',
  tradeOrderChart: 'Deal History Details Chart',
  tradeOrderTable: 'Deal History Details Table',
};

export const Staking = {
  CreatePool: 'Create pool',
  ListPools: 'List pools',
};

export const DashboardText = {
  totalTotalUser: 'Total User Balance',
  totalBalanceAccounted: 'Balance Accounted',
};

export const UserBalanceUpdateText = {
  userTableName: 'User Wallet',
  userBalanceTitle: 'User: Balance Updates',
};

export const UserWalletText = {
  userTableName: 'User Wallet',
  userTitleName: 'User: Wallet',
};

export const UserWalletNewText = {
  userTableName: "User Wallet New",
  userTitleName: "User: Wallet",
};

export const UserActivityText = {
  UserActivityTitle: 'User: Activity',
  userTableName: 'User Activity',
};

export const UserMisMatchIntradeText = {
  intradeDataTitle: 'User : Intrade Mismatch',
  intradeDataTableName: 'Intrade Mismatch',
};

export const CardIntegrationsText = {
  cardIntegrationsTitle: "Exchange : Card Integrations",
  cardIntegrationsTableName: "Card Integrations",
};

export const ReferralSettingsText = {
  referralSettingsTitle: "Exchange : User Referral Settings",
  referralSettingsTableName: "Referral Settings",
};

export const UserReferralsText = {
  UserReferralsTitle: "Exchange : User Referral Details",
  UserReferralsTableName: "User Referral Details",
};

export const UserReferralDistributionText = {
  UserReferralDistributionTitle:
    "Exchange : User Referral Distribution Details",
  UserReferralDistributionTableName: "User Referral Distribution Details",
};

export const UserReferralDailyLogsText = {
  UserReferralDailyLogsTitle: "Exchange : User Referral Daily Logs",
  UserReferralDailyLogsTableName: "User Referral Daily Logs",
};

export const UserReferralRedemptionLogsText = {
  UserReferralRedemptionLogsTitle: "Exchange : User Referral Redemption Logs",
  UserReferralRedemptionLogsTableName: "User Referral Redemption Logs",
};

export const UserReferralWalletText = {
  UserReferralWalletTitle: "Exchange : User Referral Wallet",
  UserReferralWalletTableName: "User Referral Wallet",
};

export const AllAdminUpdateLogsText = {
  allAdminUpdateLogsTitle: 'Admin : All Admin Update Logs',
  allAdminUpdateLogsTableName: 'All Admin Update Logs',
};

export const GbexStatsText = {
  GbexStatsTitle: "Admin : Gbex Stats",
  GbexStatsTableName: "Gbex Stats",
};

export const SwapStatsText = {
  SwapStatsTitle: "Admin : Swap Stats",
  SwapStatsTableName: "Swap Stats",
};

export const BlockchainBalancesText = {
  blockchainBalancesTitle: 'Admin : Blockchain Balances',
  blockchainBalancesTableName: 'Blockchain Balances',
};

export const ExchangeAdminFeeConfigurationText = {
  exchangeAdminFeeConfigurationTitle:
    'Admin : Minimum Deposit Withdrawal Configuration',
  exchangeAdminFeeConfigurationTableName:
    'Minimum Deposit Withdrawal Configuration',
};

export const AllowedLoginText = {
  allowedLoginTitle: 'Allowed Login',
  allowedLoginChart: 'Allowed Login Chart',
  allowedLoginTable: 'Allowed Login Table',
};

export const MobileSessionText = {
  mobileSessionTitle: 'Mobile Session',
  mobileSessionTable: 'Mobile Session Table',
};

export const RegionText = {
  regionTitle: 'Region',
  regionChart: 'Region Chart',
  regionTable: 'Region Table',
  regionStatus: 'Region Status',
  regionStatusTable: 'Region Status Table',
};

export const ModuleApiText = {
  moduleApi: 'Module API',
  adminData: 'Admin Data',
  moduleData: 'Module Data',
  moduleApiData: 'Module API Data',
  rolePermission: 'Role Permission Data',
};

export const LiquidatorsText = {
  pair: 'Pair',
  side: 'Side',
  price: 'Price',
  status: 'Status',
  createdAt: 'Created At',
  reachedAt: 'Reached At',
  bookAnalysis: 'Book Analysis',
  orderBook: 'Orderbook',
  alerts: 'Alerts',
  priceTicker: 'Price Ticker',
  bid: 'Buy',
  ask: 'Sell',
  selectPair: 'Select Pair',
  decimals: 'Decimals',
  amount: 'Amount',
  total: 'Total',
  wsStatus: 'WS Status',
};
