const DefaultErrorMsg = "Something went wrong";

export const ParseError = (e) => {
  if (e && e.errors && e.errors.length > 0) {
    const error = e.errors[0];
    return error.field ? `${error.field}: ${error.message}` : error.message;
  }

  if (e && e.response && e.response.data) {
    const responseData = e.response.data;

    if (responseData.errors && responseData.errors.length > 0) {
      const error = responseData.errors[0];
      return error.field ? `${error.field}: ${error.message}` : error.message;
    }

    if (responseData.error) {
      const error = responseData.error;
      return error.field ? `${error.field}: ${error.message}` : error.message;
    }
    
    if (responseData.error) {
      const error = responseData.error;
      return error.message ? error.message : DefaultErrorMsg;
    }

    if (responseData.message) {
      return responseData.message;
    }
  }

  if (e && e.message) {
    return e.message;
  }

  return DefaultErrorMsg;
};
