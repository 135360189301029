import React, { Component } from "react";
import { connect } from "react-redux";

const HOC = (ComposedComponent) => {
  class MultiLanguage extends Component {
    render() {
      return (
        <ComposedComponent sidebarCollapsed={this.props.sidebarCollapsed} {...this.props} />
      );
    }
  }

  function mapStateToProps({ sidebarCollapsed }) {
    return { sidebarCollapsed };
  }

  return connect(mapStateToProps)(MultiLanguage);
};

export default HOC;
