import * as types from "../../actions/actionTypes";

const initialState = {
  connected: false,
  rooms: [],
  ts: null,
};

const SocketReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case types.SOCKET_CONNECTED: {
      return { ...state, connected: true, ts: payload.ts };
    }
    case types.SOCKET_DISCONNECTED: {
      return { ...state, connected: false, ts: payload.ts };
    }
    case types.SOCKET_JOIN_ROOM: {
      return state;
    }
    case types.SOCKET_LEAVE_ROOM: {
      return state;
    }
    default:
      return state;
  }
};

export default SocketReducer;
