import React, { useState } from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';

export default function AccordionItem({ title, items, defaultExpanded, handleTree, activeService }) {
  const [expanded, setExpanded] = useState(defaultExpanded);

  const handleToggle = () => {
    setExpanded(prevExpanded => !prevExpanded);
  };

  return (
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey="0" onClick={handleToggle}>
        <span>{title}</span>
        <Button variant="link">
          <i className={`fa ${expanded ? 'fa-minus' : 'fa-plus'}`}></i>
        </Button>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0" alwaysOpen>
        <Card.Body>
          <ul className='logger-ui'>
            {items.map((item, index) => (
              <li key={index}>
                <a href='javascript:;' onClick={()=>{ handleTree(item) }} className={`btn btn-primary ${activeService === item ? 'active' : ''}`}>{item}</a>
              </li>
            ))}
          </ul>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}