import React, { useState } from 'react';
import "./loggerui.css";

const AccordianBlock = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`logger-accordion-separator logger-accordion ${isOpen ? 'active' : ''}`}>
      <a href='javascript:;' className="logger-accordion-title" onClick={toggleAccordion}>
        {title}
        <span className={`arrow ${isOpen ? 'open' : ''}`}></span>
      </a>
      {isOpen && <div className="logger-accordion-content">{content}</div>}
    </div>
  );
};
export default AccordianBlock;
