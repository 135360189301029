import Web3, { utils } from "web3";
import { INFURA_KEY } from "../helper/constant";
import ABI from "./abi/erc20"

const RPC = `https://mainnet.infura.io/v3/${INFURA_KEY}`;

export const PublishRawTx = (tx) => {
  const web3 = new Web3(new Web3.providers.HttpProvider(RPC));
  return web3.eth.sendSignedTransaction(tx);
};

export const FromWei = (n, to = "ether") => {
  return utils.fromWei(n, to);
};

export const GetAccountNonce = (address, config = { type: "latest" }) => {
  const type = config.type || "latest";
  const web3 = new Web3(new Web3.providers.HttpProvider(RPC));
  return web3.eth.getTransactionCount(address, type);
};

export const IsAddress = (address = "") => {
  return utils.isAddress(address);
};

export const GetBalance = (address) => {
  const web3 = new Web3(new Web3.providers.HttpProvider(RPC));
  return web3.eth.getBalance(address);
};

export const GetTokenBalance = (tokenAddress, address) => {
  const web3 = new Web3(new Web3.providers.HttpProvider(RPC));
  const contract = new web3.eth.Contract(ABI, tokenAddress);
  return contract.methods.balanceOf(address).call();
};
