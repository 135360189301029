import {
  DETAIL_FLEXIBLE,
  DETAIL_LOCKED,
  HIDDEN_LOADING,
  VISIBLE_LOADING,
} from '../../actions/actionTypes';

const initialState = {
  detailLocked: {},
  detailFlexible: {},
  loading: false,
};

const stakingReducer = (state = initialState, action) => {
  switch (action.type) {
    case DETAIL_LOCKED:
      state.detailLocked = action.projects;
      return { ...state };

    case DETAIL_FLEXIBLE:
      state.detailFlexible = action.projects;
      return { ...state };

    case VISIBLE_LOADING:
      state.loading = true;
      return { ...state };

    case HIDDEN_LOADING:
      state.loading = false;
      return { ...state };

    default:
      return state;
  }
};

export default stakingReducer;
