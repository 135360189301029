import React, { useState, useEffect, useRef } from "react";
import { MerchantAxiosInstance } from "../../helper/AxiosInstance";
import { AddNoti } from "../../helper/Notification";
import { ParseError } from "../../helper/ResponseHelper";
import loaderGif from "../../assets/gif/loader.gif";
import moment from "moment";
import AccordianBlock
 from "./AccordianBlock";
 import "./loggerui.css";
 import { Card, Col, Accordion, Row, Button } from "react-bootstrap";
 import axios from "axios";
 import AccordianItem
 from "./AccordianItem";
 import { API_MERCHANT_ROOT } from "../../helper/constant";

const LoggerUI = () => {
  const [serviceLoading, setServiceLoading] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [treeLoading, setTreeLoading] = useState(false);
  const [treeList, setTreeList] = useState([]);
  const [treeKeys, setTreeKeys] = useState([]);
  const [loggerLoading, setLoggerLoading] = useState(false);
  const [loggerData, setLoggerData] = useState([]);
  const [logInfo, setLogInfo] = useState({});

  const [activeService, setActiveService] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const readerRef = useRef(null);
  const unmountRef = useRef(null);


  const getServiceList = () => {
		setServiceLoading(true);
		MerchantAxiosInstance.post(`${API_MERCHANT_ROOT}/logger-service/get-service-list`,{})
			.then((resp) => {
				if (resp.status === 200 || resp.status === 201) {
					setServiceList(resp.data.data);
				} else {
					AddNoti(ParseError(resp), { type: "error" });
				}
				setServiceLoading(false);
			})
			.catch((e) => {
				AddNoti(ParseError(e), { type: "error" });
				setServiceLoading(false);
			});
	};

  useEffect(() => {
    getServiceList();
    const logEntriesElement = document.querySelector('#log-entries');
      if (unmountRef.current && logEntriesElement) { 
        logEntriesElement.innerHTML = '';
      }
  }, []);

  useEffect(() => {
    // Cleanup function to abort FileReader when component re-renders
    setLoggerLoading(false);
    return () => {
      unmountRef.current = true;
      const logEntriesElement = document.querySelector('#log-entries');
      if (unmountRef.current && logEntriesElement) { 
        logEntriesElement.innerHTML = '';
      }

      if(readerRef.current) {
        readerRef.current.abort();
      }
    };
  }, [serviceList, treeList]);

  const handleTree = (service) => {
    setActiveService(service);
    setLogInfo({});
    setTreeLoading(true);
		MerchantAxiosInstance.post(`${API_MERCHANT_ROOT}/logger-service/get-tree`, {"serviceName": service })
			.then((resp) => {
				if (resp.status === 200 || resp.status === 201) {
					setTreeList(resp.data.data);
          setTreeKeys(Object.keys(resp.data.data));
				} else {
					AddNoti(ParseError(resp), { type: "error" });
          setTreeKeys([]);
				}
				setTreeLoading(false);
			})
			.catch((e) => {
				AddNoti(ParseError(e), { type: "error" });
				setTreeLoading(false);
        setTreeKeys([]);
			});
  }

  const handleLogger = async(log) => { 
    setLogInfo(log);
    setLoggerLoading(true);
   


     unmountRef.current = true;
     
      if(readerRef.current) {
        readerRef.current.abort();
      }
 document.querySelector('#log-entries').innerHTML = '';

    const apiURL = `${API_MERCHANT_ROOT}/logger-service/get-file-content`;
    let cancelTokenSource;
    
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Previous request canceled");
    }

    cancelTokenSource = axios.CancelToken.source();

    MerchantAxiosInstance.post(apiURL, {"filePath": log.path },{ 
        headers: { 'Content-Type': 'application/json' },
        responseType: 'blob',
        cancelToken: cancelTokenSource.token,
      })
          .then((resp) => {
            // console.log(resp);
            document.querySelector('#log-entries').innerHTML = '';
            const reader = new FileReader(); 
            readerRef.current = reader;
            unmountRef.current = false;
            let buffer = '';
            let isProcessing = false; // Flag to indicate if data processing is in progress
            const chunkSize = 1024; // Adjust the chunk size as needed
            const delayBetweenChunks = 2000; // Adjust the delay between chunks as needed
            
            reader.onload = function () {
              setLoggerLoading(false);
              buffer += reader.result;
              processChunks();
            };
            
            const processChunks = async () => {
              if (isProcessing) return; // If already processing, skip the current invocation
              isProcessing = true;
            
              const lines = buffer.split("\n");
              buffer = lines.pop() || '';
            
              for (const line of lines) {
                const parts = line.split(' ');
                const timestamp = parts[0];
                const level = parts[1];
                const message = parts.slice(2).join(' ');
                const row = `<div><span>${timestamp}</span> <span>${level}</span> ${message}</div>`;
                document.querySelector('#log-entries').insertAdjacentHTML('beforeend', row);
              }
            
              isProcessing = false;
            
              if (!reader.eof) {
                // Continue reading the next chunk after a delay
                await sleep(delayBetweenChunks);
                if(!unmountRef.current) {
                  readNextChunk();
                }

                
              }
            };
            
            const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
            
            const readNextChunk = () => {
              const chunk = resp.data.slice(startByte, startByte + chunkSize);
              reader.readAsText(chunk);
              startByte += chunkSize;
            };
            
            let startByte = 0;
            const endByte = 1024; // Adjust the total data size as needed
            
            reader.eof = false; // Flag to indicate end-of-file
            // Start reading the stream
            readNextChunk();
    })
    .catch((e) => {
      AddNoti(ParseError(e), { type: "error" });
      setLoggerLoading(false);
    });

  }

  const convertBytesToMB = (bytes) => {
    const megabytes = bytes / (1024 * 1024);
    return megabytes.toFixed(2); // Adjust the decimal places as needed
  }

  const handleToggle = () => {
    setExpanded(!expanded);
  };
	
    return (
      <div className="user-wallet main-panel">
        <Card className="custom-card-1">
            <div className="title">
            Logger UI
              <div className="title--bottom"></div>
            </div>

                <div className="card-body">
                  
                  <Row>
                    <Col lg={12} sm={12} md={12}>
                  
                    {/* <div style={{ marginBottom: "15px"}}>
                      {serviceList && serviceList.length > 0 && serviceList.map(service=>
                      <a href="javascript:;" onClick={()=>{ handleTree(service) }} className={`btn btn-primary ${activeService === service ? 'active' : ''}`} style={{ marginRight: "10px", marginBottom: "5px"}}>{service}</a>
                      )}
                    </div> */}
                    
                    <Row style={{ marginBottom: '10px'}}>
                      <Col lg={3} sm={12} md={3}>
                      <Accordion defaultActiveKey="0" alwaysOpen>
                        <AccordianItem
                          title="BLOCKPROCESSORS"
                          items={['blockprocessor-btc', 'blockprocessor-eth', 'blockprocessor-bnb', 'blockprocessor-xdc', 'blockprocessor-trx', 'blockprocessor-service']}
                          defaultExpanded={true}
                          handleTree={handleTree}
                          activeService={activeService}
                        />
                      </Accordion>  
                      </Col>
                      <Col lg={3} sm={12} md={3}>
                      <Accordion defaultActiveKey="0" alwaysOpen>
                        <AccordianItem
                          title="Management Service"
                          items={['health-check-service', 'logger-service', 'admin-dashboard-api-service']}
                          defaultExpanded={true}
                          handleTree={handleTree}
                          activeService={activeService}
                        />
                      </Accordion>  
                      </Col>
                      <Col lg={3} sm={12} md={3}>
                      <Accordion defaultActiveKey="0" alwaysOpen>
                        <AccordianItem
                          title="User Service"
                          items={['widget-api-service', 'merchant-api-service', 'merchant-dashboard-api-service']}
                          defaultExpanded={true}
                          handleTree={handleTree}
                          activeService={activeService}
                        />
                      </Accordion>  
                      </Col>
                      <Col lg={3} sm={12} md={3}>
                      <Accordion defaultActiveKey="0" alwaysOpen>
                        <AccordianItem
                          title="Common Service"
                          items={['payment-processing-service']}
                          defaultExpanded={true}
                          handleTree={handleTree}
                          activeService={activeService}
                        />
                      </Accordion>  
                      </Col>
                    </Row>					



                    <Row>
                    <Col lg={4} md={5}>
                      {activeService && (
                      <Card className="mb-3">
                        {treeLoading && <div><img src={loaderGif} alt="loading" width="20%" height="10rem" /></div>}
                        {!treeLoading && treeKeys && treeKeys.length > 0 && treeKeys.map(keys => 
                           <AccordianBlock
                           title={keys}
                           content={<ul>
                            {treeList[keys] && Object.values(treeList[keys]).length > 0 && Object.values(treeList[keys]).map(log => <li onClick={()=>handleLogger(log)}>{log.name}</li>)}
                           </ul>}
                         />
                        )}
                      </Card>
                      )}
                      {logInfo && Object.keys(logInfo).length > 0 &&  (
                      <Card>
                        <Card.Body>
                          <Card.Title style={{ color: '#2980b9', borderBottom:'1px solid', paddingBottom:'10px'}}><i className="fa fa-file"></i> File Info</Card.Title>
                          <Card.Text>
                            <div>
                            <div class="key-values">
                                <div>
                                  <span class="key">Filename:</span>
                                  <span class="value">{logInfo.name}</span>
                                </div>
                                <div>
                                  <span class="key">Size:</span>
                                  <span class="value">{convertBytesToMB(logInfo.size)} MB</span>
                                </div>
                                <div>
                                  <span class="key">Path:</span>
                                  <span class="value">{logInfo.path}</span>
                                </div>
                                <div>
                                  <span class="key">Relative Path:</span>
                                  <span class="value">{logInfo.relativePath}</span>
                                </div>
                                <div>
                                  <span class="key">Created:</span>
                                  <span class="value">{moment(logInfo.created).format('YYYY-MM-DD HH:mm:ss')}</span>
                                </div>
                                <div>
                                  <span class="key">Updated:</span>
                                  <span class="value">{moment(logInfo.updated).format('YYYY-MM-DD HH:mm:ss')}</span>
                                </div>
                              </div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                      )}
                    </Col>
                    
                    <Col lg={8} md={7}>
                      <div className="logs">
                        {/* <h2>Logger Data</h2> */}
                        <div id="main" style={{ height: "500px", overflow:"scroll", background:"black", color:"green"}}>
                           {loggerLoading && <div><img src={loaderGif} alt="loading" width="20%" height="10rem" /></div>}
                           <div id="log-entries" style={{padding:'10px'}}></div>
                        </div>
                      </div>
                      </Col>
                    
                    </Row>
                
                </Col>
            </Row>
          </div>
        </Card>
      </div>
      
    );
  };

export default LoggerUI;
