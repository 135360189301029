import * as types from "../../actions/actionTypes";

const initialState = {
  currency: {
    loading: false,
    data: null,
  },
  balance: {
    loading: false,
    data: null,
  },
  pairs: {
    loading: false,
    data: null,
  },
  pairedCurrency: {
    loading: false,
    data: null,
  },
  btcNodeBalance: {
    loading: false,
    data: null,
  },
  adminAddress: {
    loading: false,
    data: null,
  },
  adminAddressBalance: {
    data: null,
    loading: false,
  },
  fineryPositions: {
    data: null,
    loading: false,
  },
  b2c2Positions: {
    data: null,
    loading: false,
  },
  pendingBalances: {
    data: null,
    loading: false,
  },
  adjustments: {
    data: null,
    loading: false,
  },
};

const CurrencyReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     *
     * --------------------------------------
     *
     */

    case types.GET_CURRENCY_START: {
      return {
        ...state,
        currency: {
          ...state.currency,
          loading: true,
        },
      };
    }

    case types.GET_CURRENCY_SUCCESS: {
      return {
        ...state,
        currency: {
          data: action.payload,
          loading: false,
        },
      };
    }

    case types.GET_CURRENCY_FAIL: {
      return {
        ...state,
        currency: {
          ...state.currency,
          loading: false,
        },
      };
    }

    /**
     *
     * --------------------------------------
     *
     */

    case types.GET_WALLET_SUMMARY_START: {
      return {
        ...state,
        balance: {
          ...state.balance,
          loading: true,
        },
      };
    }

    case types.GET_WALLET_SUMMARY_SUCCESS: {
      return {
        ...state,
        balance: {
          data: action.payload,
          loading: false,
        },
      };
    }

    case types.GET_WALLET_SUMMARY_FAIL: {
      return {
        ...state,
        balance: {
          ...state.balance,
          loading: false,
        },
      };
    }

    /**
     *
     * --------------------------------------
     *
     */

    case types.GET_PAIRS_START: {
      return {
        ...state,
        pairs: {
          ...state.pairs,
          loading: true,
        },
        pairedCurrency: {
          ...state.pairedCurrency,
          loading: true,
        },
      };
    }

    case types.GET_PAIRS_SUCCESS: {
      return {
        ...state,
        pairs: {
          data: action.payload,
          loading: false,
        },
      };
    }

    case types.GET_PAIRS_FAIL: {
      return {
        ...state,
        pairs: {
          ...state.pairs,
          loading: false,
        },
        pairedCurrency: {
          loading: false,
        },
      };
    }

    case types.PAIRED_CURRENCIES: {
      return {
        ...state,
        pairedCurrency: {
          ...state.pairedCurrency,
          loading: false,
          data: action.payload,
        },
      };
    }

    /**
     *
     * --------------------------------------
     *
     */

    case types.GET_BTC_NODE_START: {
      return {
        ...state,
        btcNodeBalance: {
          ...state.btcNodeBalance,
          loading: true,
        },
      };
    }

    case types.GET_BTC_NODE_SUCCESS: {
      return {
        ...state,
        btcNodeBalance: {
          ...state.btcNodeBalance,
          loading: false,
          data: action.payload,
        },
      };
    }

    case types.GET_BTC_NODE_FAIL: {
      return {
        ...state,
        btcNodeBalance: {
          ...state.btcNodeBalance,
          loading: false,
        },
      };
    }

    /**
     *
     * --------------------------------------
     *
     */

    case types.GET_ADMIN_ADDRESS_START: {
      return {
        ...state,
        adminAddress: {
          ...state.adminAddress,
          loading: true,
        },
      };
    }

    case types.GET_ADMIN_ADDRESS_SUCCESS: {
      return {
        ...state,
        adminAddress: {
          ...state.adminAddress,
          loading: false,
          data: action.payload,
        },
      };
    }

    case types.GET_ADMIN_ADDRESS_FAIL: {
      return {
        ...state,
        adminAddress: {
          ...state.adminAddress,
          loading: false,
        },
      };
    }

    /**
     *
     * --------------------------------------
     *
     */

    case types.ADMIN_ADDRESS_BALANCE_SUCCESS: {
      return {
        ...state,
        adminAddressBalance: {
          ...state.adminAddressBalance,
          loading: false,
          data: action.payload,
        },
      };
    }

    case types.ADMIN_ADDRESS_BALANCE_FAIL: {
      return {
        ...state,
        adminAddressBalance: {
          ...state.adminAddressBalance,
          loading: false,
        },
      };
    }

    /**
     *
     * --------------------------------------
     *
     */

    case types.GET_FINERY_POSITIONS_START: {
      return {
        ...state,
        fineryPositions: {
          ...state.fineryPositions,
          loading: true,
        },
      };
    }

    case types.GET_FINERY_POSITIONS_SUCCESS: {
      return {
        ...state,
        fineryPositions: {
          ...state.fineryPositions,
          loading: false,
          data: action.payload,
        },
      };
    }

    case types.GET_FINERY_POSITIONS_FAIL: {
      return {
        ...state,
        fineryPositions: {
          ...state.fineryPositions,
          loading: false,
        },
      };
    }

    /**
     *
     * --------------------------------------
     *
     */

    case types.GET_B2C2_POSITIONS_START: {
      return {
        ...state,
        b2c2Positions: {
          ...state.b2c2Positions,
          loading: true,
        },
      };
    }

    case types.GET_B2C2_POSITIONS_SUCCESS: {
      return {
        ...state,
        b2c2Positions: {
          ...state.b2c2Positions,
          loading: false,
          data: action.payload,
        },
      };
    }

    case types.GET_B2C2_POSITIONS_FAIL: {
      return {
        ...state,
        b2c2Positions: {
          ...state.b2c2Positions,
          loading: false,
        },
      };
    }


    case types.GET_PENDING_BALANCE_SUCCESS: {
      return {
        ...state,
        pendingBalances: {
          ...state.pendingBalances,
          loading: false,
          data: action.payload,
        },
      };
    }
    
    case types.GET_ADJUSTMENT_SUCCESS: {
      return {
        ...state,
        adjustments: {
          ...state.adjustments,
          loading: false,
          data: action.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default CurrencyReducer;
