import Xdc3, { utils } from "xdc3";

import ABI from "./abi/erc20"

const RPC = `https://rpc.xinfin.network`;

export const PublishRawTx = (tx) => {
  const xdc3 = new Xdc3(new Xdc3.providers.HttpProvider(RPC));
  return xdc3.eth.sendSignedTransaction(tx);
};

export const FromWei = (n, to = "ether") => {
  return utils.fromWei(n, to);
};

export const GetAccountNonce = (address, config = { type: "latest" }) => {
  const type = config.type || "latest";
  const xdc3 = new Xdc3(new Xdc3.providers.HttpProvider(RPC));
  return xdc3.eth.getTransactionCount(address, type);
};

export const IsAddress = (address = "") => {
  return utils.isAddress(address);
};

export const GetBalance = (address) => {
  const xdc3 = new Xdc3(new Xdc3.providers.HttpProvider(RPC));
  return xdc3.eth.getBalance(address);
};

export const GetTokenBalance = (tokenAddress, address) => {
  const xdc3 = new Xdc3(new Xdc3.providers.HttpProvider(RPC));
  const contract = new xdc3.eth.Contract(ABI, tokenAddress);
  return contract.methods.balanceOf(address).call();
};
