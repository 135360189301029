import { AddNoti } from "../helper/Notification";
import _ from "lodash";

export const NotifiedPayloads = (store) => (next) => (action) => {
  if (typeof action.notify !== "object") return next(action);
  const { msg, opts = {} } = action.notify;
  if (!_.isEmpty(msg)) AddNoti(msg, opts);
  delete action.notify;
  return next(action);
};
