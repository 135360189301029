import React from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import moment from "moment";

import Table from "../common/Table";
import ToggleModal from "../common/ToggleModal";
import {
  GeneralStateUpdaterNew,
  TableLoader,
} from "../../utils/Table";
import PieChart from "./PieChart";
import { DynamicActionBtn } from "../common/RenderCustomView";
import { API_MERCHANT_ROOT } from "../../helper/constant";
import ReactSelect from 'react-select';
import { SelectOption } from "../common/RenderCustomView";
import { MerchantAxiosInstance } from "../../helper/AxiosInstance";

const merchantCheckoutColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
  },
  {
    dataField: "requestCurrency",
    text: "Request Currency",
  },
  {
    dataField: "requestCurrencyAmount",
    text: "Request Amount",
  },
  {
    dataField: ["MerchantKey.labelName"],
    text: "Store Name",
  },
  {
    dataField: ["User.email"],
    text: "Merchant Email",
  },
  {
    dataField: "actions",
    text: "Merchant Name",
    classes: "u-center",
    formatter: (cell, row, rowIndex, x) => {
      if (cell && cell.length > 0 && cell[0].props && cell[0].props.options && cell[0].props.options.data) {
        return cell.map((e) => {
          const firstName = e.props.options.data["User.firstName"];
          const lastName = e.props.options.data["User.lastName"];
          if (firstName && lastName) {
            return `${firstName} ${lastName}`;
          } else if (firstName) {
            return firstName;
          } else if (lastName) {
            return lastName;
          } else {
            return "";
          }
        });
      } else {
        return "";
      }
    }
  },
  {
    dataField: "tolerance",
    text: "Tolerance",
  },
  {
    dataField: "duration",
    text: "Duration",
    formatter: (cell) => moment(cell).format("HH:mm:ss"),
  },
  {
    dataField: "checkoutTime",
    text: "Checkout Time",
  },
  {
    dataField: "origin",
    text: "Origin",
  },
  {
    dataField: "status",
    text: "Status",
  },
  {
    dataField: "isActive",
    text: "isActive",
  },
  {
    dataField: "createdAt",
    text: "created At",
  },  
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell, row, rowIndex, x) => {
      if (cell)
        return cell.map((e) => {
          if (e.modalName) return <ToggleModal {...e} />;
          return e;
        });
    },
  },
];

const statuses = [
  { label: "Completed", value: "completed" },
  { label: "Pending", value: "pending" },
]

const origin = [
  { label: "Widget", value: "widget" },
  { label: "Mobile", value: "mobile" },
]

class MerchantCheckout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checkout: { data: [], totalRecords:0, loading: false },
      pageSize: 10,
      currentPage: 0,
      statusChart: { data: [], loading: false },
      merchantChart: { data: [], loading: false },
      stores: [],
      searchParams: { to: "", from: "", status: "", origin: "", checkoutId: "", merchantKeyId:"" },
      srNo:0,
      storeMapping: []
    };


    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    
    this.getDataByMerchant();
    this.getDataByStatus();
    this.getAllStores();

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    var yyyy = today.getFullYear();
    var options = { year: 'numeric', month: '2-digit', day: '2-digit',hour12: false,  hour: '2-digit', minute: '2-digit', second: '2-digit' };

    today = yyyy + '-' + mm + '-' + dd;
  
    var toDate = new Date(today + 'T23:59:00'); // Set "to" date to today at 23:59
   
    var fromDate = new Date(toDate); // Create a copy of "to" date
    fromDate.setDate(fromDate.getDate() - 7); // Subtract 7 days from the "from" date
  
    var fd = String(fromDate.getDate()).padStart(2, '0');
    var fm = String(fromDate.getMonth() + 1).padStart(2, '0'); // January is 0!
    var fy = fromDate.getFullYear();
  
    var fromdate = fy + '-' + fm + '-' + fd;
  
    const searchParams = { ...this.state.searchParams };
    // searchParams["to"] = toDate.toISOString().slice(0, 19).replace('T', ' '); // Convert "to" date to YYYY-MM-DD HH:mm:ss format
    searchParams["to"] = moment(toDate.toLocaleString('en-US', options)).format("YYYY-MM-DD HH:mm:ss");
    searchParams["from"] = fromdate + ' 00:00:00'; // Set "from" date to YYYY-MM-DD 00:00:00 format

    this.setState({ searchParams },()=> this.getMerchantCheckout(0));
  }

  handleChange = ({ currentTarget: input }) => { 
   
    const searchParams = { ...this.state.searchParams };
    searchParams[input.name] = input.value;

    this.setState({ searchParams });
  };

  handlePage = (page) => {
    this.setState({ currentPage: page });
    this.getMerchantCheckout(page);
  }

  handleSearch = () => {
    this.getMerchantCheckout(0);
  }

  getMerchantCheckout(page) {
    
    const {to, from, status, origin, checkoutId, merchantKeyId } = this.state.searchParams;
    console.log("key=",merchantKeyId);
    var params = {
      page: page,
      limit: this.state.pageSize
    };
 
    if(to != '') {
      params.to = moment(to).toISOString();
    }
    if(from != '') {
      params.from = moment(from).toISOString();
    }
    if(status != '') {
      params.status = status;
    }
    if(origin != '') {
      params.origin = origin;
    }
    if(checkoutId != '') {
      params.checkoutId = checkoutId;
    }
    if(merchantKeyId != '') {
      params.merchantKeyId = merchantKeyId;
    }

    // console.log(params);
    this.generalStateUpdater("checkout", {
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/checkout/get-all-checkout`,
      method: "POST",
      data: params,
      postProcessor: (e) => {
        const View = (
          <DynamicActionBtn
            link={`/merchant-admin-checkout-detail/${e.uniqueId}`}
            type="navigateBtn"
            options={{
              variant: "info",
              name: "View",
              data: e,
            }}
            />
        )

        return {
          ...e,
          actions: [
            View
          ],
        };
      },
    });
  }

  getDataByStatus() {
    this.generalStateUpdater("statusChart", {
      method: "POST",
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/checkout/get-stats-by-status`,
    });
    
  }

  getDataByMerchant() {
    this.generalStateUpdater("merchantChart", {
      method: "POST",
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/checkout/get-stats-by-merchant`,
    });
  }

  getAllStores()  {
		MerchantAxiosInstance.post(`${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/get-all-merchant-key`)
			.then((resp) => {
				if (resp.status === 200 || resp.status === 201) {
					
          this.setState({ store: resp.data.data});
          const storeMap =
          resp.data.data != null
            ? resp.data.data.map((store) => ({
                label: `${store.labelName}`,
                value: store.uniqueId,
              }))
            : [{ label: "Loading", value: "" }];

          const options = [...SelectOption, ...storeMap]
          console.log("==",storeMap);
          this.setState({storeMapping: options});

				} else {
					//AddNoti(ParseError(resp), { type: "error" });
				}
			})
			.catch((e) => {
				//AddNoti(ParseError(e), { type: "error" });
			});
	};

  renderTableData(name) {
    const { currentPage, pageSize } =  this.state;
    let srNo = currentPage * pageSize;
    if (this.state[name].loading === true) return TableLoader("requestCurrency");
    return {
      data: this.state[name].data.map((e, i) => {
        if(e.createdAt != null) {
          e.createdAt = moment(e.createdAt).format('YYYY-MM-DD H:mm:ss');
        }
        if(e.checkoutTime != null) {
          e.checkoutTime = moment(e.checkoutTime).format('YYYY-MM-DD H:mm:ss');
        }
        if(e.requestCurrencyAmount) {
          e.requestCurrencyAmount = e.requestCurrencyAmount.toLocaleString();
        }
        if(e.amountInUSD) {
          e.amountInUSD = e.amountInUSD.toLocaleString();
        }
        return {
          srNo: i + 1 + srNo,
          ...e,
        };
      }),
    };
  }

  render() {
    return (
      <div className="kyb main-panel">
        <Card className="custom-card-1">
          
          <div className="card-body">
            <Row>
             <Col lg={6} >
             <div className="title">
                Checkout Status
                <div className="title--bottom"></div>
              </div>
              <Col ol md={12} sm={12}>
                <PieChart
                  data={this.state.statusChart.data}
                  fieldName="status"
                  dataName="count"
                />
              </Col>
              </Col>
              <Col lg={6}>
              <div className="title">
                Merchant Distribution
                <div className="title--bottom"></div>
              </div>
              <Col ol md={12} sm={12}>
                <PieChart
                  data={this.state.merchantChart.data}
                  fieldName="email"
                  dataName="count"
                />
              </Col>
              </Col>
            </Row>
          </div>
        </Card>
        <Card className="custom-card-1">
          <div className="title">
            Merchant: Checkouts
            <div className="title--bottom"></div>
          </div>
          <div className="card-body">
            <Card className="custom-card-1">
                <div className="card-body">
                  <Row>
                   <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>From:</Form.Label>
                        <Form.Control type="datetime-local" value={this.state.searchParams.from} name="from" onChange={this.handleChange} style={{ height: "35px"}} />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>To:</Form.Label>
                        <Form.Control type="datetime-local" value={this.state.searchParams.to} name="to" onChange={this.handleChange} style={{ height: "35px"}} />
                      </Form.Group>
                    </Col>
                    
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Status:</Form.Label>
                        <select className="form-control" name="status" onChange={this.handleChange} style={{ height: "35px"}}>
                          <option value="">select</option>
                          <option value="pending">Pending</option>
                          <option value="completed">Completed</option>
                          <option value="cancelled">Cancelled</option>
                        </select>
                      </Form.Group> 
                    </Col>
                    </Row>
                    <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Origin:</Form.Label>
                        <select className="form-control" name="origin" onChange={this.handleChange} style={{ height: "35px"}}>
                          <option value="">select</option>
                          <option value="widget">Widget</option>
                          <option value="api">API</option>
                          <option value="dashboard">Dashboard</option>
                          <option value="page">Page</option>
                          <option value="button">Button</option>
                          <option value="subscription">Subscription</option>
                        </select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Checkout ID:</Form.Label>
                        <Form.Control type="text" value={this.state.searchParams.checkoutId} name="checkoutId" onChange={this.handleChange} style={{ height: "35px"}} />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Store:</Form.Label>
                        <ReactSelect defaultValue={""} options={this.state.storeMapping} onChange={(e) => {
                          this.setState({
                            searchParams: {
                              ...this.state.searchParams,
                              merchantKeyId: e.value
                            }
                          })
                        }} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ marginTop: "25px"}}>
                      <Button variant="primary" type="button" onClick={()=>this.handleSearch()} style={{ width: '125px', fontSize:'16px'}}>
                        Search
                      </Button>
                    </Col>   
                  </Row>
                </div>
            </Card>

            <Row>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={merchantCheckoutColumn}
                  className="table1"
                  tableName="Merchant Checkout"
                  keyField="srNo"
                  customPagination="custom"
                  totalCount={this.state.checkout.totalRecords}
                  pageSize={this.state.pageSize}
                  pageNumber={this.state.currentPage}
                  // onRefresh={this.getMerchantCheckout.bind(this)}
                  onPageChange={this.handlePage}                
                  {...this.renderTableData("checkout")}
                />
              </Col>
            </Row>

            <br />
          </div>
        </Card>
      </div>
    );
  }
}

export default MerchantCheckout;
