import React, { Component } from "react";
import { connect } from "react-redux";

const RequireLogout = (redirect = "/") => (ComposedComponent) => {
  class Authentication extends Component {
    componentDidMount() {
      if (this.props.auth.isLoggedIn === true) {
        this.props.history.push(redirect);
      }
    }

    componentDidUpdate() {
      if (this.props.auth.isLoggedIn === true) {
        this.props.history.push(redirect);
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps({ auth }) {
    return { auth };
  }

  return connect(mapStateToProps)(Authentication);
};

export default RequireLogout;
