import * as CommonReducer from '../reducers/CommonReducers';
import Login from './Login';
import WalletReducer from './WalletReducer';
import SocketReducer from './Socket';
import stakingReducer from './StakingReducer';
import ModalReducer from './ModalReducer';

const reducer = {
  ...CommonReducer,
  auth: Login,
  wallet: WalletReducer,
  socket: SocketReducer,
  staking: stakingReducer,
  modal: ModalReducer,
};

export default reducer;
