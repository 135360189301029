import { AUTH_STATUS_PATH, AUTH_TOKEN_PATH, toastTime } from "../../helper/constant";
import * as types from "../../actions/actionTypes";
import { toast } from "react-toastify";

const initialState = {
  isLoggedIn: localStorage.getItem(AUTH_STATUS_PATH) === "true",
  ts: Date.now(),
  admin: null,
};

const LoginReducer = (state = initialState, action) => {
  console.log("action", action)
  switch (action.type) {
    case types.ADMIN_DATA: {
      if(action && action.admin && Object.keys(action.admin).length > 0) {
        return {
            ...state,
            admin: action.admin,
            isLoggedIn: true,
        };
    } else {
        localStorage.removeItem('jwt');
        toast.error('The session has expired. Please login.', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: toastTime,
          })
        return {
            ...state,
            isLoggedIn: false,
        };
    }
      // return { ...state, admin: action.admin };
    }

    case types.LOGIN_SUCCESS: {
      // ReconnectSocket();
      return { ...state, isLoggedIn: true, ts: action.ts };
    }

    case types.LOGIN_FAIL: {
      localStorage.setItem(AUTH_STATUS_PATH, "false");
      localStorage.setItem(AUTH_TOKEN_PATH, "");
      return { ...state, isLoggedIn: false, ts: action.ts };
    }

    case types.LOGOUT: {
      localStorage.setItem(AUTH_STATUS_PATH, "false");
      localStorage.setItem(AUTH_TOKEN_PATH, "");
      return { ...state, isLoggedIn: false };
    }

    default:
      return state;
  }
};

export default LoginReducer;
