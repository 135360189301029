import React from "react";
import { Card, Row, Col } from "react-bootstrap";

import { faSort } from "@fortawesome/free-solid-svg-icons";
import {
  textFilter,
  dateFilter,
  numberFilter,
} from "react-bootstrap-table2-filter";
import DatePicker from "react-datepicker";

import Table from "../common/Table";
import ToggleModal from "../common/ToggleModal";
import {
  DateFilter,
  DefaultHeadFormatter,
  GeneralStateUpdater,
  IconHeader,
  TableLoader,
  ViewOnlyForm,
} from "../../utils/Table";
import { DynamicForm } from "../common/RenderCustomView";

const merchantDataColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "email",
    text: "Merchant Email",
    classes: "highlight",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "name",
    text: "Merchant Name",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },

  {
    dataField: "amount",
    text: "Amount",
    filter: numberFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "status",
    text: "Status",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "createdAt",
    text: "Created At",
    filter: dateFilter({
      onFilter: DateFilter("createdAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "updatedAt",
    text: "Updated At",
    filter: dateFilter({
      onFilter: DateFilter("updatedAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell, row, rowIndex, x) => {
      if (cell) return cell.map((e) => <ToggleModal {...e} />);
    },
  },
];

class Refunds extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      refunds: { data: [], loading: false },
    };

    this.generalStateUpdater = GeneralStateUpdater.bind(this);
  }

  componentDidMount() {
    this.getAllRefunds();
  }

  getAllRefunds() {
    this.generalStateUpdater("refunds", {
      path: "/merchant/list-refund",
      method: "POST",
      postProcessor: (e) => {
        const updateData = [
          { name: "refundId", value: e.uniqueId, disabled: true },
          {
            name: "status",
            defaultValue: e.status,
            type: "radio",
            options: [
              { label: "Initiated", value: `initiated` },
              { label: "Completed", value: `completed` },
              { label: "Rejected", value: `rejected` },
            ],
          },
          { name: "txId", defaultValue: e.txId || "" },
          {
            name: "completedAt",
            type: "custom",
            defaultValue: new Date().toString(),
            CustomElement: ({ cb, value, formValues }) => {
              return (
                <DatePicker
                  selected={new Date(value)}
                  onChange={(date) => {
                    cb(date.toString());
                  }}
                />
              );
            },
          },
        ];
        const updateApi = "/merchant/update-refund";

        e.name = e.firstName + " " + e.lastName;

        return {
          ...e,
          actions: [
            {
              modalName: "Refunds",
              children: <ViewOnlyForm data={e} />,
              backdrop: "static",
              centered: true,
              disableSubmit: true,
              keyboard: false,
            },
            {
              modalName: "Update Refund",
              children: <DynamicForm data={updateData} api={updateApi} />,
              backdrop: "static",
              centered: true,
              disableSubmit: true,
              keyboard: false,
              btnName: "Update",
              btnVariant: "warning",
            },
          ],
        };
      },
    });
  }

  renderTableData(name) {
    console.log("name", name, this.state[name]);
    if (this.state[name].loading === true) return TableLoader("email");
    return {
      data: this.state[name].data.map((e, i) => {
        return {
          srNo: i + 1,
          ...e,
        };
      }),
    };
  }

  render() {
    return (
      <div className="kyb main-panel">
        <Card className="custom-card-1">
          <div className="title">
            Merchant: Refunds
            <div className="title--bottom"></div>
          </div>
          <div className="card-body">
            <Row>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={merchantDataColumn}
                  className="table1"
                  tableName="Refunds"
                  keyField="srNo"
                  onRefresh={this.getAllRefunds.bind(this)}
                  {...this.renderTableData("refunds")}
                />
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    );
  }
}

export default Refunds;
