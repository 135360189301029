import axios from "axios";
import $ from "jquery";
import { RemoveExpo } from "./decimals";

export const DecodeHTMLCode = (s) => {
  return $("#dummy").html(s).text();
};

export const EncodeHTMLCode = (s) => {
  return $("#dummy").text(s).html();
};

export const GetXrpBalance = async (addr) => {
  const resp = await axios.get(
    `https://data.ripple.com/v2/accounts/${addr}/balances?currency=XRP`
  );
  return resp.data.balances[0].value;
};

export const AddUniqueArr = (arr1, arr2) => {
  return [...new Set([...arr1, ...arr2])];
};

export const RemoveFromArr = (arr1, arr2) => {
  for (let e of arr2) {
    let i = arr1.indexOf(e);
    if (i >= 0) {
      arr1.splice(i, 1);
    }
  }
  return arr1;
};

export const MergeOrders = orders => {
  orders = [...orders];
  const merged = orders.reduce((acc, cur) => {
      let x = RemoveExpo(cur.price);
      if (!acc[x]) {
          acc[x] = {
              ...cur,
          };
      } else {
          acc[x] = {
              ...acc[x],
              amount: acc[x].amount + cur.amount,
              filled_qty: acc[x].filled_qty + cur.filled_qty,
              total: acc[x].total + cur.total,
              executed_total: acc[x].executed_total + cur.executed_total,
          };
      }

      return acc;
  }, {});

  return [
      ...Object.keys(merged)
          .map(x => merged[x])
          .sort((a, b) => b.price - a.price),
  ];
};