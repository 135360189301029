import { applyMiddleware, createStore, combineReducers } from 'redux';

import ReduxThunk from 'redux-thunk';
import ReduxLogger from 'redux-logger';

import Reducer from './reducers';
import { TimestampedPayloads } from '../middleware/TimestampedPayloads';
import { NotifiedPayloads } from '../middleware/NotifiedPayloads';
import { SideEffects } from '../middleware/SideEffects';

import { ENV } from '../helper/constant';

const middlewares =
  ENV === 'dev'
    ? applyMiddleware(
        ReduxThunk,
        ReduxLogger,
        TimestampedPayloads,
        NotifiedPayloads,
        SideEffects
      )
    : applyMiddleware(
        ReduxThunk,
        TimestampedPayloads,
        NotifiedPayloads,
        SideEffects
      );

console.log('ENV', ENV);

const configureStore = () =>
  createStore(combineReducers({ ...Reducer }), {}, middlewares);

const store = configureStore();

export default store;
