import React from "react";
import { Navbar, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from "lodash";

import * as actions from "../../actions";
import * as types from "../../actions/actionTypes";

export const actionCreator = {
  logout: () => ({
    type: types.LOGOUT,
    notify: {
      msg: "Logout success",
      opts: {
        type: "info",
      },
    },
  }),
};

class Header extends React.Component {
  componentDidMount() {
    if(this.props.auth.isLoggedIn === true) {
      this.props.GetAuthStatus();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(nextProps.auth, this.props.auth);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.auth.isLoggedIn, this.props.auth.isLoggedIn)) {
      if(this.props.auth.isLoggedIn === false) {
        this.props.history.push("/");
      }
    }
  }

  render() {
    const text = this.props.text;

    let btnMsg = "",
      btnClass = "btn custom-header__act-btn",
      onClick = {};

    if (this.props.auth.isLoggedIn === false) {
      btnMsg = text.login;
      onClick = () => {
        this.props.history.push("/login");
      };
      btnClass += " btn-outline-primary";
    } else {
      btnMsg = text.logout;
      onClick = this.props.logout;
      btnClass += " btn-outline-secondary";
    }

    let btn = (
      <button onClick={onClick} className={btnClass}>
        {btnMsg}
      </button>
    );

    return (
      <Navbar className="custom-header">
        <Link className="navbar-brand" to="/">
          {text.brandName}
        </Link>
        <Button
          className="custom-header__toggle"
          onClick={this.props.toggleSidebar}
        >
          <FontAwesomeIcon icon={faBars} />
        </Button>
        {btn}
      </Navbar>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default withRouter(
  connect(mapStateToProps, {
    ...actions,
    ...actionCreator,
    toggleSidebar: actions.SidebarToggle,
    GetAuthStatus: actions.GetAuthStatus,
  })(Header)
);
