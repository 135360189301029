import React, { Component } from "react";
import { connect } from "react-redux";

// import Text from "../../helper/Texts";
import * as TextEN from "../../helper/Texts/Text_EN";

const HOC = (componentName = null) => (ComposedComponent) => {
  class MultiLanguage extends Component {
    render() {
      // const RelevantText =
      //   componentName === null
      //     ? Text(this.props.language)
      //     : Text(this.props.language)[`${componentName}Text`];

      const RelevantText =
        componentName === null
          ? TextEN
          : TextEN[`${componentName}Text`];

      return <ComposedComponent text={RelevantText} {...this.props} />;
    }
  }

  function mapStateToProps({ language, auth }) {
    return { language, auth };
  }

  return connect(mapStateToProps)(MultiLanguage);
};

export default HOC;
