import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import ToggleModal from "../common/ToggleModal";
import Table from "../common/Table";
import {
  GeneralStateUpdaterNew,
  RenderCreateBtn,
  TableLoader,
  ViewOnlyForm,
} from "../../utils/Table";
import moment from "moment";
import { DynamicActionBtn, DynamicForm, SelectOption } from "../common/RenderCustomView";

import { AddNoti } from "../../helper/Notification";
import { ParseError } from "../../helper/ResponseHelper";
import { AxiosInstance, MerchantAxiosInstance } from "../../helper/AxiosInstance";
import CreateRefundFeeForm from "./CreateRefundFeeForm";
import { API_MERCHANT_ROOT } from "../../helper/constant";

const refundFeeDataColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
  },
  {
    dataField: "currencyId",
    text: "currencyId",
    classes: "highlight",
  },
  {
    dataField: ["Currency.symbol"],
    text: "Currency Name",
    classes: "highlight",
  },
  {
    dataField: "currencyType",
    text: "currencyType",
  },
  {
    dataField: "feePercent",
    text: "feePercent",
  },
  {
    dataField: "createdAt",
    text: "Created At",
  },
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell, row, rowIndex, x) => {
      if (cell)
        return cell.map((e, i) => {
          if (e.modalName) return <ToggleModal key={i} {...e} />;
          return e;
        });
    },
  },
];

const currencyType = [
  { label: "Blockchain", value: "blockchain" },
  { label: "Wallet", value: "wallet" },
]

export class RefundFee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refundFee: { data: [], loading: false },
      currencyList: []
    };
    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    this.getRefundFee();
    this.getCurrencyList();
  }

  getCurrencyList = () => {
		MerchantAxiosInstance.post("/admin-dashboard-api-service/currency/get-all-currency")
			.then((resp) => {
        if (resp.status === 200 || resp.statusCode === 201) {

					this.setState({ currencyList: resp.data.data.rows });
				} else {
					AddNoti(ParseError(resp), { type: "error" });
				}
			})
			.catch((e) => {
				AddNoti(ParseError(e), { type: "error" });
			});
	};

  getRefundFee() {
    this.generalStateUpdater("refundFee", {
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/refund-fee/get-all-refund-fee`,
      method: "POST",
      postProcessor: (e) => {
        const updateRefundFeeApi =
          `${API_MERCHANT_ROOT}/admin-dashboard-api-service/refund-fee/update-refund-fee`;
        const updateRefundFee = [
          { name: "refundFeeId", value: e.uniqueId, disabled: true },

          { name: "feePercent", defaultValue: e.feePercent }
        ];
        const Enable = (
          {
            modalName: "Update Refund Fee",
            children: (
              <DynamicForm
                data={updateRefundFee}
                api={updateRefundFeeApi}
              />
            ),
            backdrop: "static",
            centered: true,
            disableSubmit: true,
            keyboard: false,
            btnName: "Update",
            btnVariant: "warning",
          }
        );

        return {
          ...e,
          actions: [
            {
              modalName: "View Refund Fee",
              children: <ViewOnlyForm data={e} />,
              backdrop: "static",
              centered: true,
              disableSubmit: true,
              keyboard: false,
            },
            Enable,
          ],
        };
      },
    });
  }

  createRefundFee() {
    let currencyMap = [{ label: "loading", value: "" }];
    if (this.state.currencyList.length > 0) {
      currencyMap = this.state.currencyList.map((currency) => {
        return { label: currency.symbol, value: `${currency.uniqueId}` };
      });
    }

    const data = [
      { name: "currencyId", type: "react-select", options: [...SelectOption, ...currencyMap] },
      { name: "currencyType", type: "select", options: [...SelectOption, ...currencyType] },
      { name: "feePercent" },
    ];
    const api = `${API_MERCHANT_ROOT}/admin-dashboard-api-service/refund-fee/create-refund-fee`;
   // return RenderCreateBtn(data, api, "Create Refund Fee");
    const renderCreateBtnConfigs = { ...RenderCreateBtn(data, api, "Create Refund Fee") };
    renderCreateBtnConfigs.children = <CreateRefundFeeForm isUserApi={false} data={data} api={api} type="create"/>;
    return renderCreateBtnConfigs;
  }

  renderTableData(name) {
    if (this.state[name].loading === true) return TableLoader("currencyId");
    return {
      data: this.state[name].data.map((e, i) => {
        if(e.createdAt != null) {
          e.createdAt = moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss');
        }
        return {
          srNo: i + 1,
          ...e,
        };
      }),
    };
  }


  render() {
    return (
      <div className="user-wallet main-panel">
        <Card className="custom-card-1">
          <div className="title">
            Merchant: Refund Fee
            <div className="title--bottom"></div>
          </div>

          <div className="card-body">
            
            <Row className="mt-3 mt-md-5">
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={refundFeeDataColumn}
                  className="table1"
                  tableName="Refund Fee"
                  keyField="srNo"
                  createBtn={<ToggleModal {...this.createRefundFee()} />}
                  onRefresh={this.getRefundFee.bind(this)}
                  {...this.renderTableData("refundFee")}
                />
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    );
  }
}

export default RefundFee;
