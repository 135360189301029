import React, { Component } from 'react';

import { Card, Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import * as types from '../actions/actionTypes';
import { AxiosInstance, MerchantAxiosInstance } from '../helper/AxiosInstance';
import { EXCHANGE_ID } from '../helper/constant';
import { ParseError } from '../helper/ResponseHelper';

const initialState = {
  email: '',
  password: '',
  tfa: '',
  loading: false,
};

const actionCreator = {
  loginSuccess: (data) => {
    return {
      type: types.LOGIN_SUCCESS,
      payload: data.data.token,
      notify: {
        msg: 'Login success, Welcome',
        opts: {
          type: 'success',
        },
      },
    }
  },
  loginError: (data) => ({
    type: types.LOGIN_FAIL,
    notify: {
      msg: data.msg,
      opts: {
        type: 'error',
      },
    },
  }),
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.handleLogin = this.handleLogin.bind(this);
  }

  handleLogin() {
    this.setState({ loading: true }, () => {
      MerchantAxiosInstance.post('/admin-dashboard-api-service/auth/login', {
        email: this.state.email,
        password: this.state.password,
      })
        .then((resp) => {
          if (resp.status === 200) {
            this.props.dispatch(actionCreator.loginSuccess(resp.data));
          } else {
            this.props.dispatch(actionCreator.loginError(resp.data));
          }

          this.setState({ loading: false });
        })
        .catch((e) => {
          console.log("----e----- ", e.response)
          this.props.dispatch(actionCreator.loginError({ msg: ParseError(e) }));
          this.setState({ loading: false });
        });
    });
  }

  render() {
    const text = this.props.text;
    return (
      <div className='main-panel login'>
        <Card className='custom-card-1'>
          <Card.Body>
            <Container>
              <div className='title'>
                {text.login}
                <div className='title--bottom'></div>
              </div>
              <div className='card-body custom-input-1'>
                <Row>
                  <label className='form-control'>{text.email}</label>
                  <input
                    className='form-comtrol'
                    placeholder={text.emailPH}
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </Row>

                <Row>
                  <label className='form-control'>{text.password}</label>
                  <input
                    type='password'
                    className='form-comtrol'
                    placeholder={text.passwordPH}
                    value={this.state.password}
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                  />
                </Row>

                {/* <Row>
                  <label className='form-control'>{text.tfa}</label>
                  <input
                    type='text'
                    className='form-comtrol'
                    value={this.state.tfa}
                    onChange={(e) =>
                      this.setState({ tfa: e.target.value })
                    }
                  />
                </Row> */}

                <Row>
                  <Col>
                    <button
                      className='u-float-r btn btn-primary'
                      onClick={this.handleLogin}
                      disabled={this.state.loading}>
                      {text.submit}
                    </button>
                  </Col>
                </Row>
              </div>
            </Container>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
