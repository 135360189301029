import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";

import {
  faTachometerAlt,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";

import { AccordionElement, NavElement } from "./CustomElement";
import AccordionContext from "./Context";

import { HasAccess } from "../../../helper/AccessHelper";


const accordionMerchantAdmin = [
  { text: "Merchants", to: "/merchant-users" },
  { text: "Payment Gateway Currency", to: "/payment-gateway-currency" },
  { text: "Settlement Pair", to: "/settlement-pair" },
  { text: "Refund Fee", to: "/merchant-admin-refund-fee" },
  // { text: "Merchant Requests", to: "/merchant-admin-request" },
  { text: "Merchant Refund Requests", to: "/merchant-admin-refund-request" },
  { text: "Merchant Keys", to: "/merchant-admin-keys" },
  { text: "Merchant Checkouts", to: "/merchant-admin-checkout" },
  { text: "Merchant Payments", to: "/merchant-admin-payment" },
  { text: "Merchant Settlement Order", to: "/merchant-admin-settlement-order" },
  { text: "Fee Distribution", to: "/merchant-admin-fee-distribution" },
  { text: "Merchant Balances ", to: "/merchant-admin-balances" },
  { text: "Merchant Balances Tally", to: "/merchant-balances-tally" },
  { text: "Globiance Fee Collection ", to: "/globiance-fee-collection" },
  { text: "Blockprocessor", to: "/merchant-blockprocessor" },
  { text: "LoggerUI", to: "/merchant-loggerui" },
  { text: "Merchant Currency", to: "/merchant-currency" },
  { text: "Merchant Withdrawal", to: "/merchant-withdrawal" },
  { text: "Page Layouts", to: "/merchant-page-layout" },
];

function renderSidebarElement(data, roleAccess) {
  return data.map((e, i) => {
    const access = HasAccess(roleAccess, e.access);
    if (access.hasAccess === false) return null;
    if (e.hasOwnProperty("list")) {
      return (
        <AccordionElement
          key={i}
          icon={e.icon}
          text={e.text}
          eventKey={i}
          toggle={e.toggle}
          list={e.list}
          sidebarCollapsed={e.sidebarCollapsed}
        />
      );
    } else {
      return (
        <NavElement
          key={i}
          icon={e.icon}
          text={e.text}
          eventKey={i}
          toggle={e.toggle}
          sidebarCollapsed={e.sidebarCollapsed}
          to={e.to}
        />
      );
    }
  });
}

function Sidebar(props) {
  let sidebarClass = "custom-sidebar";

  if (props.sidebarCollapsed === true) {
    sidebarClass += " custom-sidebar__collapsed";
  }

  const [currActive, setcurrActive] = useState("");

  const data = [
    {
      icon: faTachometerAlt,
      text: "Dashboard",
      toggle: setcurrActive,
      sidebarCollapsed: props.sidebarCollapsed,
      to: "/",
      access: [],
    },

    {
      icon: faUserCheck,
      text: "Merchant Admin",
      toggle: setcurrActive,
      sidebarCollapsed: props.sidebarCollapsed,
      list: accordionMerchantAdmin,
      access: [],
      // access: [...ComponentModuleMap.merchantAdmin],
    },

  ];

  useEffect(() => {
    const href = window.document.location.pathname;
    let hrefIndex = data.includesPartial({ to: href });
    if (hrefIndex === null) {
      hrefIndex = data.reduce((acc, curr, i) => {
        if (curr.list) {
          if (curr.list.includesPartial({ to: href }) !== null) acc = i;
        }
        return acc;
      }, null);
    }
    if (hrefIndex !== null) setcurrActive(hrefIndex);
  }, []);

  return (
    <div className={sidebarClass}>
      <AccordionContext.Provider value={currActive}>
        {/* <Accordion>{renderSidebarElement(data, props.access)}</Accordion> */}
        <Accordion>{renderSidebarElement(data, true)}</Accordion>
      </AccordionContext.Provider>
    </div>
  );
}

export default Sidebar;
