import React from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { RemoveExpo } from "../../helper/decimals"
import Table from "../common/Table";
import ToggleModal from "../common/ToggleModal";
import {
  DateFilter,
  DefaultHeadFormatter,
  GeneralStateUpdaterNew,
  IconHeader,
  TableLoader,
  ViewOnlyForm,
} from "../../utils/Table";
import PieChart from "./PieChart";
import { DynamicActionBtn } from "../common/RenderCustomView";
import moment from "moment";
import { API_MERCHANT_ROOT } from "../../helper/constant";

const paymentDistributionDataColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
  },
  {
    dataField: "currencyName",
    text: "currencyName",
    classes: "u-center",
  },
  {
    dataField: "settlementTotal",
    text: "settlementTotal",
    classes: "u-center",
  },
  {
    dataField: "distributedTotal",
    text: "distributedTotal",
    classes: "u-center",
  },
  {
    dataField: "difference",
    text: "difference",
    classes: "u-center",
  },
]


const paymentBlockchainDataColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
  },
  {
    dataField: "currencyName",
    text: "currencyName",
    classes: "u-center",
  },
  {
    dataField: "paymentTotal",
    text: "paymentTotal",
    classes: "u-center",
  },
  {
    dataField: "blockchainTotal",
    text: "blockchainTotal",
    classes: "u-center",
  },
  {
    dataField: "difference",
    text: "difference",
    classes: "u-center",
  },
];

class MerchantBalancesPaymentDistribution extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentDistribution: { data: [], totalRecords:0, loading: false },
      paymentBlockchain: { data: [], loading: false },
      searchParams: { to: "", from: "", tou: "", fromu: "" }
    };


    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    this.getPaymentDistribution();
    this.getPaymentBlockchain();

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    var yyyy = today.getFullYear();
    var options = { year: 'numeric', month: '2-digit', day: '2-digit',hour12: false,  hour: '2-digit', minute: '2-digit', second: '2-digit' };
    today = yyyy + '-' + mm + '-' + dd;
  
    var toDate = new Date(today + 'T23:59:00'); // Set "to" date to today at 23:59
  
    var fromDate = new Date(toDate); // Create a copy of "to" date
    fromDate.setDate(fromDate.getDate() - 7); // Subtract 7 days from the "from" date
  
    var fd = String(fromDate.getDate()).padStart(2, '0');
    var fm = String(fromDate.getMonth() + 1).padStart(2, '0'); // January is 0!
    var fy = fromDate.getFullYear();
  
    var fromdate = fy + '-' + fm + '-' + fd;

    const searchParams = { ...this.state.searchParams };
    searchParams["to"] = moment(toDate.toLocaleString('en-US', options)).format("YYYY-MM-DD HH:mm:ss");
    searchParams["from"] = fromdate + ' 00:00:00';

    searchParams["tou"] = moment(toDate.toLocaleString('en-US', options)).format("YYYY-MM-DD HH:mm:ss");
    searchParams["fromu"] = fromdate + ' 00:00:00';

    this.setState({ searchParams });
  }

  handleDistributionChange = ({ currentTarget: input }) => { 
    
    const searchParams = { ...this.state.searchParams };
    searchParams[input.name] = input.value;

    this.setState({ searchParams });
  };

  
  handleDistributionSearch = () => {
    this.getPaymentDistribution();
  }

  handleBlockchainChange = ({ currentTarget: input }) => { 
    
    const searchParams = { ...this.state.searchParams };
    searchParams[input.name] = input.value;

    this.setState({ searchParams });
  };

  
  handleBlockchainSearch = () => {
    this.getPaymentBlockchain();
  }

 
  getPaymentDistribution() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;
    
    var fromdate = new Date();
    fromdate.setDate(fromdate.getDate() - 7);
    var fd = String(fromdate.getDate()).padStart(2, '0');
    var fm = String(fromdate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var fy = fromdate.getFullYear();

    fromdate = fm + '/' + fd + '/' + fy;

    const {to, from} = this.state.searchParams;
    var params = {
      to: moment(today).toISOString(),
      from: moment(fromdate).toISOString()
    };

    if(to != '') {
      params.to = moment(to).toISOString();
    }
    if(from != '') {
      params.from = moment(from).toISOString();
    }
   
    // console.log(params);
    this.generalStateUpdater("paymentDistribution", {
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/balance-tally/get-payments-vs-distribution`,
      method: "POST",
      data: params,
      postProcessor: (e) => {
        const View = (
          {
            modalName: "View Payments vs Distribution",
            children: <ViewOnlyForm data={e} />,
            backdrop: "static",
            centered: true,
            disableSubmit: true,
            keyboard: false,
          }
        )
        return {
          ...e,
          difference: RemoveExpo(e.difference),
          actions: [
            View
          ],
        };
      },
    });
  }

  handlePage = (page) => {
    this.setState({ currentPage: page });
    this.getBalance(page);
  }

  getPaymentBlockchain() {
   var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;
    
    var fromdate = new Date();
    fromdate.setDate(fromdate.getDate() - 7);
    var fd = String(fromdate.getDate()).padStart(2, '0');
    var fm = String(fromdate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var fy = fromdate.getFullYear();

    fromdate = fm + '/' + fd + '/' + fy;

    const {tou, fromu} = this.state.searchParams;
    var params = {
      tou: moment(today).toISOString(),
      fromu: moment(fromdate).toISOString()
    };
    if(tou != '') {
      params.tou = moment(tou).toISOString();
    }
    if(fromu != '') {
      params.fromu = moment(fromu).toISOString();
    }
   
    // console.log(params);
    this.generalStateUpdater("paymentBlockchain", {
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/balance-tally/get-payments-vs-blockchain`,
      method: "POST",
      data: {to: params.tou, from: params.fromu},
      postProcessor: (e) => {
        // const View = (
        //   {
        //     modalName: "View Merchant Balance Summary",
        //     children: <ViewOnlyForm data={e} />,
        //     backdrop: "static",
        //     centered: true,
        //     disableSubmit: true,
        //     keyboard: false,
        //   }
        // )
        return {
          ...e,
          difference: RemoveExpo(e.difference),
          // actions: [
          //   View
          // ],
        };
      },
    });
  }

  renderTableData(name) {
    if (this.state[name].loading === true) return TableLoader("srNo");
    return {
      data: this.state[name].data.map((e, i) => {
        if(e.createdAt != null) {
          e.createdAt = moment(e.createdAt).format('YYYY-MM-DD');
        }
       
        return {
          srNo: i + 1,
          ...e,
        };
      }),
    };
  }

  render() {
    return (
      <div className="kyb main-panel">
        <Card className="custom-card-1">
        <div>
          <Link to='/merchant-balances-tally' className="btn btn-outline-dark tab-btn" style={{ marginRight: "10px"}}>Balances Tally</Link>
          <Link to='/merchant-balances-payment-distribution' className="btn btn-outline-dark tab-btn active">Payment Distribution</Link>
        </div>
        </Card>
        <Card className="custom-card-1">
          <div className="title">
            Payments Vs Distribution
            <div className="title--bottom"></div>
          </div>
          <div className="card-body">
            <Card className="custom-card-1">
                <div className="card-body">
                  <Row>
                    
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>From:</Form.Label>
                        <Form.Control type="datetime-local" defaultValue={this.state.searchParams.from} value={this.state.searchParams.from} name="from" onChange={this.handleDistributionChange} />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>To:</Form.Label>
                        <Form.Control type="datetime-local" value={this.state.searchParams.to} name="to" onChange={this.handleDistributionChange} />
                      </Form.Group>
                    </Col>

                    <Col style={{ marginTop: "25px"}}>
                      <Button variant="primary" type="button" onClick={()=>this.handleDistributionSearch()}>
                        Search
                      </Button>
                    </Col>   
                    
                  </Row>
                </div>
            </Card>

            <Row>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={paymentDistributionDataColumn}
                  className="table1"
                  tableName="Settlement vs Distribution"
                  keyField="srNo"
                  onRefresh={this.getPaymentDistribution.bind(this)}      
                  {...this.renderTableData("paymentDistribution")}
                />
              </Col>
            </Row>

            <br />
          </div>
        </Card>

        <Card className="custom-card-1">
          <div className="title">
          Payments Vs Blockchain
            <div className="title--bottom"></div>
          </div>
          <div className="card-body">
          <Card className="custom-card-1">
                <div className="card-body">
                  <Row>
                    
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>From:</Form.Label>
                        <Form.Control type="datetime-local" defaultValue={this.state.searchParams.fromu} value={this.state.searchParams.fromu} name="fromu" onChange={this.handleBlockchainChange} />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>To:</Form.Label>
                        <Form.Control type="datetime-local" value={this.state.searchParams.tou} name="tou" onChange={this.handleBlockchainChange} />
                      </Form.Group>
                    </Col>

                    <Col style={{ marginTop: "25px"}}>
                      <Button variant="primary" type="button" onClick={()=>this.handleBlockchainSearch()}>
                        Search
                      </Button>
                    </Col>   
                    
                  </Row>
                </div>
            </Card>
            <Row>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={paymentBlockchainDataColumn}
                  className="table1"
                  tableName="Payment vs Blockchain"
                  keyField="srNo"
                  onRefresh={this.getPaymentBlockchain.bind(this)}      
                  {...this.renderTableData("paymentBlockchain")}
                />
              </Col>
            </Row>

            <br />
          </div>
        </Card>
      </div>
    );
  }
}

export default MerchantBalancesPaymentDistribution;
