import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";

import { MerchantAxiosInstance } from "../../helper/AxiosInstance";
import { AddNoti } from "../../helper/Notification";
import { ParseError } from "../../helper/ResponseHelper";

const RenderInput = ({ field, ...inputProps }) => (
  <Row>
    <Col lg={4} md={4} sm={12}>
      <label className="form-control">{field}</label>
    </Col>
    <Col lg={8} md={8} sm={12}>
      <input className="form-control" {...inputProps} />
    </Col>
  </Row>
);


const PostApi = (path, data, cb) => {
  MerchantAxiosInstance.post(path, { ...data })
    .then((resp) => {
      if (resp.data.statusCode === 200) {
        AddNoti(resp.data.message, { type: "info" });
      } else {
        AddNoti(ParseError(resp), { type: "error" });
      }
    })
    .catch((e) => {
      AddNoti(ParseError(e), { type: "error" });
    })
    .finally(() => {
      cb();
    });
};

export const Update = ({ data, api }) => {
  const [inputs, setInputs] = useState(
    data.map(({ value = "", defaultValue = "" }) => {
      if (value !== "") {
        return value;
      } else if (defaultValue !== "") {
        return defaultValue;
      }
      return "";
    })
  );
  const [loading, setLoading] = useState(false);

  const setInput = (i, v) => setInputs(Object.assign([...inputs], { [i]: v }));

  return (
    <Container className="custom-input-1 table-form">
      {data.map(({ name, ...rest }, i) => (
        <RenderInput
          field={name}
          value={inputs[i]}
          onChange={(e) => setInput(i, e.target.value)}
          {...rest}
        />
      ))}
      <Button
        onClick={() => {
          setLoading(true);
          PostApi(
            api,
            data.reduce((acc, { name }, i) => {
              const query = {};
              query[name] = inputs[i];
              return { ...acc, ...query };
            }, {}),
            () => setLoading(false)
          );
        }}
        variant="primary"
        style={{ width: "10rem", float: "right" }}
        disabled={loading}
      >
        Submit
      </Button>
    </Container>
  );
};


