import React from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import Table from "../common/Table";
import ToggleModal from "../common/ToggleModal";
import { Link, NavLink} from "react-router-dom";
import {
  DefaultHeadFormatter,
  GeneralStateUpdaterNew,
  TableLoader,
  ViewOnlyForm,
} from "../../utils/Table";
import ReactSelect from "react-select";
import { AddNoti } from "../../helper/Notification";
import { ParseError } from "../../helper/ResponseHelper";
import { MerchantAxiosInstance } from "../../helper/AxiosInstance"; 
import moment from "moment";
import { API_MERCHANT_ROOT } from "../../helper/constant";
// import { NavLink } from "react-router-dom/cjs/react-router-dom";

const merchantBalanceColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
  },
  {
    dataField: ["User.email"],
    text: "Merchant Email",
  },
  {
    dataField: "actions",
    text: "Merchant Name",
    classes: "u-center",
    formatter: (cell, row, rowIndex, x) => {
      console.log(cell)
      if (cell && cell.length > 0 && cell[0].children && cell[0].children.props && cell[0].children.props.data) {
        return cell.map((e) => {
          const firstName = e.children.props.data["User.firstName"];
          const lastName = e.children.props.data["User.lastName"];
          if (firstName && lastName) {
            return `${firstName} ${lastName}`;
          } else if (firstName) {
            return firstName;
          } else if (lastName) {
            return lastName;
          } else {
            return "";
          }
        });
      } else {
        return "";
      }
    }
  },
  {
    dataField: "amount",
    text: "amount",
  },
  {
    dataField: "withdraw",
    text: "withdraw",
  },
  {
    dataField: "typeOfRecord",
    text: "typeOfRecord",
  },
  {
    dataField: "currencyId",
    text: "currencyId",
  },
  {
    dataField: "currencyName",
    text: "currencyName",
  },
  {
    dataField: "isActive",
    text: "isActive",
  },
  {
    dataField: "createdAt",
    text: "created At",
    // filter: dateFilter({
    //   onFilter: DateFilter("createdAt"),
    // }),
    headerFormatter: DefaultHeadFormatter,
  },  
  
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell, row, rowIndex, x) => {
      if (cell)
        return cell.map((e) => {
          if (e.modalName) return <ToggleModal {...e} />;
          return e;
        });
    },
  },
];

class MerchantBalance extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      balance: { data: [], totalRecords:0, loading: false },
      pageSize: 10,
      currentPage: 0,
      searchParams: { currency: "" },
      selectOptions: [],
      selectedOption:null
    };


    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    this.getMerchantBalance(0);
    this.getCurrencyList();
  }

  handleChange = (selectedOption) => {
    const searchParams = { ...this.state.searchParams }; 
    if(selectedOption != null) {
      
      searchParams["currency"] = selectedOption.value;
  
      this.setState({ searchParams });
      this.setState({ selectedOption });
    } else {
      searchParams["currency"] = "";
  
      this.setState({ searchParams });
      this.setState({ selectedOption: null });
    }
  }

  handlePage = (page) => {
    this.setState({ currentPage: page });
    this.getMerchantBalance(page);
  }

  handleSearch = () => {
    this.getMerchantBalance(0);
  }

  getMerchantBalance(page) {
    
    const {currency} = this.state.searchParams;
    var params = {};
 
    if(currency != '') {
      params.currency = currency;
    }
    
    // console.log(params);
    this.generalStateUpdater("balance", {
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-balance/get-all-balances`,
      method: "POST",
      data: params,
      postProcessor: (e) => {
        const View = (
          {
            modalName: "View Merchant Balance",
            children: <ViewOnlyForm data={e} />,
            backdrop: "static",
            centered: true,
            disableSubmit: true,
            keyboard: false,
          }
        )

        return {
          ...e,
          actions: [
            View
          ],
        };
      },
    });
  }

  getCurrencyList = () => {
		MerchantAxiosInstance.post("/admin-dashboard-api-service/currency/get-all-currency")
			.then((resp) => {
        if (resp.status === 200 || resp.statusCode === 201) {
          
          let options = [];
          let currencyList = resp.data.data.rows;
          if (currencyList.length > 0) {
            options = currencyList.map((currency) => {
              return { label: currency.symbol, value: `${currency.symbol}` };
            });
          }

          this.setState({ selectOptions: options});

				} else {
					AddNoti(ParseError(resp), { type: "error" });
				}
			})
			.catch((e) => {
				AddNoti(ParseError(e), { type: "error" });
			});
	};


  renderTableData(name) {
    if (this.state[name].loading === true) return TableLoader("amount");
    return {
      data: this.state[name].data.map((e, i) => {
        if(e.createdAt != null) {
          e.createdAt = moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss');
        }
        return {
          srNo: i + 1,
          ...e,
        };
      }),
    };
  }

  render() {
    return (
      <div className="kyb main-panel">
        <Card className="custom-card-1">
        <div>
          <Link to='/merchant-admin-balances' className="btn btn-outline-dark tab-btn active" style={{ marginRight: "10px"}}>Merchant Balances</Link>
          <Link to='/merchant-admin-balances-by-id' className="btn btn-outline-dark tab-btn" style={{ marginRight: "10px"}}>Merchant Balances By Id</Link>
          <Link to='/merchant-admin-balances-by-email' className="btn btn-outline-dark tab-btn">Merchant Balances By Email</Link>
        </div>
        </Card>
        <Card className="custom-card-1">
          <div className="title">
            Merchant: Balances
            <div className="title--bottom"></div>
          </div>
          <div className="card-body">
            <Card className="custom-card-1">
                <div className="card-body">
                  <Row>
                  
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Currency:</Form.Label>
                        <ReactSelect
                          defaultValue={this.state.selectedOption}
                          onChange={this.handleChange}
                          options={this.state.selectOptions}
                          isClearable={true}
                        />
                      </Form.Group>
                    </Col>

                    <Col style={{ marginTop: "35px"}}>
                      <Button variant="primary" type="button" onClick={()=>this.handleSearch()}>
                        Search
                      </Button>
                    </Col>   
                    
                  </Row>
                </div>
            </Card>

            <Row>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={merchantBalanceColumn}
                  className="table1"
                  tableName="Merchant Balance"
                  keyField="srNo"
                  onRefresh={this.getMerchantBalance.bind(this)}
                  {...this.renderTableData("balance")}
                />
              </Col>
            </Row>

            <br />
          </div>
        </Card>
      </div>
    );
  }
}

export default MerchantBalance;
