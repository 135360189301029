import React from "react";
import _ from "lodash";
import ReactPaginate from 'react-paginate';

const Pagination = ({ totalCount, pageSize, pageNumber, onPageChange }) => {
  const totalPages = Math.ceil(totalCount / pageSize);
  // const pages = _.range(1, totalPages + 1);

  const handlePageClick = (data) => {
    onPageChange(data.selected);
  }

  return (
    <div>
      <br/>
      
      <ReactPaginate
        previousLabel={'previous'}
        nextLabel={'next'}
        breakLabel={'...'}
        pageCount={totalPages}
        marginPagesDisplayed={3}
        pageRangeDisplayed={6}
        onPageChange={handlePageClick}
        containerClassName={'pagination justify-content-center'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        breakClassName={'page-item'}
        breakLinkClassName={'page-link'}
        activeClassName={'active'}
        renderOnZeroPageCount={null}
      />
      
    </div>
  );

  // return (
  //   <nav aria-label="Page navigation example" style={{ marginTop: "10px", float:"right"}}>
  //     <ul className="pagination">
  //       {pages.map((page) => (
  //         <li
  //           key={page}
  //           className={(page-1) === pageNumber ? "page-item active" : "page-item"}
  //         >
  //           <a className="page-link" onClick={() => onPageChange(page-1)}>
  //             {page}
  //           </a>
  //         </li>
  //       ))}
  //     </ul>
  //   </nav>
  // );
};

export default Pagination;
