import { toast } from "react-toastify";

export const AddNoti = (
  msg,
  {
    type = "info",
    position = "top-right",
    duration = 2000,
    hideProgressBar = false,
    closeOnClick = true,
    closeButton=true
  }
) => {
  return toast(msg, {
    type: type,
    position,
    autoClose: duration,
    className: "custom-toast",
    hideProgressBar,
    closeOnClick,
    closeButton
  });
};

export const RemoveNoti = (id) => {
  toast.dismiss(id);
};
