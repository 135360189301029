import DashboardAdmin from "./DashboardAdmin";

const Dashboard = (props) => {
  if (!props.access) return <></>;

  if (props.isAdmin) return <DashboardAdmin {...props} />;

  console.log(props);

  return (
    <div className="main-panel ">
      <div className="placeholder-admin">GLOBIANCE ADMIN MANAGEMENT</div>
    </div>
  );
};

export default Dashboard;
