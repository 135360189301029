import Utils, { unitMap } from "xdc3-utils";
import BigNumber from "bignumber.js";

const _mapUnit = unitMap;

export const FromDecimalsAbs = (x, d = 18) => {
  const a = new BigNumber(x);
  const b = new BigNumber(Math.pow(10, d))
  const y = a.div(b).toString()
  console.log(a.toString(), b.toString(), y.toString());
  return new BigNumber(x).div(new BigNumber(Math.pow(10, d))).toString();
};

const ValueToUnit = Object.keys(_mapUnit).reduce((acc, cur) => {
  acc[_mapUnit[cur].length - 1] = cur;
  return acc;
}, {});

function getUnitName(decimal) {
  return ValueToUnit[`${decimal}`];
}

export const RemoveExpo = (x) => {
  const data = String(x).split(/[eE]/);
  if (data.length === 1) return data[0];

  let z = "";
  const sign = x < 0 ? "-" : "";
  const str = data[0].replace(".", "");
  let mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = sign + "0.";
    while (mag++) z += "0";
    return z + str.replace(/^-/, "");
  }
  mag -= str.length;
  while (mag--) z += "0";
  return str + z;
};

export const FromDecimals = (x, decimal = 18) => {
  x = RemoveExpo(x);
  const unitName = getUnitName(decimal);
  return Utils.fromWei(x, unitName);
};

export const ToDecimals = (x, decimal = 18) => {
  if (typeof x === "string") {
    if (x.trim() === "") x = 0;
  }
  x = RemoveExpo(x);

  const unitName = getUnitName(decimal);
  return Utils.toWei(x, unitName);
};

export const pad = (num) => {
  return ("0" + num).slice(-2);
};

export const removeExponentsFromNumber = (number) => {
  const [coefficient, exponent] = String(number).split(/[eE]/);
  if (!exponent) return coefficient;

  const isNegative = number < 0;
  const str = coefficient.replace('.', '').replace(/^\-/, '');
  let zeros = '';

  let magnitude = Number(exponent) + 1;
  if (magnitude < 0) {
    zeros = `${isNegative ? '-' : ''}0.`;
    while (magnitude++) zeros += '0';
    return zeros + str;
  }

  magnitude -= str.length;
  while (magnitude--) zeros += '0';
  return isNegative ? `-${str}${zeros}` : `${str}${zeros}`;
};

export const convertSecondsTohhmmssFormat = (secs) => {
  let minutes = Math.floor(secs / 60);
  secs = secs % 60;
  let hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
};

export const convertDaysIntoMilliSeconds = (days) => {
  return days * 24 * 60 * 60 * 1000;
};

export const convertMilliSecondsIntoDays = (milliseconds) => {
  return milliseconds / (24 * 60 * 60 * 1000);
};

export const ROUND_DECIMALS = 12;

export const ROUND_DECIMALS_PAIR = {
  "GBEX-XDC": [0, 12],
  "XDC-GBEX": [12, 0],

  "GBEX-WXDC": [0, 12],
  "WXDC-GBEX": [12, 0],

  "GBEX-EURG": [0, 12],
  "EURG-GBEX": [12, 0],

  "TSRX-EURG": [12, 0],
  "EURG-TSRX": [0, 12],

  "TSRX-XDC": [4, 2],
  "XDC-TSRX": [2, 4],

  "TSRX-WXDC": [4, 2],
  "WXDC-TSRX": [2, 4],
};

export const ROUND_DECIMALS_AMOUNT = {
  GBEX: 0,
  XDC: 2,
  WXDC: 2,
  TSRX: 2,
  SRX: 2,
  EURG: 2,
};

export const USD_DECIMALS = 2;

export const POOL_DECIMALS = 2;
