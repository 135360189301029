import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Base64 from 'Base64';

function LinkButton({
  children,
  btnName = "View",
  btnVariant = "info",
  disableSubmit = false,
  ...rest
}) {

    // console.log(rest)
    let history = useHistory();

  return (
    <>
      <Button variant={btnVariant} onClick={(e) => {
           history.push("/keyslist/"+Base64.btoa(rest.data.email));
          }}>
        {btnName}
      </Button>
  
    </>
  );
}

export default LinkButton;
