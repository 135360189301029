import React, { useState, useEffect } from "react";
import {  Card, Col, Row, Form } from "react-bootstrap";
import { MerchantAxiosInstance } from "../../helper/AxiosInstance";
import { AddNoti } from "../../helper/Notification";
import { ParseError } from "../../helper/ResponseHelper";
import { useParams, useHistory, Link } from "react-router-dom";
import PaymentsTable from "./PaymentsTable";
import moment from "moment";
import Table from 'react-bootstrap/Table';
import { API_MERCHANT_ROOT } from "../../helper/constant";
import QuotesTable from "./QuotesTable";

const MerchantCheckoutDetail = () => {
	const checkout = useParams();
	
	const history = useHistory();
  const [checkoutDetail, setCheckoutDetail] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [quotes, setQuotes] = useState({});

	const getMerchantCheckoutDetails = (id) => {
		MerchantAxiosInstance.post(`${API_MERCHANT_ROOT}/admin-dashboard-api-service/checkout/get-checkout-detail`, { checkoutId: id })
			.then((resp) => {
       
				if (resp.status === 200 || resp.status === 201) {
          
					setCheckoutDetail(resp.data.data.checkout);
					setPaymentList(resp.data.data.payments);
					setQuotes(resp.data.data.quotes);
				} else {
					AddNoti(ParseError(resp), { type: "error" });
				}
			})
			.catch((e) => {
				AddNoti(ParseError(e), { type: "error" });
			});
	};

	
	useEffect(() => {
		const uniqueId = checkout.id;
		
		if (!uniqueId) return true;
		getMerchantCheckoutDetails(uniqueId);
	}, []);
		
	return (
		<div className="kyb main-panel">
			<form>
				<Card className="custom-card-1">
					<div className="d-flex justify-content-between align-items-center">
						<div className="title">
							Checkout Details
							<div className="title--bottom"></div>
						</div>
						<div className="mr-4">
            <Link to="/merchant-admin-checkout">Checkout</Link> /<span style={{ color: "grey"}}> {checkoutDetail.uniqueId} </span>
            </div>
					</div>
					<div className="card-body">
						<Row>
							<Col lg={12} sm={12} md={12}>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Merchant Unique ID</Form.Label>
                    <Form.Control type="text" disabled="disabled" value={checkoutDetail.merchantUserId} />
                  </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>clientId</Form.Label>
                    <Form.Control type="text" disabled="disabled" value={checkoutDetail.merchantUserId} />
                  </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Request Currency</Form.Label>
                    <Form.Control type="text" disabled="disabled" value={checkoutDetail.requestCurrency} />
                  </Form.Group>
                  </Col>
               </Row>
								
               <Row>
                  <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Request Currency Price (Usd)</Form.Label>
                    <Form.Control type="text" disabled="disabled" value={checkoutDetail.requestCurrencyPriceUsd} />
                  </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Amount In USD</Form.Label>
                    <Form.Control type="text" disabled="disabled" value={checkoutDetail.amountInUSD} />
                  </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Request Currency Amount</Form.Label>
                    <Form.Control type="text" disabled="disabled" value={checkoutDetail.requestCurrencyAmount} />
                  </Form.Group>
                  </Col>
               </Row>

               <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Merchant Key Id</Form.Label>
                    <Form.Control type="text" disabled="disabled" value={checkoutDetail.merchantKeyId} />
                  </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Paid Currency Id</Form.Label>
                    <Form.Control type="text" disabled="disabled" value={checkoutDetail.paidCurrencyId} />
                  </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Paid Currency Name</Form.Label>
                    <Form.Control type="text" disabled="disabled" value={checkoutDetail.paidCurrencyName} />
                  </Form.Group>
                  </Col>
               </Row>

               <Row>
                  <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Tolerance</Form.Label>
                    <Form.Control type="text" disabled="disabled" value={checkoutDetail.tolerance} />
                  </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Duration</Form.Label>
                    <Form.Control type="text" disabled="disabled" value={checkoutDetail.duration} />
                  </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Checkout Time</Form.Label>
                    <Form.Control type="text" disabled="disabled" value={checkoutDetail.checkoutTime} />
                  </Form.Group>
                  </Col>
               </Row>


               <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Expiry Time</Form.Label>
                    <Form.Control type="text" disabled="disabled" value={checkoutDetail.expiryTime} />
                  </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Origin</Form.Label>
                    <Form.Control type="text" disabled="disabled" value={checkoutDetail.origin} />
                  </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Note</Form.Label>
                    <Form.Control type="text" disabled="disabled" value={checkoutDetail.note} />
                  </Form.Group>
                  </Col>
               </Row>

               <Row>
                  <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Store Name</Form.Label>
                    <Form.Control type="text" disabled="disabled" value={checkoutDetail["MerchantKey.labelName"]} />
                  </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Merchant Name</Form.Label>
                    <Form.Control type="text" disabled="disabled" value={checkoutDetail["User.firstName"]+" "+checkoutDetail["User.lastName"]} />
                  </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Merchant Email</Form.Label>
                    <Form.Control type="text" disabled="disabled" value={checkoutDetail["User.email"]} />
                  </Form.Group>
                  </Col>
               </Row>

               <Row>
                  <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Status</Form.Label>
                    <Form.Control type="text" disabled="disabled" value={checkoutDetail.status} />
                  </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>CallbackUrl</Form.Label>
                    <Form.Control type="text" disabled="disabled" value={checkoutDetail.callbackUrl} />
                  </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>isActive</Form.Label>
                    <Form.Control type="text" disabled="disabled" value={checkoutDetail.isActive} />
                  </Form.Group>
                  </Col>
               </Row>

               <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Created At</Form.Label>
                    <Form.Control type="text" disabled="disabled" value={moment(checkoutDetail.createdAt).format('YYYY-MM-DD H:mm:ss')} />
                  </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Updated At</Form.Label>
                    <Form.Control type="text" disabled="disabled" value={moment(checkoutDetail.updatedAt).format('YYYY-MM-DD H:mm:ss')} />
                  </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Deleted At</Form.Label>
                    <Form.Control type="text" disabled="disabled" value={checkoutDetail.deletedAt} />
                  </Form.Group>
                  </Col>
               </Row>

              <QuotesTable quotes={quotes} />

               <PaymentsTable payments={paymentList} />

								<Row style={{ marginBottom: "20px", float: "right" }}>
									<Col lg={12} md={12} sm={12}>
										<button type="button" onClick={()=> history.goBack()} className="btn-warning btn btn-sm mt-2 mr-3">
											Go Back
										</button>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				</Card>
			</form>
		</div>
	);
};

export default MerchantCheckoutDetail;
