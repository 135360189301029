import React from "react";
import { Pie } from 'react-chartjs-2';

export default function PieChart({data, fieldName, fieldName2="", dataName}) {
  
  const colors = [
    '#fca5a5',
    '#fcd34d',
    '#bef264',
    '#6ee7b7',
    '#67e8f9',
    '#93c5fd',
    '#c4b5fd',
    '#f0abfc',
    '#fda4af',
    '#fdba74',
    '#fde047',
    '#86efac',
  
    '#f87171',
    '#fbbf24',
    '#a3e635',
    '#34d399',
    '#22d3ee',
    '#60a5fa',
    '#a78bfa',
    '#e879f9',
    '#fb7185',
    '#fb923c',
    '#facc15',
    '#4ade80',
  
    '#ef4444',
    '#f59e0b',
    '#84cc16',
    '#10b981',
    '#06b6d4',
    '#3b82f6',
    '#8b5cf6',
    '#d946ef',
    '#f43f5e',
    '#f97316',
    '#eab308',
    '#22c55e',
  
    '#dc2626',
    '#d97706',
    '#65a30d',
    '#059669',
    '#0891b2',
    '#2563eb',
    '#7c3aed',
    '#c026d3',
    '#e11d48',
    '#ea580c',
    '#ca8a04',
    '#16a34a',
  ];

  let dataChart = {};

  let listLabel = [];
  const listData = [];

  data.map((res) => {
    if(fieldName2 != "") {
      listLabel.push(res[fieldName] + " - "+ res[fieldName2]);
    } else {
      listLabel.push(res[fieldName]);
    }
    
    listData.push(res[dataName]);

  });
  dataChart.labels = listLabel;

  dataChart.datasets = [{
            data: listData,
            backgroundColor: colors,
            borderColor: colors,
            borderWidth: 0,
          }];

  return (
    <>
    <Pie
        width={400}
        height={200}
        data={dataChart}
        options={{
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              usePointStyle: true,
              boxWidth: 16,
              boxHeight: 16,
              fontColor: '#848E9C',
              padding: 40,
              fontSize: 14,
              filter: function (item) {
                return !!item.text;
              },
              // generateLabels: (chart) => {
              //   const data = chart.data;
              //   if (data.labels.length && data.datasets.length) {
              //     const {labels: {pointStyle}} = chart.legend.options;
              //     return data.labels.map((label, i) => {
              //       const meta = chart.getDatasetMeta(0);
              //       const style = meta.controller.getStyle(i);

              //       return {
              //         fillStyle: style.backgroundColor,
              //         strokeStyle: style.borderColor,
              //         lineWidth: style.borderWidth,
              //         pointStyle: pointStyle,
              //         text:  label + ': ' + new BigNumber(chart.data.datasets[0].data[i]).dp(8).toString(10) + ' $',
              //       };
              //     });
              //   }
              //   return [];
              // }
            },
            fullSize: true,
          },
          tooltip: {
            enabled: data.length > 0,
          },
          cutout: 90,
          responsive: true,
          maintainAspectRatio: false,
        }}
      />
      </>
  )
}
