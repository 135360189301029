import React, { useState, useEffect, useRef } from "react";
import { Button, Card, Col, Row, Form } from "react-bootstrap";
import { MerchantAxiosInstance } from "../../helper/AxiosInstance";
import { AddNoti } from "../../helper/Notification";
import { ParseError } from "../../helper/ResponseHelper";
import Table from 'react-bootstrap/Table';
import Pagination from "./Pagination";
import loaderGif from "../../assets/gif/loader.gif";
import moment from "moment";
import ReactSelect from 'react-select';
import { SelectOption } from "../common/RenderCustomView";
import ToggleModal from "../common/ToggleModal";
import {
  RenderUpdateBtn,
} from "../../utils/Table";
import { DynamicForm } from "../common/RenderCustomView";
import { API_MERCHANT_ROOT } from "../../helper/constant";

const Blockprocessor = () => {
	
	const [currency, setCurrency] = useState("");
	const [syncBlock, setSyncBlock] = useState([]);
	const [syncLoading, setSyncLoading] = useState(true);
	const [missedBlockCount, setMissedBlockCount] = useState(0);
	const [feeWallets, setFeeWallets] = useState([]);
	const [feeLoading, setFeeLoading] = useState(true);
  const [feeWalletBal, setFeeWalletBal] = useState([]);
	const [walletBalLoading, setWalletBalLoading] = useState(true);
	const [adminWallets, setAdminWallets] = useState([]);
	const [adminLoading, setAdminLoading] = useState(true);
	const [adminTransactions, setAdminTransactions] = useState([]);
	const [pageSize, setPagesize] = useState(5);
	const [adminTransactionCurrentPage, setAdminTransactionCurrentPage] = useState(0);
	const [adminTransactionTotalRec, setAdminTransactionTotalRec] = useState(0);
	const [loading, setLoading] = useState(true);
	const [gasTransactions, setGasTransactions] = useState([]);
	const [gasTransactionTotalRec, setGasTransactionTotalRec] = useState(0);
	const [gasTransactionCurrentPage, setGasTransactionCurrentPage] = useState(0);
	const [gasLoading, setGasLoading] = useState(true);
	const [walletTransactions, setWalletTransactions] = useState([]);
	const [walletTransactionTotalRec, setWalletTransactionTotalRec] = useState(0);
	const [walletTransactionCurrentPage, setWalletTransactionCurrentPage] = useState(0);
	const [walletLoading, setWalletLoading] = useState(true);
	const [showTransactionTable, setShowTransactionTable] = useState("");
	const [intervalId, setIntervalId] = useState("");
  const [searchParams, setSearchParams] = useState({toWallet: "", fromWallet: "",toAdmin: "", fromAdmin: "",toGas: "", fromGas: "", currency:"", acknowledged: "", moveTo: "", moved: ""});
  const [shouldCallApi, setShouldCallApi] = useState(false);
  const [lastHitTime, setLastHitTime] = useState(Date.now());
  const [secondsPassed, setSecondsPassed] = useState(0);
  const [missedLastHitTime, setMissedLastHitTime] = useState(Date.now());
  const [missedSecondsPassed, setMissedSecondsPassed] = useState(0);
  const [walletLastHitTime, setWalletLastHitTime] = useState(Date.now());
  const [walletSecondsPassed, setWalletSecondsPassed] = useState(0);
  const [currencyList, setCurrencyList] = useState([]);
  const [currencyLoading, setCurrencyLoading] = useState(true);
  const [blockchainCurrencies, setBlockchainCurrencies] = useState([]);
  const [nodeBalance, setNodeBalance] = useState(0);
  const [nodeLoading, setNodeLoading] = useState(true);

  const intervalIdRef = useRef(null);
  const missedIntervalIdRef = useRef(null);
  const walletIntervalIdRef = useRef(null);


const getLastSyncBlock = (token) => {
  
  setSyncLoading(true);

  const fetchLastSyncBlock = () => {
    
    if (currency === 'XDC' || currency === 'ETH' || currency === 'BNB') {
      setSecondsPassed(0);
      setLastHitTime(Date.now());
      MerchantAxiosInstance.post(`${API_MERCHANT_ROOT}/admin-dashboard-api-service/blockchain/get-last-block-sync`, { blockchain: currency })
        .then((resp) => {
          if (resp.status === 200 || resp.status === 201) {
            setSyncBlock(resp.data.data);
          } else {
            AddNoti(ParseError(resp), { type: "error" });
          }
          setSyncLoading(false);
        })
        .catch((e) => {
          AddNoti(ParseError(e), { type: "error" });
          setSyncLoading(false);
        });
    } else {
      setSyncLoading(false);
      clearInterval(intervalIdRef.current);
    }
  };

  // Clear the previous interval if it exists
  clearInterval(intervalIdRef.current);

  // Fetch immediately with the updated token
  fetchLastSyncBlock();

  // Fetch every 5 seconds with the updated token
  intervalIdRef.current = setInterval(() => {
    fetchLastSyncBlock();
  }, 5000);

};

  
  	const getMissedcBlockCount = (token) => {
      setSyncLoading(true);
      const fetchMissedBlock = () => {
    
        if (currency === 'XDC' || currency === 'ETH' || currency === 'BNB') {
          setMissedSecondsPassed(0);
          setMissedLastHitTime(Date.now());
          MerchantAxiosInstance.post(`${API_MERCHANT_ROOT}/admin-dashboard-api-service/blockchain/get-missed-block-count`, { blockchain: token })
            .then((resp) => {
              if (resp.status === 200 || resp.status === 201) {
                setMissedBlockCount(resp.data.data.missedCount);
              } else {
                AddNoti(ParseError(resp), { type: "error" });
              }
              setSyncLoading(false);
            })
            .catch((e) => {
              AddNoti(ParseError(e), { type: "error" });
              setSyncLoading(false);
            });
        } else {
          setSyncLoading(false);
          clearInterval(missedIntervalIdRef.current);
        }
      };
    
      // Clear the previous interval if it exists
      clearInterval(missedIntervalIdRef.current);
    
      // Fetch immediately with the updated token
      fetchMissedBlock();
    
      // Fetch every 5 seconds with the updated token
      missedIntervalIdRef.current = setInterval(() => {
        fetchMissedBlock();
      }, 5000);
	};

  const getNodeBalance = (token) => {
    setNodeLoading(true);
		MerchantAxiosInstance.post(`${API_MERCHANT_ROOT}/admin-dashboard-api-service/blockchain/get-node-balance`, { blockchain: token })
			.then((resp) => {
				if (resp.status === 200 || resp.status === 201) {
					setNodeBalance(resp.data.data.balance);
				} else {
					AddNoti(ParseError(resp), { type: "error" });
				}
        setNodeLoading(false);
			})
			.catch((e) => {
				AddNoti(ParseError(e), { type: "error" });
        setNodeLoading(false);
			});
	};

	const getFeeWallets = (token) => {
		setFeeLoading(true);
		MerchantAxiosInstance.post(`${API_MERCHANT_ROOT}/admin-dashboard-api-service/blockchain/get-fee-wallets`, { blockchain: token })
			.then((resp) => {
				if (resp.status === 200 || resp.status === 201) {
					setFeeWallets(resp.data.data.wallet);
				} else {
					AddNoti(ParseError(resp), { type: "error" });
				}
				setFeeLoading(false);
			})
			.catch((e) => {
				AddNoti(ParseError(e), { type: "error" });
				setFeeLoading(false);
			});
	};

  const getFeeWalletBalance = (token) => {
    setWalletBalLoading(true);
      const fetchWalletBalance = () => {
    
        if (currency === 'XDC' || currency === 'ETH' || currency === 'BNB' || currency === 'TRX') {
          setWalletSecondsPassed(0);
          setWalletLastHitTime(Date.now());
          MerchantAxiosInstance.post(`${API_MERCHANT_ROOT}/admin-dashboard-api-service/blockchain/get-fee-wallets-balance`, { blockchain: token })
            .then((resp) => {
              if (resp.status === 200 || resp.status === 201) {
                setFeeWalletBal(resp.data.data);
              } else {
                AddNoti(ParseError(resp), { type: "error" });
              }
              setWalletBalLoading(false);
            })
            .catch((e) => {
              AddNoti(ParseError(e), { type: "error" });
              setWalletBalLoading(false);
            });
        } else {
          setWalletBalLoading(false);
          clearInterval(walletIntervalIdRef.current);
        }
      };
    
      // Clear the previous interval if it exists
      clearInterval(walletIntervalIdRef.current);
    
      // Fetch immediately with the updated token
      fetchWalletBalance();
    
      // Fetch every 5 seconds with the updated token
      walletIntervalIdRef.current = setInterval(() => {
        fetchWalletBalance();
      }, 60000);
	};

	const getAdminWallets = (token) => {
		setAdminLoading(true);
		MerchantAxiosInstance.post(`${API_MERCHANT_ROOT}/admin-dashboard-api-service/blockchain/get-admin-wallets`, { blockchain: token })
			.then((resp) => {
				if (resp.status === 200 || resp.status === 201) {
					setAdminWallets(resp.data.data.wallet);
				} else {
					AddNoti(ParseError(resp), { type: "error" });
				}
				setAdminLoading(false);
			})
			.catch((e) => {
				AddNoti(ParseError(e), { type: "error" });
				setAdminLoading(false);
			});
	};

  const getBlockchainCurrencies = (token) => {
		setCurrencyLoading(true);
		MerchantAxiosInstance.post(`${API_MERCHANT_ROOT}/admin-dashboard-api-service/blockchain/get-all-currency`, { blockchain: token })
			.then((resp) => {
				if (resp.status === 200 || resp.status === 201) {
					setBlockchainCurrencies(resp.data.data);
				} else {
					AddNoti(ParseError(resp), { type: "error" });
				}
				setCurrencyLoading(false);
			})
			.catch((e) => {
				AddNoti(ParseError(e), { type: "error" });
				setCurrencyLoading(false);
			});
	};

	const getWalletTransactions = (token, page = 0) => {
    var params = {
      blockchain: token,
      page:page,
      limit: pageSize
    };

    if(searchParams.to != '') {
      params.to = moment(searchParams.to).toISOString();
    }
    if(searchParams.from != '') {
      params.from = moment(searchParams.from).toISOString();
    }
    if (searchParams.currency !== null && searchParams.currency !== undefined && searchParams.currency !== '') {
      params.currency = searchParams.currency;
    }
    if(searchParams.acknowledged !== null && searchParams.acknowledged !== undefined && searchParams.acknowledged.toString() != '') {
      params.acknowledged = searchParams.acknowledged;
    }
    if(searchParams.moveTo.toString() != '') {
      params.moveTo = searchParams.moveTo;
    }
    if(searchParams.moved.toString() != '') {
      params.moved = searchParams.moved;
    }
		setWalletLoading(true);
		MerchantAxiosInstance.post(`${API_MERCHANT_ROOT}/admin-dashboard-api-service/blockchain/get-all-wallet-transactions`, params)
			.then((resp) => {
				if (resp.status === 200 || resp.status === 201) {
					setWalletTransactions(resp.data.data.rows);
					setWalletTransactionTotalRec(resp.data.data.count);
				} else {
					AddNoti(ParseError(resp), { type: "error" });
				}
				setWalletLoading(false);
			})
			.catch((e) => {
				AddNoti(ParseError(e), { type: "error" });
				setWalletLoading(false);
			});
	};

	const getAdminTransactions = (token, page = 0) => {
    var params = {
      blockchain: token,
      page:page,
      limit: pageSize
    };
 
    if(searchParams.toAdmin != '') {
      params.to = moment(searchParams.toAdmin).toISOString();
    }
    if(searchParams.fromAdmin != '') {
      params.from = moment(searchParams.fromAdmin).toISOString();
    }
    if (searchParams.currency !== null && searchParams.currency !== undefined && searchParams.currency !== '') {
      params.currency = searchParams.currency;
    }

		setLoading(true);
		MerchantAxiosInstance.post(`${API_MERCHANT_ROOT}/admin-dashboard-api-service/blockchain/get-all-admin-transactions`, params)
			.then((resp) => {
				if (resp.status === 200 || resp.status === 201) {
					setAdminTransactions(resp.data.data.rows);
					setAdminTransactionTotalRec(resp.data.data.count);
				} else {
					AddNoti(ParseError(resp), { type: "error" });
				}
				setLoading(false);
			})
			.catch((e) => {
				AddNoti(ParseError(e), { type: "error" });
				setLoading(false);
			});
	};

	const getGasTransactions = (token, page) => {
    var params = {
      blockchain: token,
      page:page,
      limit: pageSize
    };
 
    if(searchParams.toGas != '') {
      params.to = moment(searchParams.toGas).toISOString();
    }
    if(searchParams.fromGas != '') {
      params.from = moment(searchParams.fromGas).toISOString();
    }
    if (searchParams.currency !== null && searchParams.currency !== undefined && searchParams.currency !== '') {
      params.currency = searchParams.currency;
    }

		setGasLoading(true);
		MerchantAxiosInstance.post(`${API_MERCHANT_ROOT}/admin-dashboard-api-service/blockchain/get-all-gas-transactions`, params)
			.then((resp) => {
				if (resp.status === 200 || resp.status === 201) {
					setGasTransactions(resp.data.data.rows);
					setGasTransactionTotalRec(resp.data.data.count);
				} else {
					AddNoti(ParseError(resp), { type: "error" });
				}
				setGasLoading(false);
			})
			.catch((e) => {
				AddNoti(ParseError(e), { type: "error" });
				setGasLoading(false);
			});
	};

	const onUpdateBlockprocessor = (e) => {
    setSearchParams({
      ...searchParams,
      "currency":"",
      "acknowledged":"",
      "moveTo":"",
      "moved":""
    })
		setCurrency(e.target.value);
	} 

  useEffect(() => {

    getCurrencyList();
    return () => {
      clearInterval(intervalIdRef.current);
      clearInterval(missedIntervalIdRef.current);
      clearInterval(walletIntervalIdRef.current);
    };
  }, []);

  useEffect(() => {

    setSearchParams({
      ...searchParams,
      "currency":"",
      "acknowledged":"",
      "moveTo":"",
      "moved":""
    }) 

    setShouldCallApi(true);
  }, [showTransactionTable]);

  useEffect(() => {
    if(shouldCallApi) {
      if (currency && showTransactionTable === "wallet") {
        getWalletTransactions(currency, 0);
      } else if (currency && showTransactionTable === "admin") {
        getAdminTransactions(currency, 0);
      } else if (currency && showTransactionTable === "gas") {
        getGasTransactions(currency, 0);
      }

      setShouldCallApi(false);
    }
    
  }, [searchParams]);

  useEffect(() => {
    const secondsInterval = setInterval(() => {
      setSecondsPassed(prevSeconds => prevSeconds + 1);
    }, 1000);

    return () => {
      clearInterval(secondsInterval);
    };
  }, [secondsPassed]);

  useEffect(() => {
    const missedSecondsInterval = setInterval(() => {
      setMissedSecondsPassed(prevSeconds => prevSeconds + 1);
    }, 1000);


    return () => {
      clearInterval(missedSecondsInterval);
    };
  }, [missedSecondsPassed]);

  useEffect(() => {
    const walletSecondsInterval = setInterval(() => {
      setWalletSecondsPassed(prevSeconds => prevSeconds + 1);
    }, 1000);


    return () => {
      clearInterval(walletSecondsInterval);
    };
  }, [walletSecondsPassed]);

  useEffect(() => {

    return () => {
      clearInterval(intervalIdRef.current);
      clearInterval(missedIntervalIdRef.current);
      clearInterval(walletIntervalIdRef.current);
    };
  }, []);


	useEffect(() => {
    // console.log(currency);
		switch (currency) {
			case "BTC":
			  setSyncBlock([]);
			  setMissedBlockCount(0);
			  setFeeWallets([]);
        setFeeWalletBal([]);
			  setAdminWallets([]);
        setBlockchainCurrencies([]);
			  setAdminTransactions([]);
			  setGasTransactions([]);
			  getWalletTransactions(currency, 0);
        getLastSyncBlock(currency);
        getNodeBalance(currency);
			  break;
			case "TRX":
        setNodeBalance(0);
			  setWalletTransactions([]);
			  getFeeWallets(currency);
			  getAdminWallets(currency);
        getWalletTransactions(currency, 0);
        getBlockchainCurrencies(currency);
			  // getAdminTransactions(currency, 0);
			  // getGasTransactions(currency, 0);
        getLastSyncBlock(currency);
        getFeeWalletBalance(currency);
			  break;
			case "BNB":
			case "XDC":
			case "ETH":
        setNodeBalance(0);
			  setWalletTransactions([]);
			  getLastSyncBlock(currency);
			  getMissedcBlockCount(currency);
			  getFeeWallets(currency);
			  getAdminWallets(currency);
        getBlockchainCurrencies(currency);
			  getWalletTransactions(currency, 0);
			  // getAdminTransactions(currency, 0);
			  // getGasTransactions(currency, 0);
        getFeeWalletBalance(currency);
			  break;
			default:
        setNodeBalance(0);
			  setSyncBlock([]);
			  setMissedBlockCount(0);
			  setFeeWallets([]);
        setFeeWalletBal([]);
			  setAdminWallets([]);
        setBlockchainCurrencies([]);
			  setAdminTransactions([]);
			  setGasTransactions([]);
			  setWalletTransactions([]);
        clearInterval(intervalIdRef.current);
        clearInterval(missedIntervalIdRef.current);
        clearInterval(walletIntervalIdRef.current);
			  break;
		  }
		  setShowTransactionTable("wallet");
	}, [currency])

	const handleWalletTransactionPage = (page) => {
		//console.log(page);
		setWalletTransactionCurrentPage(page);
		getWalletTransactions(currency, page);
	  }

	const handleAdminTransactionPage = (page) => {
		//console.log(page);
		setAdminTransactionCurrentPage(page);
		getAdminTransactions(currency, page);
	  }
	
	const handleGasTransactionPage = (page) => {
		//console.log(page);
		setGasTransactionCurrentPage(page);
		getGasTransactions(currency, page);
	  }  


   const handleChange = ({ currentTarget: input }) => { 
      setShouldCallApi(false);
      setSearchParams({
        ...searchParams,
        [input.name]: input.value
      })

    };

    const handleReactSelectChange = (inputType, e) => { 
      // console.log(e);
      setShouldCallApi(false);
      setSearchParams({
        ...searchParams,
        [inputType]: e.value
      })

    };
  
  const handleSearch = (type) => {
    if(type == "wallet") {
      getWalletTransactions(currency, 0);
    } else if(type == "admin") {
      getAdminTransactions(currency, 0);
    } else if(type == "gas") {
      getGasTransactions(currency, 0);
    }
  }
	
  const getCurrencyList = () => {
    MerchantAxiosInstance.post("/admin-dashboard-api-service/currency/get-all-currency")
        .then((resp) => {
				  if (resp.status === 200 || resp.statusCode === 201) {

            let currencyMap = [{ label: "loading", value: "" }];
            let currencyList = resp.data.data.rows;

            if (currencyList.length > 0) {
              currencyMap = currencyList.map((currency) => {
                return { label: currency.symbol, value: `${currency.uniqueId}` };
              });
            }

            setCurrencyList(currencyMap);

          } else {
            AddNoti(ParseError(resp), { type: "error" });
          }
        })
        .catch((e) => {
          AddNoti(ParseError(e), { type: "error" });
        });
  };

  const handleCurrencyStatus = (status, currencyUniqueId) => {
    let apiURL = "";
    if(status) {
      apiURL = `${API_MERCHANT_ROOT}/admin-dashboard-api-service/blockchain/enable-currency`;
    } else {
      apiURL = `${API_MERCHANT_ROOT}/admin-dashboard-api-service/blockchain/disable-currency`;
    }
    MerchantAxiosInstance.post(apiURL, { blockchain: currency, currencyId: currencyUniqueId })
			.then((resp) => {
				if (resp.status === 200 || resp.status === 201) {
					AddNoti(ParseError(resp), { type: "success" });
          getBlockchainCurrencies(currency);
				} else {
					AddNoti(ParseError(resp), { type: "error" });
				}
				
			})
			.catch((e) => {
				AddNoti(ParseError(e), { type: "error" });
			});
  }

  const createCurrencyConfigBtn = () => {
    
    const updateCofigApi =
    `${API_MERCHANT_ROOT}/admin-dashboard-api-service/blockchain/create-currency`;
  
    const updateConfig = [
      { name: "blockchain", value: currency, defaultValue: currency,  disabled: true },

      { name: "name", defaultValue: "" },
      { name: "symbol", defaultValue: ""},
      { name: "address", defaultValue: ""},
      { name: "decimal", type:"number", defaultValue: ""},
      { name: "minAmount", type:"number", defaultValue: ""},
      { name: "minMoveAmount", type:"number", defaultValue: ""},
      { name: "minConfirmation", type:"number", defaultValue: ""},
      { name: "type", type:"select", options: [
        ...SelectOption,
        { label: "Blockchain", value: "blockchain" },
        { label: "Token", value: "token" },
      ]},
      { name: "status", type:"select",  options: [
        ...SelectOption,
        { label: "true", value: "true" },
        { label: "false", value: "false" },
      ]},
    ];


    const renderUpdateBtnConfigs = { ...RenderUpdateBtn(updateConfig, updateCofigApi, "Create Currency", false, "Create") };
    renderUpdateBtnConfigs.children = <DynamicForm
                                        data={updateConfig}
                                        api={updateCofigApi}
                                      />;
    return renderUpdateBtnConfigs;
  }

  useEffect(() => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    var yyyy = today.getFullYear();
    var options = { year: 'numeric', month: '2-digit', day: '2-digit',hour12: false,  hour: '2-digit', minute: '2-digit', second: '2-digit' };
    today = yyyy + '-' + mm + '-' + dd;
  
    var toDate = new Date(today + 'T23:59:00'); // Set "to" date to today at 23:59
  
    var fromDate = new Date(toDate); // Create a copy of "to" date
    fromDate.setDate(fromDate.getDate() - 7); // Subtract 7 days from the "from" date
  
    var fd = String(fromDate.getDate()).padStart(2, '0');
    var fm = String(fromDate.getMonth() + 1).padStart(2, '0'); // January is 0!
    var fy = fromDate.getFullYear();
  
    var fromdate = fy + '-' + fm + '-' + fd;
    setSearchParams({
      ...searchParams,
      "to":  moment(toDate.toLocaleString('en-US', options)).format("YYYY-MM-DD HH:mm:ss"),
      "from": fromdate + ' 00:00:00',
      "toAdmin":  moment(toDate.toLocaleString('en-US', options)).format("YYYY-MM-DD HH:mm:ss"),
      "fromAdmin": fromdate + ' 00:00:00',
      "toGas":  moment(toDate.toLocaleString('en-US', options)).format("YYYY-MM-DD HH:mm:ss"),
      "fromGas": fromdate + ' 00:00:00',
    })
  }, [])

  const refreshAll = () => {
    switch (currency) {
			case "BTC":
			  setSyncBlock([]);
			  setMissedBlockCount(0);
			  setFeeWallets([]);
        setFeeWalletBal([]);
			  setAdminWallets([]);
        setBlockchainCurrencies([]);
			  setAdminTransactions([]);
			  setGasTransactions([]);
			  getWalletTransactions(currency, 0);
        getLastSyncBlock(currency);
        getNodeBalance(currency);
			  break;
			case "TRX":
        setNodeBalance(0);
			  setWalletTransactions([]);
			  getFeeWallets(currency);
			  getAdminWallets(currency);
        getWalletTransactions(currency, 0);
        getBlockchainCurrencies(currency);
			  // getAdminTransactions(currency, 0);
			  // getGasTransactions(currency, 0);
        getLastSyncBlock(currency);
        getFeeWalletBalance(currency);
			  break;
			case "BNB":
			case "XDC":
			case "ETH":
        setNodeBalance(0);
			  setWalletTransactions([]);
			  getLastSyncBlock(currency);
			  getMissedcBlockCount(currency);
			  getFeeWallets(currency);
			  getAdminWallets(currency);
        getBlockchainCurrencies(currency);
			  getWalletTransactions(currency, 0);
			  // getAdminTransactions(currency, 0);
			  // getGasTransactions(currency, 0);
        getFeeWalletBalance(currency);
			  break;
			default:
        setNodeBalance(0);
			  setSyncBlock([]);
			  setMissedBlockCount(0);
			  setFeeWallets([]);
        setFeeWalletBal([]);
			  setAdminWallets([]);
        setBlockchainCurrencies([]);
			  setAdminTransactions([]);
			  setGasTransactions([]);
			  setWalletTransactions([]);
        clearInterval(intervalIdRef.current);
        clearInterval(missedIntervalIdRef.current);
        clearInterval(walletIntervalIdRef.current);
			  break;
		  }
		  setShowTransactionTable("wallet");
  }

  const refreshWallet = () => {
    getFeeWalletBalance(currency);
  }
  

	return (
    <div className="kyb main-panel">
      <form>
        <Card className="custom-card-1">
          <div className="d-flex justify-content-between align-items-center">
            <div className="title">
              Blockprocessor
              <div className="title--bottom"></div>
            </div>
            <div className="mr-4">&nbsp;</div>
          </div>
          <div className="card-body">
            <Row>
              <Col lg={8} sm={8} md={8}>
                <Row style={{ marginBottom: "20px", marginLeft: "8px" }}>
                  <Col lg={8} md={8} sm={12}>
                    <select
                      name="currencyId"
                      id="currencyId"
                      className="form-control"
					  onChange={onUpdateBlockprocessor}
                    >
                      <option value="">select Blockprocessor</option>
                      <option value="BTC"> BTC </option>
                      <option value="TRX"> TRX </option>
                      <option value="ETH"> ETH </option>
                      <option value="BNB"> BNB </option>
                      <option value="XDC"> XDC </option>
                    </select>
                  </Col>
				          <Col lg={4} md={4} sm={12}>
                  &nbsp;
                  </Col>
                </Row>
              </Col>

              <Col lg={4} md={4} sm={12}>
              <a className="btn btn-primary" href="javascript:;" onClick={refreshAll} style={{ float: 'right', marginRight: '15px'}}>Refresh All</a>
              </Col>
            </Row>

      {/* Sync and Missed Block */}
			{["BTC"].includes(currency) && (
            <Row style={{ marginLeft: '5px'}}>
              <Col lg={12} sm={12} md={12}>
                <div
                  class="block"
                  style={{
                    display: "inline-block",
                    marginRight: "20px",
                    padding: "20px",
                    backgroundColor: "#f0f0f0",
                    borderRadius: "5px",
                  }}
                >
                  { nodeLoading && (
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  )}
                  <span class="label"> Node Balance</span>
                  <br/>
                  {!nodeLoading && (<span class="count" style={{ fontWeight:'bold'}}> { nodeBalance && nodeBalance || 0 } </span>)}
                  
                  
                </div>
              </Col>
            </Row>
			)}      

			{/* Sync and Missed Block */}
			{["XDC", "BNB", "ETH"].includes(currency) && (
            <Row style={{ marginLeft: '5px'}}>
              <Col lg={12} sm={12} md={12}>
                <div
                  class="block"
                  style={{
                    display: "inline-block",
                    marginRight: "20px",
                    padding: "20px",
                    backgroundColor: "#f0f0f0",
                    borderRadius: "5px",
                  }}
                >
                  { syncLoading && (
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  )}
                  {!syncLoading && (<span class="count" style={{ fontWeight:'bold'}}> { syncBlock && syncBlock.blockHeight && syncBlock.blockHeight.blockNumber || 0 } </span>)}
                  
                  <span class="label"> Block Numbers</span>
                </div>
                <div
                  class="block"
                  style={{
                    display: "inline-block",
                    marginRight: "20px",
                    padding: "20px",
                    backgroundColor: "#f0f0f0",
                    borderRadius: "5px",
                  }}
                >
                  { syncLoading && (
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  )}
                  {!syncLoading && (<span class="count" style={{ fontWeight:'bold'}}>{ missedBlockCount && missedBlockCount > 0 && missedBlockCount } </span>)}
                  <span class="label"> Missed Counts</span>
                </div>
              </Col>
              <span style={{ marginLeft: "15px"}}>Last updated: {secondsPassed} seconds ago</span>
            </Row>
			)}

			{/* Total Events block */}
			{["XDC", "BNB", "ETH"].includes(currency) && (
            <Row>
              <Col lg={12} sm={12} md={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="title">
                    Total Events
                    <div className="title--bottom"></div>
                  </div>
                  <div className="mr-4">&nbsp;</div>
                </div>
                <div className="card-body">
                  <Row>
                    <Col lg={12} sm={12} md={12}>
                      <Row style={{ marginLeft: "5px", marginRight: "5px" }}>
                        <Table striped bordered hover responsive>
                          <thead>
                            <tr>
                              <th>Sr#</th>
                              <th>blockNumber</th>
                              <th>Currency</th>
                              <th>Address</th>
                              <th>Decimal</th>
                              <th>minAmount</th>
                              <th>gasCostLimit</th>
                              <th>type</th>
                              <th>status</th>
                              <th>createdAt</th>
                            </tr>
                          </thead>
                          <tbody>
						  { syncLoading && <tr><td colSpan={10} align="center"><img src={loaderGif} alt="loading" width="20%" height="10rem" /></td></tr> }	
						  { !syncLoading && syncBlock && syncBlock.eventBlockHeight && syncBlock.eventBlockHeight.length == 0 && <tr><td colSpan={10} align="center">No Record Found</td></tr>}
						  { !syncLoading && syncBlock && syncBlock.eventBlockHeight && syncBlock.eventBlockHeight.length > 0 && syncBlock.eventBlockHeight.map((item, index) => 
                            <tr>
                              <td>{index+1}</td>
                              <td>{item.blockNumber}</td>
                              <td>{item["Currency.name"]}</td>
                              <td>{item["Currency.address"]}</td>
                              <td>{item["Currency.decimal"]}</td>
                              <td>{item["Currency.minAmount"]}</td>
                              <td>{item["Currency.gasCostLimit"]}</td>
                              <td>{item["Currency.type"]}</td>
                              <td>{item["Currency.status"]}</td>
                              <td>{item["Currency.createdAt"] && moment(item["Currency.createdAt"]).format('YYYY-M-DD h:mm:ss')}</td>
                            </tr>
							)}
                          </tbody>
                        </Table>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
			)}

			{/* Fee Wallets block */}
			{["XDC", "BNB", "ETH", "TRX"].includes(currency) && (
            <Row>
              <Col lg={12} sm={12} md={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="title">
                    Fee Wallets
                    <div className="title--bottom"></div>
                  </div>
                  <div className="mr-4">&nbsp;</div>
                </div>
                <div className="card-body">
                  <Row>
                    <Col lg={12} sm={12} md={12}>
                      <Row style={{ marginLeft: "5px", marginRight: "5px" }}>
                        <Table striped bordered hover responsive>
                          <thead>
                            <tr>
                              <th>Sr#</th>
                              <th>currency</th>
                              <th>address</th>
                              <th>label</th>
                              <th>status</th>
                              <th>createdAt</th>
                            </tr>
                          </thead>
                          <tbody>
						  { feeLoading && <tr><td colSpan={10} align="center"><img src={loaderGif} alt="loading" width="20%" height="10rem" /></td></tr> }
						  { !feeLoading && feeWallets && feeWallets.length == 0 && <tr><td colSpan={10} align="center">No Record Found</td></tr>}
						  { !feeLoading && feeWallets && feeWallets.length > 0 && feeWallets.map((item, index) => 
                            <tr>
                              <td>{index+1}</td>
                              <td>{item.currency}</td>
                              <td>{item.address}</td>
                              <td>{item.label}</td>
                              <td>{item.status ? item.status.toString(): "false"}</td>
                              <td>{moment(item.createdAt).format('YYYY-M-DD h:mm:ss')}</td>
                            </tr>
							)}
                          </tbody>
                        </Table>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
			)}

      {/* Fee Wallets - balances block */}
			{["XDC", "BNB", "ETH", "TRX"].includes(currency) && (
            <Row>
              <Col lg={12} sm={12} md={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="title">
                    Fee Wallets - Balances <span style={{ marginLeft: "15px", fontSize:'12px'}}>(Last updated: {walletSecondsPassed} seconds ago)</span>
                    <div className="title--bottom"></div>
                  </div>
                  <div className="mr-4">
                    <a className="btn btn-primary" onClick={refreshWallet} href="javascript:;">Refresh</a>
                  </div>
                </div>
                <div className="card-body">
                  <Row>
                    <Col lg={12} sm={12} md={12}>
                      <Row style={{ marginLeft: "5px", marginRight: "5px" }}>
                        <Table striped bordered hover responsive>
                          <thead>
                            <tr>
                              <th>Sr#</th>
                              <th>address</th>
                              <th>balance</th>
                              <th>balanceWei</th>
                            </tr>
                          </thead>
                          <tbody>
                          { walletBalLoading && <tr><td colSpan={10} align="center"><img src={loaderGif} alt="loading" width="20%" height="10rem" /></td></tr> }
                          { !walletBalLoading && feeWalletBal && feeWalletBal.length == 0 && <tr><td colSpan={10} align="center">No Record Found</td></tr>}
                          { !walletBalLoading && feeWalletBal && feeWalletBal.length > 0 && feeWalletBal.map((item, index) => 
                            <tr>
                              <td>{index+1}</td>
                              <td>{item.address}</td>
                              <td>{item.balance}</td>
                              <td>{item.balanceWei}</td>
                            </tr>
							)}
                          </tbody>
                        </Table>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
			)}

 			{/* Admin Wallets block */}
			 {["XDC", "BNB", "ETH", "TRX"].includes(currency) && (
            <Row>
              <Col lg={12} sm={12} md={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="title">
                    Admin Wallets
                    <div className="title--bottom"></div>
                  </div>
                  <div className="mr-4">&nbsp;</div>
                </div>
                <div className="card-body">
                  <Row>
                    <Col lg={12} sm={12} md={12}>
                      <Row style={{ marginLeft: "5px", marginRight: "5px" }}>
                        <Table striped bordered hover responsive>
                          <thead>
                            <tr>
                              <th>Sr#</th>
                              <th>currency</th>
                              <th>address</th>
                              <th>label</th>
                              {/* <th>status</th> */}
                              <th>createdAt</th>
                            </tr>
                          </thead>
                          <tbody>
						  { adminLoading && <tr><td colSpan={10} align="center"><img src={loaderGif} alt="loading" width="20%" height="10rem" /></td></tr> }
						  { !adminLoading && adminWallets && adminWallets.length == 0 && <tr><td colSpan={10} align="center">No Record Found</td></tr>}
						  { !adminLoading && adminWallets && adminWallets.length > 0 && adminWallets.map((item, index) => 
                            <tr>
                              <td>{index+1}</td>
                              <td>{item.currency}</td>
                              <td>{item.address}</td>
                              <td>{item.label}</td>
                              {/* <td>{item.status ? item.status.toString(): "false"}</td> */}
                              <td>{moment(item.createdAt).format('YYYY-M-DD h:mm:ss')}</td>
                            </tr>
							)}
                          </tbody>
                        </Table>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
			)}

      {/* Currency block */}
			 {["XDC", "BNB", "ETH", "TRX"].includes(currency) && (
            <Row>
              <Col lg={12} sm={12} md={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="title">
                    Currency
                    <div className="title--bottom"></div>
                  </div>
                  <div className="mr-4">&nbsp;</div>
                </div>
                <div className="card-body">
                  <Row>
                    <Col lg={12} sm={12} md={12}>
                    <div style={{ textAlign: "right", marginTop: "10px", marginBottom: "10px" }}>
                      <span style={{ marginBottom: "10px", marginRight:"10px" }}>
                        <ToggleModal {...createCurrencyConfigBtn()} />
                      </span>
                      <a href="javascript:;" className="btn btn-primary" onClick={()=>getBlockchainCurrencies(currency)} style={{ marginBottom: "1px", marginRight:"10px" }}>
                        Refresh
                      </a>
                    </div>
                      <Row style={{ marginLeft: "5px", marginRight: "5px" }}>
                        <Table striped bordered hover responsive>
                          <thead>
                            <tr>
                              <th>Sr#</th>
                              <th>name</th>
                              <th>symbol</th>
                              <th>address</th>
                              <th>decimal</th>
                              <th>minAmount</th>
                              <th>minMoveAmount</th>
                              <th>minConfirmation</th>
                              <th>gasCostLimit</th>
                              <th>type</th>
                              <th>status</th>
                              <th>createdAt</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                          { currencyLoading && <tr><td colSpan={13} align="center"><img src={loaderGif} alt="loading" width="20%" height="10rem" /></td></tr> }
                          { !currencyLoading && blockchainCurrencies && blockchainCurrencies.length == 0 && <tr><td colSpan={13} align="center">No Record Found</td></tr>}
                          { !currencyLoading && blockchainCurrencies && blockchainCurrencies.length > 0 && blockchainCurrencies.map((item, index) => 
                            <tr>
                              <td>{index+1}</td>
                              <td>{item.name}</td>
                              <td>{item.symbol}</td>
                              <td>{item.address}</td>
                              <td>{item.decimal}</td>
                              <td>{item.minAmount}</td>
                              <td>{item.minMoveAmount}</td>
                              <td>{item.minConfirmation}</td>
                              <td>{item.gasCostLimit}</td>
                              <td>{item.type}</td>
                              <td>{item.status ? item.status.toString(): "false"}</td>
                              <td>{moment(item.createdAt).format('YYYY-M-DD h:mm:ss')}</td>
                              <td>
                                <a href="javascript:;" className="btn btn-primary" style={{ marginRight:"5px"}} onClick={()=>handleCurrencyStatus(true, item.uniqueId)}>Enable</a>
                                <a href="javascript:;" className="btn btn-danger" onClick={()=>handleCurrencyStatus(false, item.uniqueId)}>Disable</a>
                              </td>
                            </tr>
							)}
                          </tbody>
                        </Table>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
			)}

			{/* ----Transactions----  */}
			{["BTC", "XDC", "BNB", "ETH", "TRX" ].includes(currency) && (				
			<div style={{ marginLeft: "20px", marginTop: "15px"}}>
				<h2>Transactions</h2>
				<a href="javascript:;" onClick={()=>{ setShowTransactionTable("wallet")}} className="btn btn-dark" style={{ marginRight: "10px"}}>Wallet Transaction</a>
				{ currency !== "BTC" && (
				<>
				<a href="javascript:;" onClick={()=>{ setShowTransactionTable("admin")}} className="btn btn-dark" style={{ marginRight: "10px"}}>Admin Transactions</a>
				<a href="javascript:;" onClick={()=>{ setShowTransactionTable("gas")}} className="btn btn-dark" style={{ marginRight: "10px"}}>Gas Transactions</a>
				</>
				)}
			</div>
			)}

			{/* Wallet Transactions block */}
			{["BTC", "XDC", "BNB", "ETH", "TRX" ].includes(currency) && showTransactionTable === 'wallet' && (
            <Row>
              <Col lg={12} sm={12} md={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="title">
                    Wallet Transactions
                    <div className="title--bottom"></div>
                  </div>
                  <div className="mr-4">&nbsp;</div>
                </div>
                <div className="card-body">
                <Card className="custom-card-1">
                      <div className="card-body">
                        <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>From:</Form.Label>
                              <Form.Control type="datetime-local" value={searchParams.from} name="from" onChange={handleChange} style={{ height: '40px'}} />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>To:</Form.Label>
                              <Form.Control type="datetime-local" value={searchParams.to} name="to" onChange={handleChange} style={{ height: '40px'}} />
                            </Form.Group>
                          </Col>

                          <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>Currency:</Form.Label>
                              <ReactSelect  options={[...SelectOption, ...currencyList]} onChange={(e)=>handleReactSelectChange('currency', e)}></ReactSelect>
                            </Form.Group>
                          </Col>

                          <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>Acknowledged:</Form.Label>
                              <ReactSelect  options={[...SelectOption, ...[{"label": "true", "value": true},{"label": "false", "value": false}]]} onChange={(e)=>handleReactSelectChange('acknowledged',e)}></ReactSelect>
                            </Form.Group>
                          </Col>

                          <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>MoveTo:</Form.Label>
                              <ReactSelect  options={[...SelectOption, ...[{"label": "true", "value": true},{"label": "false", "value": false}]]} onChange={(e)=>handleReactSelectChange('moveTo',e)}></ReactSelect>
                            </Form.Group>
                          </Col>

                          <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>Moved:</Form.Label>
                              <ReactSelect  options={[...SelectOption, ...[{"label": "true", "value": true},{"label": "false", "value": false}]]} onChange={(e)=>handleReactSelectChange('moved',e)}></ReactSelect>
                            </Form.Group>
                          </Col>
                          
                          <Col style={{ marginTop: "25px"}}>
                            <Button variant="primary" type="button" onClick={()=>handleSearch("wallet")} style={{ padding: '10px', width:'80px'}} >
                              Search
                            </Button>
                          </Col>   
                          
                        </Row>
                      </div>
                  </Card>
                  <Row>
                    <Col lg={12} sm={12} md={12}>
                      <Row style={{ marginLeft: "5px", marginRight: "5px" }}>
                        <Table striped bordered hover responsive>
                          <thead>
                            <tr>
                              <th>Sr#</th>
                              <th>currency</th>
                              <th>fromAddress</th>
                              <th>toAddress</th>
                              <th>txHash</th>
                              <th>amount</th>
                              <th>blockNumber</th>
                              <th>transactionStatus</th>
                              <th>acknowledged</th>
                              <th>toMove</th>
                              <th>Moved</th>
                              <th>createdAt</th>
                            </tr>
                          </thead>
                          <tbody>
						  { walletLoading && <tr><td colSpan={12} align="center"><img src={loaderGif} alt="loading" width="20%" height="10rem" /></td></tr> }	
						  { !walletLoading && walletTransactions && walletTransactions.length == 0 && <tr><td colSpan={12} align="center">No Record Found</td></tr>}
						  { !walletLoading && walletTransactions && walletTransactions.length > 0 && walletTransactions.map((item, index) => 
                            <tr>
                              <td>{index+1 + ( walletTransactionCurrentPage * pageSize )}</td>
                              <td>{item.currency}</td>
                              <td>{item.fromAddress}</td>
                              <td>{item.toAddress}</td>
                              <td>{item.txHash}</td>
                              <td>{item.amount}</td>
                              <td>{item.blockNumber}</td>
                              <td>{item.transactionStatus}</td>
                              <td>{item.acknowledged ? item.acknowledged.toString() : "false"}</td>
                              <td>{item.toMove ? item.toMove.toString() : "false"}</td>
                              <td>{item.moved ? item.moved.toString() : "false"}</td>
                              <td>{moment(item.createdAt).format('YYYY-M-DD h:mm:ss')}</td>
                            </tr>
							)}
                          </tbody>
                        </Table>
						<Pagination
							totalCount={walletTransactionTotalRec}
							pageSize={pageSize}
							pageNumber={walletTransactionCurrentPage}
							onPageChange={handleWalletTransactionPage}
						/>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
			)}

			{/* Admin Transactions block */}
			{["XDC", "BNB", "ETH", "TRX"].includes(currency) && showTransactionTable === 'admin' && (
            <Row>
              <Col lg={12} sm={12} md={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="title">
                    Admin Transactions
                    <div className="title--bottom"></div>
                  </div>
                  <div className="mr-4">&nbsp;</div>
                </div>
                <div className="card-body">
                <Card className="custom-card-1">
                      <div className="card-body">
                        <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>From:</Form.Label>
                              <Form.Control type="datetime-local" value={searchParams.fromAdmin} name="fromAdmin" onChange={handleChange} style={{ height: '40px'}} />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>To:</Form.Label>
                              <Form.Control type="datetime-local" value={searchParams.toAdmin} name="toAdmin" onChange={handleChange} style={{ height: '40px'}} />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>Currency:</Form.Label>
                              <ReactSelect  options={[...SelectOption, ...currencyList]} onChange={handleReactSelectChange}></ReactSelect>
                            </Form.Group>
                          </Col>
                          
                          <Col style={{ marginTop: "25px"}}>
                            <Button variant="primary" type="button" onClick={()=>handleSearch("admin")} style={{ padding: '10px', width: '80px'}} >
                              Search
                            </Button>
                          </Col>   
                          
                        </Row>
                      </div>
                  </Card>
                  <Row>
                    <Col lg={12} sm={12} md={12}>
                      <Row style={{ marginLeft: "5px", marginRight: "5px" }}>
                        <Table striped bordered hover responsive>
                          <thead>
                            <tr>
                              <th>Sr#</th>
                              <th>currency</th>
                              <th>fromAddress</th>
                              <th>toAddress</th>
                              <th>txHash</th>
                              <th>amount</th>
                              <th>status</th> 
                              <th>createdAt</th>
                            </tr>
                          </thead>
                          <tbody>
						  { loading && <tr><td colSpan={10} align="center"><img src={loaderGif} alt="loading" width="20%" height="10rem" /></td></tr> }	
						  { !loading && adminTransactions && adminTransactions.length == 0 && <tr><td colSpan={10} align="center">No Record Found</td></tr>}
						  { !loading && adminTransactions && adminTransactions.length > 0 && adminTransactions.map((item, index) => 
                            <tr>
                              <td>{index+1 + ( adminTransactionCurrentPage * pageSize )}</td>
                              <td>{item.currency}</td>
                              <td>{item.fromAddress}</td>
                              <td>{item.toAddress}</td>
                              <td>{item.txHash}</td>
                              <td>{item.amount}</td>
                              <td>{item.status}</td>
                              <td>{moment(item.createdAt).format('YYYY-M-DD h:mm:ss')}</td>
                            </tr>
							)}
                          </tbody>
                        </Table>
						<Pagination
							totalCount={adminTransactionTotalRec}
							pageSize={pageSize}
							pageNumber={adminTransactionCurrentPage}
							onPageChange={handleAdminTransactionPage}
						/>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
			)}

			{/* Gas Transactions block */}
			{["XDC", "BNB", "ETH", "TRX"].includes(currency) && showTransactionTable === 'gas' && (
            <Row>
              <Col lg={12} sm={12} md={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="title">
                    Gas Transactions
                    <div className="title--bottom"></div>
                  </div>
                  <div className="mr-4">&nbsp;</div>
                </div>
                <div className="card-body">
                <Card className="custom-card-1">
                      <div className="card-body">
                        <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>From:</Form.Label>
                              <Form.Control type="datetime-local" value={searchParams.fromGas} name="fromGas" onChange={handleChange} style={{ height: '40px'}} />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>To:</Form.Label>
                              <Form.Control type="datetime-local" value={searchParams.toGas} name="toGas" onChange={handleChange} style={{ height: '40px'}} />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>Currency:</Form.Label>
                              <ReactSelect  options={[...SelectOption, ...currencyList]} onChange={handleReactSelectChange}></ReactSelect>
                            </Form.Group>
                          </Col>
                          
                          <Col style={{ marginTop: "25px"}}>
                            <Button variant="primary" type="button" onClick={()=>handleSearch("gas")} style={{ padding: '10px', width: '80px'}} >
                              Search
                            </Button>
                          </Col>   
                          
                        </Row>
                      </div>
                  </Card>
                  <Row>
                    <Col lg={12} sm={12} md={12}>
                      <Row style={{ marginLeft: "5px", marginRight: "5px" }}>
                        <Table striped bordered hover responsive>
                          <thead>
                            <tr>
                              <th>Sr#</th>
                              <th>currency</th>
                              <th>fromAddress</th>
                              <th>toAddress</th>
                              <th>txHash</th>
                              <th>amount</th>
                              <th>status</th>
                              <th>createdAt</th>
                            </tr>
                          </thead>
                          <tbody>
						  { gasLoading && <tr><td colSpan={10} align="center"><img src={loaderGif} alt="loading" width="20%" height="10rem" /></td></tr> }	
						  { !gasLoading && gasTransactions && gasTransactions.length == 0 && <tr><td colSpan={10} align="center">No Record Found</td></tr>}
						  { !gasLoading && gasTransactions && gasTransactions.length > 0 && gasTransactions.map((item, index) => 
                            <tr>
                              <td>{index+1 + ( gasTransactionCurrentPage * pageSize )}</td>
                              <td>{item.currency}</td>
                              <td>{item.fromAddress}</td>
                              <td>{item.toAddress}</td>
                              <td>{item.txHash}</td>
                              <td>{item.amount}</td>
                              <td>{item.status}</td>
                              <td>{moment(item.createdAt).format('YYYY-M-DD h:mm:ss')}</td>
                            </tr>
							)}
                          </tbody>
                        </Table>
						<Pagination
							totalCount={gasTransactionTotalRec}
							pageSize={pageSize}
							pageNumber={gasTransactionCurrentPage}
							onPageChange={handleGasTransactionPage}
						/>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
			)}
          </div>
        </Card>
      </form>
    </div>
  );
};

export default Blockprocessor;
