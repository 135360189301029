import axios from "axios";
import { API_ROOT, USER_API_ROOT, AUTH_TOKEN_PATH, API_MERCHANT_ROOT } from "./constant";
import { actionCreator } from "../components/layouts/Header";

const getJWT = () => {
  const jwt = localStorage.getItem(AUTH_TOKEN_PATH);
  return jwt;
};

export const GetJwt = getJWT;

const AxiosInstance = axios.create({
  baseURL: API_ROOT,
  headers: {
    "Content-Type": "application/json",
  },
});

const UserApiAxiosInstance = axios.create({
  baseURL: USER_API_ROOT,
  headers: {
    "Content-Type": "application/json",
  },
});

const MerchantAxiosInstance = axios.create({
  baseURL: API_MERCHANT_ROOT,
  headers: {
    "Content-Type": "application/json",
    'Accept': '*/*',
    'Accept-Encoding': 'gzip, deflate, br',
    'Connection': 'keep-alive',
    'Content-Length': '0'
  },
});


AxiosInstance.interceptors.request.use(function (config) {
  config.headers["Authorization"] = getJWT();
  return config;
});

UserApiAxiosInstance.interceptors.request.use(function (config) {
  config.headers["Authorization"] = getJWT();
  return config;
});
MerchantAxiosInstance.interceptors.request.use(function (config) {
  config.headers["Authorization"] = getJWT();
  return config;
});

AxiosInstance.interceptors.response.use(function (response) {
  if(response.data && response.data.message === "please login again") {
    actionCreator.logout();
  }
  return response;
});
// The below merchant is not working
MerchantAxiosInstance.interceptors.response.use(function (response) {
  if(response.data && response.data.message === "please login again") {
    actionCreator.logout();
  }
  return response;
});

export { AxiosInstance, UserApiAxiosInstance, MerchantAxiosInstance };
