import _ from "lodash";
import parse from "html-react-parser";

export const ToJson = (data) => {
  if (_.isObject(data) || _.isArray(data)) return data;
  try {
    let parsedData = JSON.parse(data);
    if (_.isObject(parsedData) || _.isArray(parsedData)) return parsedData;
    return null;
  } catch (e) {
    return null;
  }
};

export const RenderHTMLStr = (htmlStr) => {
  return <div className="parsed-html">{parse(htmlStr)}</div>;
};

export const MergeBook = (bookA, bookB) => {
  const cummulativeBook = { bids: [], asks: [] };
  cummulativeBook.bids = [...bookA.bids, ...bookB.bids].sort(
    ({ price: PriceA }, { price: PriceB }) => PriceB - PriceA
  );
  cummulativeBook.asks = [...bookA.asks, ...bookB.asks].sort(
    ({ price: PriceA }, { price: PriceB }) => PriceA - PriceB
  );
  return cummulativeBook;
};
