import React, { Component } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import { Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSyncAlt } from "@fortawesome/free-solid-svg-icons";

import { ColSpan } from "../../utils/Table";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { AoaToSheet } from "../../utils/DataDownload";

import Pagination from "./Pagination";


const ObjectFields = ["actions"];

class Table extends Component {
  // static tableData = [];

  constructor(props) {
    super(props);
    this.tableData = []; // Store table data as an instance variable
    this.handlePdfDownload = this.handlePdfDownload.bind(this);
    this.afterFilter = this.afterFilter.bind(this);
  }

  afterFilter(newResult, newFilters) { 
    this.tableData = [...newResult];
  }

  handlePdfDownload() {
    const fileName = this.props.email ? `${this.props.tableName}(${this.props.email})` : this.props.tableName;
    const cols = [],
      colFields = [];
    for (let i = 0; i < this.props.columns.length; i++) {
      if (ObjectFields.includes(this.props.columns[i].dataField)) {
        continue;
      }
      colFields.push(this.props.columns[i].dataField);
      cols.push(this.props.columns[i].text);
    }
    const data = [];

    let rowData = this.tableData || this.props.data;
    for (let currData of rowData) {
      const currRow = [];
      for (let currField of colFields) {
        const processedValue = this.processField(currData, currField, fileName);
        currRow.push(processedValue);
      }
      data.push([...currRow]);
    }
    const aoa = [[...cols], ...data];
    AoaToSheet(aoa, fileName);
  }

  processField(currData, currField, fileName) {
    if (fileName === 'Staking History' && currField === 'interest_end_date') {
      const currentDate = Date.now();
      const interestEndDate = Number(currData[currField]);
      const remainingTime = interestEndDate - currentDate;
      const remainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));
      return remainingDays < 0 ? 0 : remainingDays;
    }
  
    return currData[currField];
  }

  render() {
    let columns = this.props.columns;

    const cols = columns.map((e) => e.dataField);
    columns = columns.map((e) => {
      e.attrs = ColSpan(cols, e.dataField, this.props.customColSpan);
      return e;
    });

    const headButtons = [];

    let k = 0;

    if (this.props.createBtn) {
      const clone = React.cloneElement(this.props.createBtn, { key: k++ });
      headButtons.push(clone);
    }

    if (this.props.navigateBtn) {
      const clone = React.cloneElement(this.props.navigateBtn, { key: k++ });
      headButtons.push(clone);
    }

    if (this.props.downloadable) {
      headButtons.push(
        <Button key={k++} onClick={() => this.handlePdfDownload()} variant="info">
          <FontAwesomeIcon icon={faDownload} />
        </Button>
      );
    }

    if (this.props.onRefresh) {
      headButtons.push(
        <Button key={k++} onClick={this.props.onRefresh} variant="info">
          <FontAwesomeIcon icon={faSyncAlt} />
        </Button>
      );
    }

    const defaultSorted = [
      {
        dataField: this.props.defaultField,
        order: this.props.order || "desc",
      },
    ];

    const sizePerPage = this.props.sizePerPage || 10;
    const hidePageListOnlyOnePage = this.props.hidePageListOnlyOnePage || false;
    const tablePagination = this.props.customPagination === 'custom' ? false : true;

    return (
      <div className={this.props.className}>
        <div className={`${this.props.className}__head`}>
          {this.props.tableName}
          {headButtons}
        </div>
        <BootstrapTable
          keyField={this.props.keyField}
          data={this.props.data}
          columns={columns}
          defaultSorted={defaultSorted}
          filter={filterFactory({ afterFilter: this.afterFilter })}
          noDataIndication="No Data to Display"
          pagination={tablePagination && paginationFactory({
            hideSizePerPage: true,
            sizePerPage: sizePerPage,
            hidePageListOnlyOnePage: hidePageListOnlyOnePage,
          })}
          rowStyle={this.props.rowStyle || {}}
          expandRow={this.props.expandRow || {}}
          {...(this.props.selectRow && { selectRow: this.props.selectRow })}
          // classes={"fixed-table"}
        />
        {!tablePagination &&
          <Pagination
            totalCount={this.props.totalCount}
            pageSize={this.props.pageSize}
            pageNumber={this.props.pageNumber}
            onPageChange={this.props.onPageChange}
          />}
      </div>
    );
  }
}

export default Table;
