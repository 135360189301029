import { Select } from 'antd';
import GBEX_ICON from '../../assets/img/GB-token.png';
import PLI_ICON from '../../assets/img/PLI-token.png';
import SRX_ICON from '../../assets/img/SRX-token.png';
import XDC_ICON from '../../assets/img/XDC-token.png';
import EthereumIcon from '../../assets/img/eth.svg';

const { Option } = Select;

export const BASE_URL_SOTATEK = 'https://stakingapi.globiance.com';
export const URL_LIVE_PRICE = 'https://api.globiance.com/api/live_price';

export const TOKEN_LIST = [
  {
    index: 0,
    asset: 'GBEX',
    tokenSymbol: 'GBEX',
    contractAddress: 'xdc34514748F86A8dA01Ef082306b6d6e738F777f5A',
    currencyId: 'd2f824b0-3dfd-11eb-bcce-2dfc779caee1',
    tokenImage: GBEX_ICON,
    tokenIcon: EthereumIcon,
    tokenDecimal: '18',
  },
  {
    index: 1,
    asset: 'PLI',
    tokenSymbol: 'PLI',
    contractAddress: 'xdcFf7412Ea7C8445C46a8254dFB557Ac1E48094391',
    currencyId: '725261F4-08B3-4F4E-BA1F-232415CBC2A4',
    tokenImage: PLI_ICON,
    tokenIcon: EthereumIcon,
    tokenDecimal: '18',
  },
  {
    index: 2,
    asset: 'SRX',
    tokenSymbol: 'SRX',
    contractAddress: 'xdc5D5f074837f5d4618B3916ba74De1Bf9662a3fEd',
    currencyId: '8E5A71F3-109A-4295-901E-4E38FA24D2D1',
    tokenImage: SRX_ICON,
    tokenIcon: EthereumIcon,
    tokenDecimal: '18',
  },
  {
    index: 3,
    asset: 'XDC',
    tokenSymbol: 'XDC',
    contractAddress: 'xdc8A3cc832Bb6B255622E92dc9d4611F2A94d200DA',
    currencyId: 'AC90FA93-754C-4B36-9E62-A3AAD96B27ED',
    tokenImage: XDC_ICON,
    tokenIcon: EthereumIcon,
    tokenDecimal: '18',
  },
];

export const TIME_TYPE = {
  MINUTES: 0,
  BLOCKS: 3,
  DAY: 86400000,
  HOURS: 3600000,
};

export const POOL_TYPE = {
  LOCKED: 0,
  FLEXIBLE: 1,
};

export const APR_TYPE = {
  DYNAMIC: 0,
  FIXED: 1,
};

export const REWARD_TYPE = {
  FLEXIBLE: 0,
  REWARD_LOCK: 1,
};

export const IS_DISPLAY = {
  HIDDEN: 0,
  VISIBLE: 1,
};

export const STATUS_RESPONSE = {
  GET_SUCCESS: 200,
  CREATE_SUCCESS: 201,
  BAD_REQUEST: 400,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
};

export const STATUS_USER_STAKE = {
  STAKING: 1,
  COMPLETE: 2,
};

export const STATUS_POOL = {
  IN_REVIEW: 1,
  PLEDGE: 2,
  LIVE: 3,
  CAP_REACHED: 4,
  PAUSED: 5,
  FINISHED: 6,
};

export const USER_STATUS = {
  Staking: 1,
  Completed: 2,
};

export const selectAfter = (
  <Select className='select-after'>
    <Option value={0}>minutes</Option>
    <Option value={1}>hours</Option>
    <Option value={2}>days</Option>
    {/*<Option value={3}>blocks</Option>*/}
  </Select>
);

export const selectAfterCoolDown = (
  <Select className='select-after'>
    <Option value={0}>minutes</Option>
    <Option value={1}>hours</Option>
    <Option value={2}>days</Option>
  </Select>
);

export const NETWORK = {
  XinFin: 'xinfin',
};
export const NetWorkOption = [
  { value: 'xinfin', title: 'XinFin' },
  { value: 'storx', title: 'StorX' },
  { value: 'pluggin', title: 'Pluggin' },
];
export const REWARD_STATUS = {
  pending: 0,
  active: 1,
};

export const TIME = {
  MINUTES: 0,
  HOURS: 1,
  DAYS: 2,
  BLOCKS: 3,
};

export const COIN = [
  { value: '', label: 'All' },
  { value: 'gbex', label: 'GBEX' },
  { value: 'xdc', label: 'XDC' },
  { value: 'srx', label: 'SRX' },
  { value: 'pli', label: 'PLI' },
];

export const TYPE = [
  { value: '', label: 'All' },
  { value: 'Stake', label: 'Stake' },
  { value: 'Unstake', label: 'Unstake' },
  { value: 'Redeem', label: 'Redeem' },
  // { value: 'DripRedeem', label: 'Drip Redeem' },
];

export const HISTORY_TYPE = {
  Stake: 1,
  Unstake: 2,
  Redeem: 3,
};

export const BUFFER_BALANCE_ACTION = [
  { value: '', label: 'All' },
  { value: 'DepositBuffer', label: 'Deposit' },
  { value: 'WithdrawBuffer', label: 'Withdraw' },
  { value: 'ReturnPrincipal', label: 'Return Principal' },
];

export const BUFFER_BALANCE_ADMIN_ACTION = {
  DepositBuffer: 'Deposit',
  WithdrawBuffer: 'Withdraw',
  ReturnPrincipal: 'Return Principal',
};

export const VALIDATOR_ACTION = [
  { value: '', label: 'All' },
  { value: 'CrawlBlockchain', label: 'Crawl From BC' },
  { value: 'WithdrawValidator', label: 'Withdraw' },
  { value: 'DepositValidator', label: 'Deposit' },
];

export const VALIDATOR_ADMIN_ACTION = {
  CrawlBlockchain: 'Crawl From BC',
  WithdrawValidator: 'Withdraw',
  DepositValidator: 'Deposit',
};

export const STAKING_APR_TYPE = {
  0: 'dynamic',
  1: 'fixed',
};

export const STAKING_STATUS = {
  1: 'In Review',
  2: 'Pledge',
  3: 'Live',
  4: 'Cap Reached',
  5: 'Paused',
  6: 'Finished',
};

export const TOKENS_IMAGE = {
  GBEX: GBEX_ICON,
  PLI: PLI_ICON,
  SRX: SRX_ICON,
  XDC: XDC_ICON,
};

export const defaultSelectToken = 'GBEX';
