import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { MerchantAxiosInstance } from "../../helper/AxiosInstance";
import { AddNoti } from "../../helper/Notification";
import { ParseError } from "../../helper/ResponseHelper";
import { RenderInputCustomType } from "../common/RenderCustomView";

const CreateStorePageLayout = (props) => {
  const [inputs, setInputs] = useState(
    props.data.map(({ value = "", defaultValue = "" }) => {
      if (!["", null, undefined].includes(value)) {
        return value;
      } else if (!["", null, undefined].includes(defaultValue)) {
        return defaultValue;
      }
      return "";
    })
  );

  const [loading, setLoading] = useState(false);
  const [formData, setFormDatas] = useState(props.data);

  const PostApi = (path, data, cb) => {
    MerchantAxiosInstance.post(path, data , { headers: {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data',
    }})
    .then((resp) => {
      if (resp.status === 200 || resp.status === 201) {
        AddNoti(resp.data.message, { type: "info" });
      } else {
        AddNoti(ParseError(resp), { type: "error" });
      }
    })
    .catch((e) => {
      AddNoti(ParseError(e), { type: "error" });
    })
    .finally(() => {
      cb();
    });
  };

  const _postApi = PostApi;

  useEffect(() => {
    setFormDatas(props.data);
  }, [props.data]);

  const setInput = (i, v) => setInputs(Object.assign([...inputs], { [i]: v }));
  const setFormData = (i, v) =>
    setFormDatas(Object.assign([...formData], { [i]: v }));

  const handleFormSubmit = () => {
    setLoading(true);
    const formData = new FormData();

    const payload = props.data.reduce((acc, { name }, i) => {
      const query = {};
      if(inputs[i]) {
        query[name] = inputs[i];

      }

      return { ...acc, ...query };
    }, {})

    Object.keys(payload).forEach((ele) => {
      if(ele === "thumbnail" || ele === "file") {
        formData.append(ele, payload[ele].files[0])
      } else {
        formData.append(ele, payload[ele])
      }
    })

    _postApi(
      props.api,
      formData,
      () => {
        if (props.cb && typeof props.cb === "function") props.cb();
        setLoading(false);
      }
    );
  }

  return (
    <div
      className={`custom-input-1 dynamic-form ${
        props.view === "horizontal" ? "" : "container"
      }`}
    >
      {formData.map(({ name, type, ...rest }, i) => (
         <RenderInputCustomType
              field={name}
              type={type}
              value={inputs[i]}
              key={i}
              setInput={(i, e) => setInput(i, e)}
              onChange={(e) => setInput(i, e)}
              formData={formData}
              formValues={inputs}
              setFormData={setFormData}
              view="vertical"
              {...rest}
            />

      ))}

      <Button
        onClick={handleFormSubmit}
        variant="primary"
        style={{ width: "10rem", float: "right" }}
        disabled={loading}
      >
        Submit
      </Button>
    </div>
  );
};

export default CreateStorePageLayout;
