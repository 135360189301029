import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { ViewOnlyForm } from "../../utils/Table";
import { Link } from "react-router-dom";

class MSettlementOrderDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userJobs: this.props.location.state.data || {},
    };
  }

  render() {
    return (
      <div className="kyb main-panel">
        <Card className="custom-card-1">
          <div className="d-flex justify-content-between align-items-center">
            <div className="title">
              Merchant: Settlement Details
              <div className="title--bottom"></div>
            </div>
            <div className="mr-4">
              <Link to="/merchant-admin-settlement-order">Settlement Order</Link> /<span style={{ color: "grey"}}> {this.state.userJobs.uniqueId} </span>
            </div>
          </div>
          <div className="card-body">
            <Row>
              <Col lg={12} sm={12} md={12}>
                <ViewOnlyForm data={this.state.userJobs} />
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    );
  }
}

export default MSettlementOrderDetail;
