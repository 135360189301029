import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const APP_NAME = "MerchantAdmin";
export const API_ROOT = process.env.REACT_APP_API_ROOT;
export const API_MERCHANT_ROOT = process.env.REACT_APP_API_MERCHANT_ROOT;
export const LIQUIDATOR_WS_URL = process.env.REACT_APP_LIQUIDATOR_WS_URL;
export const LIQUIDATOR_SUB_PATH = "/ext-lqd";
export const EXCHANGE_ID = "";
export const ENV = process.env.REACT_APP_ENV;
export const USER_API_ROOT = process.env.REACT_APP_USER_API_ROOT;
export const AUTH_STATUS_PATH = `${APP_NAME}-AUTH-STATUS`;
export const AUTH_TOKEN_PATH = `${APP_NAME}-AUTH-TOKEN`;
export const TRADING_ENGINE_SOCKET_WS_URL = "wss://staging2trdeng.globiance.com/";

export const INFURA_KEY = "e2920e4a7a3f494b9be288df4010cdc2";
export const toastTime = 3000;


export const ColdWalletAddress = {
  btc: ["bc1qmyualuxe6xdjnlv2g2qge8dl4rz20ngsgyf9zf"],
  eth: ["0x9DE3c9321559409b6Bb0E4BDf206aB0a24d6CA9F", "0x538c4EF0ee800a6c2B3ff6688D0C37828eDf39bE", "0xEe75103E0B84ff6FCEA4C632d552307dcA4AdD47"],
  xrp: ["rafxoczPCMDuMqDxrr2mXyy6JErCdd48ue", "rnW2B7g92yoaXke77PMC2ssrZtAgjzraQ8", "raMe4dpmbMQVq9tgRCsjxzjgF3nKwVxwYJ"],
};

export const GetTokenDecimals = (x) => {
  x = x.toUpperCase()
  if (["USDG", "EURG", "SGDG", "GBPG", "HKDG", "USDC", "USDT"].includes(x)) return 6;
  if (["GZX"].includes(x)) return 15;
  return 18;
}

export const ColdWalletErc20 = [
  {
    token: "lgcy",
    address: "0x9DE3c9321559409b6Bb0E4BDf206aB0a24d6CA9F",
  },
  {
    token: "lgcy",
    address: "0x717E01C553D8AEe91b3603F766FE93B6f9D4a5b1",
  },
  {
    token: "lgcy",
    address: "0x538c4EF0ee800a6c2B3ff6688D0C37828eDf39bE",
  },
  {
    token: "usdt",
    address: "0x717E01C553D8AEe91b3603F766FE93B6f9D4a5b1",
  },
  {
    token: "usdt",
    address: "0x9DE3c9321559409b6Bb0E4BDf206aB0a24d6CA9F",
  },
  {
    token: "usdt",
    address: "0x538c4EF0ee800a6c2B3ff6688D0C37828eDf39bE",
  },
  {
    token: "usdt",
    address: "0x538c4EF0ee800a6c2B3ff6688D0C37828eDf39bE",
  },
];

export const ColdWalletAddressXdc = [
  // {
  //   token: "xdc",
  //   address: "xdc2d6bcf21c24fd6476b55588ab12cfb150f085f3f",
  //   decimals: 18,
  // },
  {
    token: "xdc",
    address: "xdc236D1aE7D131AB5F83e11654872fC8e7BcFc4A25",
  },
  {
    token: "xdc",
    address: "xdc1a5228eaf8b1212151D24E7B57Bbb2f0b8361c81",
  },
  {
    token: "xdc",
    address: "xdc2d6bCf21c24fD6476B55588AB12CfB150F085f3F",
  },
  {
    token: "xdc",
    address: "xdc411ff650fC7f74245b4644316F4FEC57E820C7Fa",
  },
  {
    token: "dc",
    address: "xdc411ff650fC7f74245b4644316F4FEC57E820C7Fa",
  },
  {
    token: "pli",
    address: "xdc411ff650fC7f74245b4644316F4FEC57E820C7Fa",
  },
  {
    token: "srx",
    address: "xdc411ff650fC7f74245b4644316F4FEC57E820C7Fa",
  },
  {
    token: "xdc",
    address: "xdcA6403FBc6c09f6C5D96f6985A36A14a9181DE320",
  },
  {
    token: "srx",
    address: "xdcA6403FBc6c09f6C5D96f6985A36A14a9181DE320",
  },
  {
    token: "pli",
    address: "xdcA6403FBc6c09f6C5D96f6985A36A14a9181DE320",
  },
  {
    token: "wxdc",
    address: "xdcA6403FBc6c09f6C5D96f6985A36A14a9181DE320",
  },
  {
    token: "xdc",
    address: "xdcf6bE872a03396c0fEEDc618dF6D2fb0946c96A43",
  },
  {
    token: "gbex",
    address: "xdc236D1aE7D131AB5F83e11654872fC8e7BcFc4A25",
  },
  {
    token: "gbex",
    address: "xdc2d6bcf21c24fd6476b55588ab12cfb150f085f3f",
  },
  {
    token: "gbex",
    address: "xdc1a5228eaf8b1212151D24E7B57Bbb2f0b8361c81",
  },
  {
    token: "srx",
    address: "xdc236D1aE7D131AB5F83e11654872fC8e7BcFc4A25",
  },
  {
    token: "srx",
    address: "xdc2d6bcf21c24fd6476b55588ab12cfb150f085f3f",
  },
  {
    token: "srx",
    address: "xdc1a5228eaf8b1212151D24E7B57Bbb2f0b8361c81",
  },
  {
    token: "pli",
    address: "xdc236D1aE7D131AB5F83e11654872fC8e7BcFc4A25",
  },
  {
    token: "pli",
    address: "xdc2d6bcf21c24fd6476b55588ab12cfb150f085f3f",
  },
  {
    token: "pli",
    address: "xdc1a5228eaf8b1212151D24E7B57Bbb2f0b8361c81",
  },
  {
    token: "eurg",
    address: "xdc236D1aE7D131AB5F83e11654872fC8e7BcFc4A25",
  },
  {
    token: "eurg",
    address: "xdc2d6bcf21c24fd6476b55588ab12cfb150f085f3f",
  },
  {
    token: "eurg",
    address: "xdc1a5228eaf8b1212151D24E7B57Bbb2f0b8361c81",
  },
  {
    token: "usdg",
    address: "xdc236D1aE7D131AB5F83e11654872fC8e7BcFc4A25",
  },
  {
    token: "usdg",
    address: "xdc2d6bcf21c24fd6476b55588ab12cfb150f085f3f",
  },
  {
    token: "usdg",
    address: "xdc1a5228eaf8b1212151D24E7B57Bbb2f0b8361c81",
  },
  {
    token: "hkdg",
    address: "xdc236D1aE7D131AB5F83e11654872fC8e7BcFc4A25",
  },
  {
    token: "hkdg",
    address: "xdc2d6bcf21c24fd6476b55588ab12cfb150f085f3f",
  },
  {
    token: "hkdg",
    address: "xdc1a5228eaf8b1212151D24E7B57Bbb2f0b8361c81",
  },
  {
    token: "sgdg",
    address: "xdc236D1aE7D131AB5F83e11654872fC8e7BcFc4A25",
  },
  {
    token: "sgdg",
    address: "xdc2d6bcf21c24fd6476b55588ab12cfb150f085f3f",
  },
  {
    token: "sgdg",
    address: "xdc1a5228eaf8b1212151D24E7B57Bbb2f0b8361c81",
  },
  {
    token: "gbpg",
    address: "xdc236D1aE7D131AB5F83e11654872fC8e7BcFc4A25",
  },
  {
    token: "gbpg",
    address: "xdc2d6bcf21c24fd6476b55588ab12cfb150f085f3f",
  },
  {
    token: "gbpg",
    address: "xdc1a5228eaf8b1212151D24E7B57Bbb2f0b8361c81",
  },
  {
    token: "wxdc",
    address: "xdc236D1aE7D131AB5F83e11654872fC8e7BcFc4A25",
  },
  {
    token: "wxdc",
    address: "xdc2d6bcf21c24fd6476b55588ab12cfb150f085f3f",
  },
  {
    token: "wxdc",
    address: "xdc1a5228eaf8b1212151D24E7B57Bbb2f0b8361c81",
  },
];

export const XRC20_TOKENS = [
  "SRX",
  "PLI",
  "EURG",
  "USDG",
  "HKDG",
  "SGDG",
  "GBPG",
  "GBEX",
  "DC",
  "GZX"
];

export const GBEX_USER_ADDRESS = "xdc1a5228eaf8b1212151D24E7B57Bbb2f0b8361c81";

export const WithdrawalAddress = {
  xdc: { address: "xdc94Ce5b21965f6f2FF9000b2f2330E3B5136D64F6" },
  gbex: {
    address: "xdc94Ce5b21965f6f2FF9000b2f2330E3B5136D64F6",
  },
  srx: { address: "xdc94Ce5b21965f6f2FF9000b2f2330E3B5136D64F6" },
  pli: { address: "xdc94Ce5b21965f6f2FF9000b2f2330E3B5136D64F6" },
  eurg: { address: "xdc94Ce5b21965f6f2FF9000b2f2330E3B5136D64F6" },
  usdg: { address: "xdc94Ce5b21965f6f2FF9000b2f2330E3B5136D64F6" },
  hkdg: { address: "xdc94Ce5b21965f6f2FF9000b2f2330E3B5136D64F6" },
  sgdg: { address: "xdc94Ce5b21965f6f2FF9000b2f2330E3B5136D64F6" },
  gbpg: { address: "xdc94Ce5b21965f6f2FF9000b2f2330E3B5136D64F6" },
  wxdc: {
    address: "xdc94Ce5b21965f6f2FF9000b2f2330E3B5136D64F6",
  },
};

export const TOKEN_DECIMALS = {
  xdc: 18,
  gbex: 18,
  srx: 18,
  pli: 18,
  eurg: 6,
  usdg: 6,
  hkdg: 6,
  sgdg: 6,
  gbpg: 6,
  wxdc: 18,
  lgcy: 18,
};

export const TOKEN_ADDRESS = {
  gbex: "xdc34514748f86a8da01ef082306b6d6e738f777f5a",
  srx: "xdc5d5f074837f5d4618b3916ba74de1bf9662a3fed",
  pli: "xdcff7412ea7c8445c46a8254dfb557ac1e48094391",
  eurg: "xdc5231fc6c065466c9ef99278ebe59c1b29fd7ccd0",
  usdg: "xdc9c1eb1ea34e70ac05b5ee5515212e9ec201cfc5d",
  hkdg: "xdc192d70ff2ac21cc2f4788264c7d04502c6412094",
  sgdg: "xdcd0148cb95da3ef32e198905258a0f0af3ea9bd27",
  gbpg: "xdc9ebedd5f691ae16538dfbf7cd0f043622eb34d4d",
  wxdc: "xdc8a3cc832bb6b255622e92dc9d4611f2a94d200da",
  lgcy: "0xae697f994fc5ebc000f8e22ebffee04612f98a0d",
  usdt: "0xdac17f958d2ee523a2206206994597c13d831ec7",
  gzx: "0x16b8ca1c5b0214c1fb1f37fba7ed3a7e63833c59",
  dc: "0x20b59e6c5deb7d7ced2ca823c6ca81dd3f7e9a3a"
};

export const GLOBAL = "global";

export const ColorLightGreen = "#8ce78c";
export const ColorLightRed = "#f78080";
export const ColorLightGrey = "lightgrey";
export const ColorLightYellow = "#ffc1075c";

export const CompareCurrencies = [
  "BTC",
  "ETH",
  "XRP",
  "EUR",
  "XDC",
  "PLI",
  "LGCY",
  "SRX",
  "GBEX",
  "USDT",
  "USDC",
  "DC",
  "GZX"
];

export const FINERY_PRICE_MULTIPLIER = 10 ** 8;

export const ActionButtonType = {
  modal: "MODAL",
  actionButton: "ACTION_BUTTON",
  actionButtonDownload: "ACTION_BUTTON_DOWNLOAD",
  commonActionButton: "COMMON_ACTION_BUTTON",
  redirectButton: "REDIRECTION_BUTTON",
};

export const PairDecimals = {
  BTCEUR: {
    price: 0,
    amount: 6,
    total: 0,
  },
  ETHEUR: {
    price: 1,
    amount: 2,
    total: 0,
  },
  ETHBTC: {
    price: 5,
    amount: 2,
    total: 5,
  },

  // BTCSGD: {
  //   price: 0,
  //   amount: 6,
  //   total: 0,
  // },
  // ETHSGD: {
  //   price: 1,
  //   amount: 2,
  //   total: 0,
  // },

  XRPEUR: {
    price: 3,
    amount: 2,
    total: 2,
  },
  DOTEUR: {
    price: 5,
    amount: 2,
    total: 2,
  },
  BNBEUR: {
    price: 2,
    amount: 4,
    total: 2,
  },
  ADAEUR: {
    price: 2,
    amount: 4,
    total: 2,
  },
  LNKEUR: {
    price: 2,
    amount: 4,
    total: 2,
  },
  UNIEUR: {
    price: 2,
    amount: 4,
    total: 2,
  },
  EOSEUR: {
    price: 2,
    amount: 4,
    total: 2,
  },
  XLMEUR: {
    price: 2,
    amount: 4,
    total: 2,
  },
};

export const AggregatedExchanges = ["bitfinex", "finery", "b2c2", "bitcashier"];

export const SystemAccounts = ["swapuser@globiance.com", "mm1@globiance.com", "dev@globiance.com", "appdev@globiance.com", "bvimm@globiance.com", "profit@globiance.com"];

export const ButtonVariant = [
  "info",
  "warning",
  "danger",
  "success",
  "primary",
  "seconday",
  "light",
  "dark",
];

export const Pairs = [
  "BTCEUR",
  "ETHEUR",
  "ETHBTC",
  "BTCSGD",
  "ETHSGD",

  "XRPEUR",
  "BNBEUR",
  "ADAEUR",
  "DOTEUR",
  "LNKEUR",
  "UNIEUR",
  "EOSEUR",
  "XLMEUR",
];

export const ANALYSIS_TYPE = {
  support: "SUPPORT",
  liquidate: "LIQUIDATE",
};

Object.defineProperty(Object.prototype, "partialMatch", {
  value: function (fields) {
    for (let key of Object.keys(fields)) {
      if (Object.keys(this).includes(key)) {
        if (this[key] === fields[key]) continue;
        return false;
      } else {
        return false;
      }
    }
    return true;
  },
});

Object.defineProperty(Array.prototype, "includesPartial", {
  value: function (fields) {
    for (let i = 0; i < this.length; i++) {
      const obj = this[i];
      if (obj.partialMatch(fields)) {
        return i;
      }
    }
    return null;
  },
});

Object.defineProperty(Array.prototype, "includesPartialAll", {
  value: function (fields) {
    const retIndex = [];
    for (let i = 0; i < this.length; i++) {
      const obj = this[i];
      if (obj.partialMatch(fields)) {
        retIndex.push(i);
      }
    }
    if (retIndex.length === 0) return null;
    return retIndex;
  },
});

export const EmptyData = [
  {
    id: 1,
    maxVolume: (
      <div className="table-one__loader">
        <div>No Data</div>
      </div>
    ),
  },
];

export const prepopulatedData = [
  {
    id: 1,
    tradeFee: (
      <div className="table-one__loader">
        <FontAwesomeIcon icon={faCogs} size="5x" />
        <div>LOADING</div>
      </div>
    ),
  },
];

export const GetAccountLinkXDC = (address) => {
  return (
    <a
      target="_blank"
      href={`https://explorer.xinfin.network/address/${address}`}
    >
      {address.slice(0, 10)}
    </a>
  );
};

export const GetAccountLinkEth = (address) => {
  return (
    <a target="_blank" href={`https://etherscan.io/address/${address}`}>
      {address.slice(0, 10)}
    </a>
  );
};
