import React, { Component } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { textFilter, dateFilter } from "react-bootstrap-table2-filter";
import Table from "../common/Table";
import LinkButton from "../common/Link";
import {
  DateFilter,
  DefaultHeadFormatter,
  GeneralStateUpdater,
  IconHeader,
  TableLoader,
} from "../../utils/Table";

const DataColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "email",
    text: "User Email",
    classes: "highlight",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "firstName",
    text: "First Name",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "lastName",
    text: "Last Name",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "accountStatus",
    text: "Account Status",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "createdAt",
    text: "Created At",
    filter: dateFilter({
      onFilter: DateFilter("createdAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "updatedAt",
    text: "Updated At",
    filter: dateFilter({
      onFilter: DateFilter("updatedAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "actions",
    text: "Actions",
    formatter: (cell, row, rowIndex, x) => {
      if (cell) return cell.map((e) => <LinkButton {...e} />);
    },
  },
];

const TransactionColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "uniqueId",
    text: "Transaction ID",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "checkoutId",
    text: "Checkout ID",
    classes: "highlight",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },

  {
    dataField: "amountInCoin",
    text: "Amount",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "amount",
    text: "Paid Amount",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "TransactionStatus",
    text: "Status",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "createdAt",
    text: "Created At",
    filter: dateFilter({
      onFilter: DateFilter("createdAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
];

const DistributionColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "emailId",
    text: "Email",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "checkoutId",
    text: "Checkout ID",
    classes: "highlight",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },

  {
    dataField: "transactionID",
    text: "Tx ID",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "amount",
    text: "Amount",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "currencyName",
    text: "currency Name",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "createdAt",
    text: "Created At",
    filter: dateFilter({
      onFilter: DateFilter("createdAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
];

class Viewuser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: { data: [], loading: false },
      Transaction: { data: [], loading: false },
      Distribution: { data: [], loading: false },
    };

    this.generalStateUpdater = GeneralStateUpdater.bind(this);
  }

  componentDidMount() {
    this.getAllUsers();
    this.getAllTransactions();
    this.GetDistributionDistribution();
  }

  getAllUsers() {
    this.generalStateUpdater("email", {
      path: "/exchange_admin/get_all_users",
      data: {},
      method: "GET",
      postProcessor: (e) => {
        return {
          ...e,
          actions: [
            {
              modalName: "list",
              children: <div data={e} />,
              backdrop: "static",
              centered: true,
              data: e,
              disableSubmit: true,
              keyboard: false,
              btnName: "LIST",
              btnVariant: "warning",
            },
          ],
        };
      },
    });
  }

  getAllTransactions() {
    this.generalStateUpdater("Transaction", {
      path: "/merchant/merchantTranscation",
      data: {},
      method: "GET",
      postProcessor: (e) => {
        return {
          ...e,
          actions: [],
        };
      },
    });
  }

  GetDistributionDistribution() {
    this.generalStateUpdater("Distribution", {
      path: "/merchant/GetDistributionTransaction",
      data: {},
      method: "GET",
      postProcessor: (e) => {
        return {
          ...e,
          actions: [],
        };
      },
    });
  }

  renderTableData(name) {
    if (this.state[name].loading === true) return TableLoader("email");
    return {
      data: this.state[name].data.map((e, i) => {
        return {
          srNo: i + 1,
          ...e,
        };
      }),
    };
  }
  renderTableDataTransaction(name) {
    if (this.state[name].loading === true) return TableLoader("Transaction");
    return {
      data: this.state[name].data.map((e, i) => {
        return {
          srNo: i + 1,
          ...e,
        };
      }),
    };
  }
  renderTableDataDistribution(name) {
    if (this.state[name].loading === true) return TableLoader("Distribution");
    return {
      data: this.state[name].data.map((e, i) => {
        return {
          srNo: i + 1,
          ...e,
        };
      }),
    };
  }

  render() {
    return (
      <div className="kyc main-panel">
        {/* <Card className="custom-card-1">
      <div className="title">
      Merchant
        <div className="title--bottom"></div>
      </div>
      <div className="card-body">
        <Row>
          <Col lg={12} sm={12} md={12}>
            <Table
              columns={DataColumn}
              className="table1"
              tableName="Merchant List"
              keyField="srNo"
              onRefresh={this.getAllUsers.bind(this)}
              {...this.renderTableData("email")}
            />
          </Col>
        </Row>
      </div>
    </Card> */}

        <Card className="custom-card-1">
          <div className="title">
            Transaction
            <div className="title--bottom"></div>
          </div>
          <div className="card-body">
            <Row>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={TransactionColumn}
                  className="table1"
                  tableName="Transaction List"
                  keyField="srNo"
                  onRefresh={this.getAllTransactions.bind(this)}
                  {...this.renderTableDataTransaction("Transaction")}
                />
              </Col>
            </Row>
          </div>
        </Card>

        <Card className="custom-card-1">
          <div className="title">
            Distribution
            <div className="title--bottom"></div>
          </div>
          <div className="card-body">
            <Row>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={DistributionColumn}
                  className="table1"
                  tableName="Distribution List"
                  keyField="srNo"
                  onRefresh={this.GetDistributionDistribution.bind(this)}
                  {...this.renderTableDataDistribution("Distribution")}
                />
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    );
  }
}
export default Viewuser;
