import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import ToggleModal from "../common/ToggleModal";
import Table from "../common/Table";
import {
  DateFilter,
  DefaultHeadFormatter,
  GeneralStateUpdaterNew,
  IconHeader,
  RenderCreateBtn,
  TableLoader,
  ViewOnlyForm,
} from "../../utils/Table";
import moment from "moment";
import { DynamicActionBtn, DynamicForm, SelectOption } from "../common/RenderCustomView";
import { AddNoti } from "../../helper/Notification";
import { ParseError } from "../../helper/ResponseHelper";
import { MerchantAxiosInstance } from "../../helper/AxiosInstance";
import CreateSettlementPairForm from "./CreateSettlementPairForm";
import { API_MERCHANT_ROOT } from "../../helper/constant";

const settlementPairDataColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
  },
  {
    dataField: "fromCurrencyName",
    text: "fromCurrencyName",
  },
  {
    dataField: "toCurrencyName",
    text: "toCurrencyName",
  },
  {
    dataField: "preferredBroker",
    text: "preferredBroker",
  },
  {
    dataField: "status",
    text: "status",
  },
  {
    dataField: "createdAt",
    text: "Created At",
  },
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell, row, rowIndex, x) => {
      if (cell)
        return cell.map((e, i) => {
          if (e.modalName) return <ToggleModal key={i} {...e} />;
          return e;
        });
    },
  },
];

export class SettlementPair extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settlementPair: { data: [], loading: false },
      currencyList: []
    };
    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    this.getSettlementPair();
    this.getCurrencyList();
  }

  getCurrencyList = () => {
		MerchantAxiosInstance.post("/admin-dashboard-api-service/currency/get-all-currency")
			.then((resp) => {

        if (resp.status === 200 || resp.statusCode === 201) {
					this.setState({ currencyList: resp.data.data.rows });
				} else {
					AddNoti(ParseError(resp), { type: "error" });
				}
			})
			.catch((e) => {
        console.clear();
        console.log("edsd", e.response)
				AddNoti(ParseError(e), { type: "error" });
			});
	};

  getSettlementPair() {
    const enableAPI = `${API_MERCHANT_ROOT}/admin-dashboard-api-service/settlement-pair/enable-settlement-pair`;
    const disableAPI = `${API_MERCHANT_ROOT}/admin-dashboard-api-service/settlement-pair/disable-settlement-pair`;
    this.generalStateUpdater("settlementPair", {
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/settlement-pair/get-all-settlement-pair`,
      method: "POST",
      postProcessor: (e) => {
        const Enable = (
          <DynamicActionBtn
            type="actionBtnMerchant"
            api={enableAPI}
            options={{
              variant: "warning",
              name: "Enable",
              data: {
                uniqueId: e.uniqueId,
              },
            }}
          />
        );

        const Disable = (
          <DynamicActionBtn
            type="actionBtnMerchant"
            api={disableAPI}
            options={{
              variant: "danger",
              name: "Disable",
              data: {
                uniqueId: e.uniqueId,
              },
            }}
          />
        );

        return {
          ...e,
          actions: [
            {
              modalName: "View Settlement Pair",
              children: <ViewOnlyForm data={e} />,
              backdrop: "static",
              centered: true,
              disableSubmit: true,
              keyboard: false,
            },
            Enable,
            Disable
          ],
        };
      },
    });
  }

  createSettlementPair() {
    let currencyMap = [{ label: "loading", value: "" }];
    if (this.state.currencyList.length > 0) {
      currencyMap = this.state.currencyList.map((currency) => {
        return { label: currency.symbol, value: `${currency.uniqueId}|${currency.symbol}` };
      });
    }
    const data = [
      { name: "fromCurrencyId", type: "react-select", options: [...SelectOption, ...currencyMap] },
      { name: "toCurrencyId", type: "react-select", options: [...SelectOption, ...currencyMap] },
      { name: "fromCurrencyName" },
      { name: "toCurrencyName" },
      { name: "preferredBroker" },
      { name: "status", type: "select",  options: [
        { label: "true", value: "true" },
        { label: "false", value: "false" },
      ] },
    ];

    const api = `${API_MERCHANT_ROOT}/admin-dashboard-api-service/settlement-pair/create-new-settlement-pair`;
    const renderCreateBtnConfigs = { ...RenderCreateBtn(data, api, "Create Settlement Pair") };
    renderCreateBtnConfigs.children = <CreateSettlementPairForm isUserApi={false} data={data} api={api}/>;
    return renderCreateBtnConfigs;
  }

  renderTableData(name) {
    if (this.state[name].loading === true) return TableLoader("fromCurrencyName");
    return {
      data: this.state[name].data.map((e, i) => {
        if(e.createdAt != null) {
          e.createdAt = moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss');
        }
        return {
          srNo: i + 1,
          ...e,
        };
      }),
    };
  }


  render() {
    return (
      <div className="user-wallet main-panel">
        <Card className="custom-card-1">
          <div className="title">
            Merchant: Settlement Pair
            <div className="title--bottom"></div>
          </div>

          <div className="card-body">
            
            <Row className="mt-3 mt-md-5">
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={settlementPairDataColumn}
                  className="table1"
                  tableName="Settlement Pair"
                  keyField="srNo"
                  createBtn={<ToggleModal {...this.createSettlementPair()} />}
                  onRefresh={this.getSettlementPair.bind(this)}
                  {...this.renderTableData("settlementPair")}
                />
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    );
  }
}

export default SettlementPair;
