import React from "react";
import { Col, Row } from "react-bootstrap";

import { DynamicForm } from "../common/RenderCustomView";
import {
  RenderUpdateBtn,
  ViewOnlyForm,
} from "../../utils/Table";
import ToggleModal from "../common/ToggleModal";
import { API_MERCHANT_ROOT } from "../../helper/constant";

class ConfigKeyTable extends React.Component {
	constructor(props) {
    super(props);

    this.state = {
      merchantKeyDetail: { data: this.props.merchantKeyDetail, loading: false },
      feeConfigDetails: { data: { } }
    };
   
  }

  updateFeeConfigs() {
    const { data } = this.state.merchantKeyDetail;
    const updateCofigApi =
    `${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/update-fee-config-merchant-key`;
  
    const updateConfig = [
      { name: "merchantKeyId", value: data.uniqueId, disabled: true },

      { name: "globianceHiddenFee", defaultValue: data.globianceHiddenFee },
      { name: "globianceOfficialFee", defaultValue: data.globianceOfficialFee },
    ];

    const renderUpdateBtnConfigs = { ...RenderUpdateBtn(updateConfig, updateCofigApi, "Update Fee Config", false, "Update Config") };
    renderUpdateBtnConfigs.children = <DynamicForm data={updateConfig} api={updateCofigApi}/>;
    return renderUpdateBtnConfigs;
  }

  transformConfigDetailsToFeeDetails = (data) => {
    if(data) {
      const { hiddenFee, officialFee, agentOneFee, agentTwoFee, globianceHiddenFee, globianceOfficialFee, globianceAgentTwoFee, ...rest } = data;
      const feeConfigDetails = { hiddenFee, officialFee, agentOneFee, agentTwoFee, globianceHiddenFee, globianceOfficialFee, globianceAgentTwoFee };
      return feeConfigDetails;
    }
    return false;
  }

  render() {
   
    return (
      <>
        <div
          style={{ border: "1px solid #ccc", padding: "10px", display: "flex" }}
        >
          <div style={{ flex: "1" }}>
            <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
              {" "}
              Fee Config
            </div>
          </div>
          
        </div>
      {/* update Config */}
        <div
          style={{ border: "1px solid #ccc", padding: "10px", marginTop:"10px" }}
        >
          
          <Row>
              <Col lg={12} sm={12} md={12}>
                <ToggleModal {...this.updateFeeConfigs()} />
              </Col>
          </Row>

            <Row>
              <Col lg={12} sm={12} md={12}>
                <ViewOnlyForm data={this.transformConfigDetailsToFeeDetails(this.props.merchantKeyDetail)} />
              </Col>
            </Row>

         
        </div>
      </>
    );
  }
}

export default ConfigKeyTable;
