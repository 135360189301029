import _ from "lodash";

export const GLOBAL = "GLOBAl";

/**
 *
 * @param {Array} currAccess -  array with user's current role permisison array
 * @param {Array} requiredAccess - array of modules & they type required
 */
export const HasAccess = (currAccess, requiredAccess) => {
  if (_.isEmpty(requiredAccess))
    return {
      hasAccess: true,
    };
  const currAccessModule = Object.keys(currAccess);
  let hasAccess = false;
  for (let i = 0; i < requiredAccess.length; i++) {
    const currMod = requiredAccess[i];
    if (currAccessModule.includes(currMod)) {
      hasAccess = true;
      if (currAccess[currMod].editAccess === true) {
      }
    }
  }
  return {
    hasAccess,
  };
};

/**
 *
 * @param {Array} currAccess
 * @param {Object} param1
 */
export function HasTypeAccess(
  currAccess,
  { requiredModule = [], type = "viewAccess" } = {
    requiredModule: [],
    type: "viewAccess",
  }
) {
  if (_.isEmpty(requiredModule)) return true;
  const currAccessKeys = Object.keys(currAccess);

  if (currAccessKeys.includes("global")) return true;

  for (let module of requiredModule) {
    if (currAccessKeys.includes(module) && currAccess[module][type] === true)
      return true;
  }
  return false;
}
