export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE';

export const LANGUAGE_CHANGE = 'LANGUAGE_CHANGE';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGOUT = 'LOGOUT';

export const GET_WALLET_SUMMARY_START = 'GET_WALLET_SUMMARY_START';
export const GET_WALLET_SUMMARY_SUCCESS = 'GET_WALLET_SUMMARY_SUCCESS';
export const GET_WALLET_SUMMARY_FAIL = 'GET_WALLET_SUMMARY_FAIL';

export const GET_CURRENCY_START = 'GET_CURRENCY_START';
export const GET_CURRENCY_SUCCESS = 'GET_CURRENCY_SUCCESS';
export const GET_CURRENCY_FAIL = 'GET_CURRENCY_FAIL';

export const GET_PAIRS_START = 'GET_PAIRS_START';
export const GET_PAIRS_SUCCESS = 'GET_PAIRS_SUCCESS';
export const GET_PAIRS_FAIL = 'GET_PAIRS_FAIL';

export const PAIRED_CURRENCIES = 'PAIRED_CURRENCIES';

export const GET_BTC_NODE_START = 'GET_BTC_NODE_START';
export const GET_BTC_NODE_SUCCESS = 'GET_BTC_NODE_SUCCESS';
export const GET_BTC_NODE_FAIL = 'GET_BTC_NODE_FAIL';

export const GET_ADMIN_ADDRESS_START = 'GET_ADMIN_ADDRESS_START';
export const GET_ADMIN_ADDRESS_SUCCESS = 'GET_ADMIN_ADDRESS_SUCCESS';
export const GET_ADMIN_ADDRESS_FAIL = 'GET_ADMIN_ADDRESS_FAIL';

export const ADMIN_ADDRESS_BALANCE_SUCCESS = 'ADMIN_ADDRESS_BALANCE_SUCCESS';
export const ADMIN_ADDRESS_BALANCE_FAIL = 'ADMIN_ADDRESS_BALANCE_FAIL';

export const GET_ALL_ROLE_PERMISSION_START = 'GET_ALL_ROLE_PERMISSION_START';
export const GET_ALL_ROLE_PERMISSION_SUCCESS =
  'GET_ALL_ROLE_PERMISSION_SUCCESS';
export const GET_ALL_ROLE_PERMISSION_FAIL = 'GET_ALL_ROLE_PERMISSION_FAIL';

export const ADMIN_DATA = 'ADMIN_DATA';

export const GET_FINERY_POSITIONS_START = 'GET_FINERY_POSITIONS_START';
export const GET_FINERY_POSITIONS_SUCCESS = 'GET_FINERY_POSITIONS_SUCCESS';
export const GET_FINERY_POSITIONS_FAIL = 'GET_FINERY_POSITIONS_FAIL';

export const SOCKET_JOIN_ROOM = 'SOCKET_JOIN_ROOM';
export const SOCKET_LEAVE_ROOM = 'SOCKET_LEAVE_ROOM';
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const SOCKET_DISCONNECTED = 'SOCKET_DISCONNECTED';

export const GET_B2C2_POSITIONS_START = 'GET_B2C2_POSITIONS_START';
export const GET_B2C2_POSITIONS_SUCCESS = 'GET_B2C2_POSITIONS_SUCCESS';
export const GET_B2C2_POSITIONS_FAIL = 'GET_B2C2_POSITIONS_FAIL';

export const GET_PENDING_BALANCE_START = 'GET_PENDING_BALANCE_START';
export const GET_PENDING_BALANCE_SUCCESS = 'GET_PENDING_BALANCE_SUCCESS';
export const GET_PENDING_BALANCE_FAIL = 'GET_PENDING_BALANCE_FAIL';

export const GET_ADJUSTMENT_START = 'GET_ADJUSTMENT_START';
export const GET_ADJUSTMENT_SUCCESS = 'GET_ADJUSTMENT_SUCCESS';
export const GET_ADJUSTMENT_FAIL = 'GET_ADJUSTMENT_FAIL';

export const DETAIL_LOCKED = 'DETAIL_LOCKED';
export const DETAIL_FLEXIBLE = 'DETAIL_FLEXIBLE';

export const VISIBLE_MODAL = 'VISIBLE_MODAL';
export const DISABLE_MODAL = 'DISABLE_MODAL';

export const VISIBLE_LOADING = 'VISIBLE_LOADING';
export const HIDDEN_LOADING = 'HIDDEN_LOADING';
