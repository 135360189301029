import { ToastContainer } from "react-toastify";
import { Redirect, Route, Switch } from "react-router-dom";
import { compose } from "redux";

import MultiLanguage from "./components/HOC/MultiLanguage";
import RequireLogout from "./components/HOC/RequireLogout";
import RequireAuthentication from "./components/HOC/RequireAuthentication";
import SidebarCollapsed from "./components/HOC/SidebarState";

import Header from "./components/layouts/Header";
import Sidebar from "./components/layouts/Sidebar/Sidebar";
import Dashboard from "./components/Dashboard/index";

import Login from "./components/Login";

import MerchantCheckout from "./components/Admin-Merchant/MerchantCheckout";
import MerchantCheckoutDetail from "./components/Admin-Merchant/MerchantCheckoutDetail";
import MerchantPaymentDetail from "./components/Admin-Merchant/MerchantPaymentDetail";
import MSettlementOrder from "./components/Admin-Merchant/MSettlementOrder";
import MSettlementOrderDetail from "./components/Admin-Merchant/MSettlementOrderDetail";
import MerchantPayment from "./components/Admin-Merchant/MerchantPayment";
import MerchantRefundRequests from "./components/Admin-Merchant/MerchantRefundRequests";
import MerchantAdminKey from "./components/Admin-Merchant/MerchantKey";
import MerchantAdminKeyDetail from "./components/Admin-Merchant/MerchantAdminKeyDetail";
import PaymentCurrency from "./components/Admin-Merchant/PaymentCurrency";
import SettlementPair from "./components/Admin-Merchant/SettlementPair";
import PageLayout from "./components/Admin-Merchant/PageLayout";
import RefundFee from "./components/Admin-Merchant/RefundFee";
import FeeDistribution from "./components/Admin-Merchant/FeeDistribution";
import MerchantBalance from "./components/Admin-Merchant/MerchantBalance";
import MerchantBalanceById from "./components/Admin-Merchant/MerchantBalanceById";
import MerchantBalanceByEmail from "./components/Admin-Merchant/MerchantBalanceByEmail";
import GlobianceFeeCollection from "./components/Admin-Merchant/GlobianceFeeCollection";
import MerchantBalancesTally from "./components/Admin-Merchant/MerchantBalancesTally";
import MerchantBalancesPaymentDistribution from "./components/Admin-Merchant/MerchantBalancesPaymentDistribution";
import BalanceTallySummary from "./components/Admin-Merchant/BalanceTallySummary";
import BalanceTally from "./components/Admin-Merchant/BalanceTally";
import Blockprocessor from "./components/Admin-Merchant/Blockprocessor";
import LoggerUI from "./components/Admin-Merchant/LoggerUI";
import PaymentCurrencyNetwork from "./components/Admin-Merchant/PaymentCurrencyNetwork";
import Viewuser from "./components/Merchant/Viewuser";
import Refunds from "./components/Merchant/Refunds";
import keysList from "./components/Merchant/keysList";
import MerchantBalanceUpdateLogs from "./components/Merchant/MerchantBalanceUpdateLogs";
import MerchantRequest from "./components/Merchant/MerchantRequests";
import MerchantSettlementOrder from "./components/Merchant/MerchantSettlementOrder";
import MerchantKeys from "./components/Merchant/MerchantFeeRates";
import MerchantActivityLogs from "./components/Merchant/MerchantActivityLogs";
import MerchantCurrency from "./components/Admin-Merchant/MerchantCurrency";
import MerchantWithdrawal from "./components/Admin-Merchant/MerchantWithdrawal";
import MerchantUsers from "./components/Admin-Merchant/MerchantUsers";

import "./assets/scss/main.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import CreateStorePageLayoutFiles from "./components/Admin-Merchant/CreateStorePageLayoutFiles";
import { useSelector } from "react-redux";

const ComposedHeader = MultiLanguage("Header")(Header);
const ComposedSidebar = compose(
  MultiLanguage("Sidebar"),
  RequireAuthentication(null),
  SidebarCollapsed,
  // SelectiveComponent([], { forward: true })
)(Sidebar);

const ComposedLogin = compose(
  MultiLanguage("Login"),
  RequireLogout("/")
)(Login);

function App() {
  
  const authState = useSelector(store => store.auth);
  
  console.log("authState", authState)
  const redirectAppUser = () => {
    if(authState && authState.isLoggedIn) {
      return (
        <Redirect to="/" />
      )
    } else {
      return (
        <Redirect to="/login" from="/" />
      )
    }
  }

  return (
    <div className="src-app">
      {/* <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          // console.log("[*] cache policy", loading, isLatestVersion);
          if (loading) return null;
          console.log(`Admin UI Version:`, packageJson.version);
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }

          return null;
        }}
      </CacheBuster> */}

      <ToastContainer />
      <ComposedHeader />
      <ComposedSidebar />
      <Switch>
        <Route path="/login" exact={true} component={ComposedLogin} />

        {/* Merchant Admin Dashboard */}
        <Route path="/merchant-admin-checkout" exact={true} component={MerchantCheckout} />
        <Route path="/merchant-admin-checkout-detail/:id" exact={true} component={MerchantCheckoutDetail} />
        <Route path="/merchant-admin-payment-detail/:id" exact={true} component={MerchantPaymentDetail} />
        <Route path="/merchant-admin-settlement-order" exact={true} component={MSettlementOrder} />
        <Route path="/merchant-admin-settlement-order-detail/:id" exact={true} component={MSettlementOrderDetail} />
        <Route path="/merchant-admin-payment" exact={true} component={MerchantPayment} />
        {/* <Route path="/merchant-admin-request" exact={true} component={MerchantAdminRequests} /> */}
        <Route path="/merchant-admin-refund-request" exact={true} component={MerchantRefundRequests} />
        <Route path="/merchant-admin-keys" exact={true} component={MerchantAdminKey} />
        <Route path="/merchant-admin-keys-detail/:id" exact={true} component={MerchantAdminKeyDetail} />
        <Route path="/payment-gateway-currency" exact={true} component={PaymentCurrency} />
        <Route path="/settlement-pair" exact={true} component={SettlementPair} />
        <Route path="/merchant-admin-refund-fee" exact={true} component={RefundFee} />
        <Route path="/merchant-admin-fee-distribution" exact={true} component={FeeDistribution} />
        <Route path="/merchant-admin-balances" exact={true} component={MerchantBalance} />
        <Route path="/merchant-admin-balances-by-id" exact={true} component={MerchantBalanceById} />
        <Route path="/merchant-admin-balances-by-email" exact={true} component={MerchantBalanceByEmail} />
        <Route path="/globiance-fee-collection" exact={true} component={GlobianceFeeCollection} />
        <Route path="/merchant-balances-tally" exact={true} component={MerchantBalancesTally} />
        <Route path="/merchant-balances-payment-distribution" exact={true} component={MerchantBalancesPaymentDistribution} />
        <Route path="/balance-tally-summary" exact={true} component={BalanceTallySummary} />
        <Route path="/balance-tally" exact={true} component={BalanceTally} />
        <Route path="/merchant-blockprocessor" exact={true} component={Blockprocessor} />
        <Route path="/merchant-LoggerUI" exact={true} component={LoggerUI} />
        <Route path="/merchant-page-layout" exact={true} component={PageLayout} />
        <Route path="/merchant-currency" exact={true} component={MerchantCurrency} />
        <Route path="/merchant-withdrawal" exact={true} component={MerchantWithdrawal} />
        <Route path="/merchant-users" exact={true} component={MerchantUsers} />
        {/* <Route path="/merchant-page-layout-files" exact={true} component={PageLayoutFiles} /> */}
        <Route path="/add-page-layout-file/:id" exact={true} component={CreateStorePageLayoutFiles} />

        <Route path="/merchant-checkout" exact={true} component={Viewuser} />
        <Route path="/merchant-refunds" exact={true} component={Refunds} />
        <Route path="/keyslist/:email" exact={true} component={keysList} />
        <Route
          path="/merchant-balance-update-logs"
          exact={true}
          component={MerchantBalanceUpdateLogs}
        />
        <Route
          path="/merchant-request"
          exact={true}
          component={MerchantRequest}
        />
        <Route path="/merchant-keys" exact={true} component={MerchantKeys} />
        <Route
          path="/merchant-settlement-order"
          exact={true}
          component={MerchantSettlementOrder}
        />
        <Route
          path="/merchant-activity-logs"
          exact={true}
          component={MerchantActivityLogs}
        />

        <Route
          path="/payment-currency-network/:action/:id"
          exact={true}
          component={PaymentCurrencyNetwork}
        />
        {/* <Route path="/" exact={true} component={Dashboard} /> */}
        {/* {redirectAppUser()} */}

          {/* Redirect logic */}
        <Route path="/" exact={true} component={Dashboard}>
          {authState && authState.isLoggedIn ? <Redirect to="/" /> : <Redirect to="/login" />}
        </Route>
      </Switch>
    </div>
  );
}

export default App;
