import * as types from './actionTypes';
import { AxiosInstance, MerchantAxiosInstance } from '../helper/AxiosInstance';
import { ParseError } from '../helper/ResponseHelper';
import { CompareCurrencies } from '../helper/constant';

/**
 * ----------------------------------------------------------
 * Simple Action Creators Start
 * ----------------------------------------------------------
 */

export const SidebarToggle = () => {
  return {
    type: types.SIDEBAR_TOGGLE,
  };
};

export const LanguageChange = () => {
  return {
    type: types.LANGUAGE_CHANGE,
  };
};

export const getDetailLocked = (data) => {
  return {
    type: types.DETAIL_LOCKED,
    projects: data,
  };
};

export const getDetailFlexible = (data) => {
  return {
    type: types.DETAIL_FLEXIBLE,
    projects: data,
  };
};

export const handleOpenModal = (Component) => {
  return {
    type: types.VISIBLE_MODAL,
    Component: Component,
  };
};

/**
 * ----------------------------------------------------------
 * Simple Action Creators Stop
 * ----------------------------------------------------------
 */

/**
 * ----------------------------------------------------------
 * Thunkified Actions Start
 * ----------------------------------------------------------
 */

export const GetAuthStatus = () => async (dispatch) => {
  try {
    const resp = await MerchantAxiosInstance.post('/admin-dashboard-api-service/auth/verify-jwt');
    if (resp.status !== 200) {
      dispatch({
        type: types.LOGOUT,
      });
    } else {
      dispatch({
        type: types.ADMIN_DATA,
        admin: resp.data.data,
      });

      // const resp1 = await MerchantAxiosInstance.post(
      //   '/exchange_admin/rolePermission/getAllRolePermission'
      // );

      // if (resp1.data.statusCode === 200) {
      //   dispatch({
      //     type: types.GET_ALL_ROLE_PERMISSION_SUCCESS,
      //     payload: resp1.data.data,
      //   });
      // } else {
      //   dispatch({
      //     type: types.GET_ALL_ROLE_PERMISSION_FAIL,
      //     notify: {
      //       msg: ParseError(resp1.data),
      //       opts: {
      //         type: 'error',
      //       },
      //     },
      //   });
      // }
    }
  } catch (e) {
    dispatch({
      type: types.LOGOUT,
    });
  }
};

export const GetGlobianceWalletSummary = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_WALLET_SUMMARY_START,
    });
    const resp = await MerchantAxiosInstance.post(
      '/exchange_admin/all_user_balance_summary'
    );

    if (resp.data.statusCode === 200) {
      dispatch({
        type: types.GET_WALLET_SUMMARY_SUCCESS,
        payload: resp.data.data,
      });
    } else {
      dispatch({
        type: types.GET_WALLET_SUMMARY_FAIL,
        notify: {
          msg: ParseError(resp.data),
          opts: { type: 'error' },
        },
      });
    }
  } catch (e) {
    dispatch({
      type: types.GET_WALLET_SUMMARY_FAIL,
      notify: {
        msg: ParseError(e),
        opts: { type: 'error' },
      },
    });
  }
};


export const GetSummaryAdjustment = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_ADJUSTMENT_START,
    });
    const resp = await MerchantAxiosInstance.post(
      '/exchange_admin/getSummaryAdjustments'
    );


    if (resp.data) {
      dispatch({
        type: types.GET_ADJUSTMENT_SUCCESS,
        payload: resp.data,
      });
    } else {
      dispatch({
        type: types.GET_ADJUSTMENT_FAIL,
        notify: {
          msg: ParseError(resp.data),
          opts: { type: 'error' },
        },
      });
    }
  } catch (e) {
    dispatch({
      type: types.GET_ADJUSTMENT_FAIL,
      notify: {
        msg: ParseError(e),
        opts: { type: 'error' },
      },
    });
  }
};

export const GetExchangeCurrency = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_CURRENCY_START,
    });
    const resp = await MerchantAxiosInstance.post('/exchange_admin/get_currency_list');

    if (resp.data.statusCode === 200) {
      dispatch({
        type: types.GET_CURRENCY_SUCCESS,
        payload: resp.data.data,
      });
    } else {
      dispatch({
        type: types.GET_CURRENCY_FAIL,
        notify: {
          msg: ParseError(resp.data),
          opts: { type: 'error' },
        },
      });
    }
  } catch (e) {
    dispatch({
      type: types.GET_CURRENCY_FAIL,
      notify: {
        msg: ParseError(e),
        opts: { type: 'error' },
      },
    });
  }
};

export const GetPair = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_PAIRS_START,
    });
    const resp = await AxiosInstance.get('/exchange_admin/get_all_pairs', {});

    if (resp.data.statusCode === 200) {
      dispatch({
        type: types.GET_PAIRS_SUCCESS,
        payload: resp.data.data,
      });
    } else {
      dispatch({
        type: types.GET_PAIRS_FAIL,
        notify: {
          msg: ParseError(resp.data),
          opts: { type: 'error' },
        },
      });
    }
  } catch (e) {
    dispatch({
      type: types.GET_PAIRS_FAIL,
      notify: {
        msg: ParseError(e),
        opts: { type: 'error' },
      },
    });
  }
};

export const GetBtcNodeBalance = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_BTC_NODE_START,
    });

    const resp = await MerchantAxiosInstance.post(
      '/exchange_admin/get_btc_node_balance'
    );

    if (resp.data.statusCode === 200) {
      dispatch({
        type: types.GET_BTC_NODE_SUCCESS,
        payload: resp.data.data.data,
      });
    } else {
      dispatch({
        type: types.GET_BTC_NODE_FAIL,
        notify: {
          msg: ParseError(resp.data),
          opts: {
            type: 'error',
          },
        },
      });
    }
  } catch (e) {
    dispatch({
      type: types.GET_BTC_NODE_FAIL,
      notify: {
        msg: ParseError(e),
        opts: { type: 'error' },
      },
    });
  }
};

export const GetAdminAddress = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_ADMIN_ADDRESS_START,
    });

    const resp = await MerchantAxiosInstance.post('/exchange_admin/get_admin_address');

    if (resp.data.statusCode === 200) {
      dispatch({
        type: types.GET_ADMIN_ADDRESS_SUCCESS,
        payload: resp.data.data.data,
      });
    } else {
      dispatch({
        type: types.GET_ADMIN_ADDRESS_FAIL,
        notify: {
          msg: ParseError(resp.data),
          opts: {
            type: 'error',
          },
        },
      });
    }
  } catch (e) {
    dispatch({
      type: types.GET_ADMIN_ADDRESS_FAIL,
      notify: {
        msg: ParseError(e),
        opts: { type: 'error' },
      },
    });
  }
};

export const GetAllRolePermission = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_ALL_ROLE_PERMISSION_START,
    });

    const resp = await MerchantAxiosInstance.post(
      '/exchange_admin/rolePermission/getAllRolePermission'
    );

    if (resp.data.statusCode === 200) {
      dispatch({
        type: types.GET_ALL_ROLE_PERMISSION_SUCCESS,
        payload: resp.data.data,
      });
    } else {
      dispatch({
        type: types.GET_ALL_ROLE_PERMISSION_FAIL,
        notify: {
          msg: ParseError(resp.data),
          opts: {
            type: 'error',
          },
        },
      });
    }
  } catch (e) {
    dispatch({
      type: types.GET_ADMIN_ADDRESS_FAIL,
      notify: {
        msg: ParseError(e),
        opts: { type: 'error' },
      },
    });
  }
};

export const GetFineryPositions = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_FINERY_POSITIONS_START,
    });

    const resp = await AxiosInstance.get(
      '/exchange_admin/qbt/get_positions_finery'
    );

    if (resp.data.statusCode === 200) {
      dispatch({
        type: types.GET_FINERY_POSITIONS_SUCCESS,
        payload: resp.data.data,
      });
    } else {
      dispatch({
        type: types.GET_FINERY_POSITIONS_FAIL,
        notify: {
          msg: ParseError(resp.data),
          opts: {
            type: 'error',
          },
        },
      });
    }
  } catch (e) {
    dispatch({
      type: types.GET_FINERY_POSITIONS_FAIL,
      notify: {
        msg: ParseError(e),
        opts: { type: 'error' },
      },
    });
  }
};

export const GetB2C2Positions = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_B2C2_POSITIONS_START,
    });

    const resp = await AxiosInstance.get(
      '/exchange_admin/qbt/get_positions_b2c2'
    );

    if (resp.data.statusCode === 200) {
      dispatch({
        type: types.GET_B2C2_POSITIONS_SUCCESS,
        payload: Object.keys(resp.data.data)
          .filter((cur) => CompareCurrencies.includes(cur))
          .map((cur) => [cur, resp.data.data[cur]]),
      });
    } else {
      dispatch({
        type: types.GET_B2C2_POSITIONS_FAIL,
        notify: {
          msg: ParseError(resp.data),
          opts: {
            type: 'error',
          },
        },
      });
    }
  } catch (e) {
    dispatch({
      type: types.GET_B2C2_POSITIONS_FAIL,
      notify: {
        msg: ParseError(e),
        opts: { type: 'error' },
      },
    });
  }
};


export const GetPendingBalance = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_PENDING_BALANCE_START,
    });

    const resp = await MerchantAxiosInstance.post(
      '/exchange_admin/getPendingBalance'
    );

    if (resp.data.statusCode === 200) {
      dispatch({
        type: types.GET_PENDING_BALANCE_SUCCESS,
        payload: resp.data.data,
      });
    } else {
      dispatch({
        type: types.GET_PENDING_BALANCE_FAIL,
        notify: {
          msg: ParseError(resp.data),
          opts: {
            type: 'error',
          },
        },
      });
    }
  } catch (e) {
    dispatch({
      type: types.GET_PENDING_BALANCE_FAIL,
      notify: {
        msg: ParseError(e),
        opts: { type: 'error' },
      },
    });
  }
};

/**
 * ----------------------------------------------------------
 * Thunkified Actions Start
 * ----------------------------------------------------------
 */
