import React from "react";
import {  Card, Col, Row, Form } from "react-bootstrap";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import {
  textFilter,
  dateFilter,
  numberFilter,
} from "react-bootstrap-table2-filter";
import { DynamicActionBtn } from "../common/RenderCustomView";
import Table from "../common/Table";
import ToggleModal from "../common/ToggleModal";
import {
  DateFilter,
  DefaultHeadFormatter,
  IconHeader,
  TableLoader,
} from "../../utils/Table";

const merchantPaymentsColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
  },
  {
    dataField: "merchantUserId",
    text: "Merchant UserId",
    classes: "highlight",
  },
  {
    dataField: "merchantKeyId",
    text: "Key ID",
  },
  {
    dataField: "checkoutId",
    text: "CheckoutId",
  },
  {
    dataField: "currencyId",
    text: "currencyId",
  },
  {
    dataField: "currencyName",
    text: "currencyName",
  },
  {
    dataField: "transactionType",
    text: "transactionType",
  },
  {
    dataField: "transactionIdWallet",
    text: "transactionIdWallet",
  },
  {
    dataField: "transactionIdBlockchain",
    text: "transactionIdBlockchain",
  },
  {
    dataField: "isUnderDispute",
    text: "isUnderDispute",
  },
  {
    dataField: "disputeType",
    text: "disputeType",
  },
  {
    dataField: "toSettle",
    text: "toSettle",
  },
  {
    dataField: "settlementPairAvailable",
    text: "settlementPairAvailable",
  },
  {
    dataField: "settlementStatus",
    text: "settlementStatus",
  },
  {
    dataField: "settlementOrderId",
    text: "settlementOrderId",
  },
  {
    dataField: "amount",
    text: "amount",
  },
  {
    dataField: "amountDistributed",
    text: "amountDistributed",
  },  
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell, row, rowIndex, x) => {
      if (cell)
        return cell.map((e) => {
          if (e.modalName) return <ToggleModal {...e} />;
          return e;
        });
    },
  },
];

class PaymentsTable extends React.Component {
	constructor(props) {
    super(props);

    this.state = {
      payments: { data: this.props.payments, loading: false },
    };
  }
  

  renderTableData(name) { 
    if (this.state.payments.loading === true) return TableLoader("merchantUserId");
    return {
      data: this.props.payments.map((e, i) => {
        const View = (
          <DynamicActionBtn
            link={`/merchant-admin-payment-detail/${e.uniqueId}`}
            type="navigateBtn"
            options={{
              variant: "info",
              name: "View",
              data: e,
            }}
            />
        )
        return {
          srNo: i + 1,
          ...e,
          actions: [
            View
          ],
        };
      }),
    };
  }

  render() {
    return (
            <>
            <Row style={{ marginTop: "20px"}}>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={merchantPaymentsColumn}
                  className="table1"
                  tableName="Payments Detail"
                  keyField="srNo"
                  {...this.renderTableData("payments")}
                />
              </Col>
            </Row>
            </>
    );
  }
}

export default PaymentsTable;
